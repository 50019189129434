import React, { useContext, useState } from "react";
import { Drawer } from "antd";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";

import Icon from "../icon";

import "./style.scss";
import Avatar from "../avatar";
import { RootStoreContext } from "../../store/rootStore.ts";
import Document from "../documents";
import Menu from "../menu";
import DrawerNav from "../drawer-nav";

const DrawerMenu = observer(({ open, setOpen }) => {
  const { userStore, authStore } = useContext(RootStoreContext);
  const [placement] = useState("left");

  const onClose = () => {
    setOpen(!open);
  };
  const logOut = () => {
    sessionStorage.removeItem("authToken");
    authStore.setToken(null);
    localStorage.clear();
  };

  return (
    <Drawer
      title={(
        <div className="drawer_menu">
          <Avatar />
          <div className="name">{userStore.user?.firstName}</div>
          <div className="title">{userStore.role}</div>
        </div>
        )}
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      key={placement}
      className="drawer_menu"
      style={{ width: "280px" }}
    >
      <div className="web_drawer">
        <DrawerNav />
      </div>
      <div className="mobile">
        <Document />
        {userStore.role === "coach"
      && (
      <NavLink to="/subscriptions">
        <div className="settings">
          <Icon name="subscription" />
          Пакеты
        </div>
      </NavLink>
      )}
        <NavLink to="/settings">
          <div className="settings">
            <Icon name="setting" />
            Настройки
          </div>
        </NavLink>
        <NavLink to="/dashboard">
          <div className="settings" onClick={logOut}>
            <Icon name="exit" />
            Выйти
          </div>
        </NavLink>
      </div>
    </Drawer>
  );
});
export default DrawerMenu;
