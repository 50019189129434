import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "antd";
import { RootStoreContext } from "../../../store/rootStore.ts";
import LandingInfo from "./new-landing/info";
import LandingCoaches from "./new-landing/coaches";
import LandingAppStore from "./new-landing/app-store";
import LandingFooter from "./new-landing/footer";
import LandingWork from "./new-landing/work";
import LandingLine from "./new-landing/line";
import LandingMain from "./new-landing/main";
import "./style.scss";

function Landing() {
  const { userStore } = useContext(RootStoreContext);
  const navigate = useNavigate();
  const coach = useRef(null);
  // const handleNav = () => {
  //   userStore.setCoach(true);
  //   navigate("/register");
  // };
  return (
    <div className="landing">
      <div>
        <LandingMain coachHeader={coach} />
        <LandingLine />
      </div>
      <LandingInfo />
      <LandingWork coachRef={coach} />
      <LandingCoaches />
      <div className="end">
        <LandingAppStore />
        <LandingFooter />
      </div>
    </div>
  );
}

export default Landing;
