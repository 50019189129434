import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment/moment";
import { getAppointmentsClient } from "../api";
import { RootStore } from "./rootStore.ts";

export class ClientAppointmentsStore {
  rooStore: RootStore;
  appointments: any[];
  isLoading: boolean;
  today: any;
  future: any;
  constructor(rooStore: RootStore) {
    this.rooStore = rooStore;
    this.appointments = [];
    this.isLoading = false;
    this.today = 0;
    this.future = 0;
    makeAutoObservable(this);
  }
  // setToday(today: any) {
  //   runInAction(() => {
  //     this.today = today;
  //   });
  // }
  //
  // setFuture(future: any) {
  //   runInAction(() => {
  //     this.future = future;
  //   });
  // }
  calculateTodayAndFutureAppointments() {
    const now = moment();
    const today = moment();
    const todayEnd = moment().endOf("day");
    const dateIn20Days = today.clone().add(20, "days");
    const nowMinutes = now.hours() * 60 + now.minutes();
    this.today = this.appointments.filter((appointment) => {
      const endOfAppointment = moment.utc(appointment.endOfAppointment);
      const endMinutes = endOfAppointment.hours() * 60 + endOfAppointment.minutes();
      const isSameDay = endOfAppointment.isSame(today, "day");
      const isEndingTodayOrStarted = (endMinutes - nowMinutes) >= 0;
      return isEndingTodayOrStarted && isSameDay;
    }).length;
    this.future = this.appointments.filter((appointment) => {
      const startOfAppointment = moment.utc(appointment.startOfAppointment);
      const isAfterToday = startOfAppointment.isAfter(todayEnd, "day");
      const isBefore20Days = startOfAppointment.isBefore(dateIn20Days, "day");
      return isAfterToday && isBefore20Days;
    }).length;
  }

  async fetchAppointments(page = 0) {
    const today = moment().toISOString();
    const twentyDaysFromNow = moment(today).add(20, "days").toISOString();
    const { authStore } = this.rooStore;
    if (this.isLoading || !authStore?.token) {
      // console.log("Fetching aborted due to loading status or missing token");
      return; // Прерывание, если уже идет загрузка или нет токена
    }

    this.isLoading = true; // Начало загрузки
    try {
      if (authStore?.token) {
        const filter = {
          from: today,
          to: twentyDaysFromNow,
          size: 40,
          page,
        };
        const response = await getAppointmentsClient(filter);
        const existingIds = new Set(
          this.appointments?.map((coach) => coach.id),
        );
        const uniqueNewCoaches = response?.content?.filter(
          (coach: any) => !existingIds.has(coach.id),
        );
        runInAction(() => {
          this.appointments = [...this.appointments, ...uniqueNewCoaches];
          this.calculateTodayAndFutureAppointments();
        });
        if (response?.content.length === 20) {
          // console.log("Fetching next page", page + 1);
          await this.fetchAppointments(page + 1);
        }
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
