import React, { useContext, useEffect, useState } from "react";
import {
  Form, Radio, Button, notification, InputNumber, Input, Divider,
} from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import mixpanel from "../../../../helpers/mix-panel/mixpanel";
import Names from "../../../../components/names";
import { RootStoreContext } from "../../../../store/rootStore.ts";
import "./style.scss";
import AvatarName from "../../../../components/avatar-name";
import CalendarProfile from "../../../../components/calendar-profile";
import Icon from "../../../../components/icon";

const TimeTableIndividualMentor = observer(() => {
  const {
    individualAppointmentsStore,
  } = useContext(RootStoreContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [trainingType, setTrainingType] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    individualAppointmentsStore.getIndividualAppointments();
  }, []);

  useEffect(() => {
    // Попытаемся получить данные формы из localStorage
    const savedFormData = JSON.parse(localStorage.getItem("formData"));
    // Если данные существуют, устанавливаем их в форму
    if (savedFormData) {
      form.setFieldsValue(savedFormData);
    }
  }, [form]);

  useEffect(() => {
    // Получаем сохраненные данные формы из localStorage, если они есть
    const savedFormData = JSON.parse(localStorage.getItem("formData")) || {};

    // Значения по умолчанию из настроек, если они есть
    const defaultValues = individualAppointmentsStore.settings ? {
      experience: savedFormData.experience || individualAppointmentsStore.settings.experience,
      individualPrice: savedFormData.individualPrice || individualAppointmentsStore.settings.individualPrice,
      offlineLocation: savedFormData.offlineLocation || individualAppointmentsStore.settings.offlineLocation,
      offlineName: savedFormData.offlineName || individualAppointmentsStore.settings.offlineName,
      howCanHelp: savedFormData.howCanHelp || individualAppointmentsStore.settings.howCanHelp,
      bankData: savedFormData.bankData || individualAppointmentsStore.settings.bankData,
      trainingType: savedFormData.trainingType || (individualAppointmentsStore.settings.availableOffline ? "offline" : "online"),
    } : {};

    // Устанавливаем начальные значения формы
    form.setFieldsValue(defaultValues);

    // Устанавливаем значение тренировки в сторе и локальном состоянии
    setTrainingType(defaultValues.trainingType);
    individualAppointmentsStore.setOffline(defaultValues.trainingType === "offline");
    if (individualAppointmentsStore?.speciality) {
      form.setFieldsValue({
        specializations: individualAppointmentsStore.speciality || individualAppointmentsStore.settings?.specialities,
      });
    } else {
      individualAppointmentsStore.setSpeciality(individualAppointmentsStore.settings?.specialities);
      form.setFieldsValue({
        specializations: individualAppointmentsStore.speciality });
    }
  }, [individualAppointmentsStore.settings, form]);

  const handleTrainingTypeChange = (e) => {
    const { value } = e.target;
    setTrainingType(value);
    individualAppointmentsStore.setOffline(value === "offline");
    handleFormChange("trainingType", value);
  };

  const handleSpecialization = async () => {
    navigate("/specialization");
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  const handleDone = async (values) => {
    if (!individualAppointmentsStore.workingSchedule?.length) {
      navigate("/setting/profile-coach/work-hours");
    } else {
      if (!individualAppointmentsStore.speciality || individualAppointmentsStore.speciality.length === 0) {
        notification.error({
          message: t("error"),
          description: t("choseSpec"),
          placement: "topRight",
        });
        return;
      }
      const data = {
        individualPrice: values.individualPrice,
        experience: values.experience,
        qualification: values.qualification || null,
        bankData: values.bankData,
        offlineLocation: values.offlineLocation,
        offlineName: values.offlineName,
        howCanHelp: values.howCanHelp,
      };
      if (!individualAppointmentsStore.workingSchedule?.length || !individualAppointmentsStore.settings?.worktimes?.length) {
        mixpanel.track("Training Created", {
          Specialization: individualAppointmentsStore.speciality,
          Price: values.individualPrice,
          Format: individualAppointmentsStore.isOffline ? t("offline") : t("online"),
        });
      }
      if (!individualAppointmentsStore.isLoading && !individualAppointmentsStore.workingSchedule?.length) {
        individualAppointmentsStore.setWorkingSchedule(individualAppointmentsStore.settings.worktimes);
      } else {
        individualAppointmentsStore.setWorkingSchedule(individualAppointmentsStore.workingSchedule);
      }
      await individualAppointmentsStore.fetchIndividualAppointments(data);
      if (individualAppointmentsStore.response && !individualAppointmentsStore.isLoading) {
        notification.success({
          description: t("trainingSuccessfullyCreated"),
          placement: "topRight",
        });
        localStorage.removeItem("formData");
        localStorage.removeItem("savedData");
        navigate(-1);
      } else {
        notification.error({
          message: t("error"),
          description: t("notificationFailText"),
          placement: "topRight",
        });
      }
    }
  };

  const handleClear = () => {
    localStorage.removeItem("savedData");
    localStorage.removeItem("type");
  };
  const handleFormChange = (fieldName, value) => {
    // Получаем текущие данные формы из localStorage, если они существуют, иначе создаем новый пустой объект
    const formData = JSON.parse(localStorage.getItem("formData")) || {};

    // Обновляем соответствующее поле в объекте данных формы
    formData[fieldName] = value;

    // Сохраняем обновленные данные формы обратно в localStorage
    localStorage.setItem("formData", JSON.stringify(formData));
  };
  return (
    <div className="container_mobile timetable_individual">
      <Names title={t("back")} name={t("createSession")} handleClick={handleClear} />
      <AvatarName />
      <Divider />
      <Form layout="vertical" form={form} onFinish={handleDone} onKeyPress={handleKeyPress}>
        <div className="flow">
          <div className="war" onClick={handleSpecialization}>
            <div className="text_war">
              {t("specialization")}
            </div>
            <div className="flex gap-10 alignC pointer">
              <div className="title_war">
                {t("change")}
              </div>
              <Icon name="arrow_rigth" />
            </div>
          </div>
          {individualAppointmentsStore.speciality
          && (
          <div className="flower">
            {individualAppointmentsStore.speciality?.map((item) => <div className="card" key={item}>{item}</div>)}
          </div>
          )}
        </div>
        <Divider />
        <Form.Item
          label={t("experience")}
          name="experience"
          rules={[{ required: true, message: t("experienceRule") }]}
        >
          <InputNumber min={1} max={50} placeholder={`(${t("year")})`} block onChange={(value) => handleFormChange("experience", value)} />
        </Form.Item>
        <Divider />
        <Form.Item label={t("costOfOneWorkout")} name="individualPrice" rules={[{ required: true, message: t("costOfOneWorkoutRule") }]}>
          <InputNumber min={1} max={99999} placeholder={`(${t("som")})`} onChange={(value) => handleFormChange("individualPrice", value)} />
        </Form.Item>
        <Divider />

        <Form.Item className="button_chose" label={t("formatTraining")}>
          <Radio.Group
            onChange={handleTrainingTypeChange}
            value={
                individualAppointmentsStore.isOffline ? "offline" : "online"
              }
          >
            <Radio value="online">{t("online")}</Radio>
            <Radio value="offline">{t("offline")}</Radio>
          </Radio.Group>
        </Form.Item>

        {individualAppointmentsStore.isOffline && (
          <>
            {/* <Form.Item label="Название" name="offlineName"> */}
            {/*  <Input placeholder="Введите название заведения" onChange={(e) => handleFormChange("offlineName", e.target.value)} /> */}
            {/* </Form.Item> */}

            <Form.Item label={t("address")} name="offlineLocation" rules={[{ required: true, message: "Пожалуйста, укажите адресс" }]}>
              <Input placeholder={t("enterAddress")} onChange={(e) => handleFormChange("offlineLocation", e.target.value)} />
            </Form.Item>
          </>
        )}
        <Divider />
        <Form.Item
          label={t("paymentMethod")}
          name="bankData"
          rules={[{ required: true, message: t("paymentMethodRule") }]}
        >
          <TextArea placeholder={t("shareYourPaymentInfo")} onChange={(e) => handleFormChange("bankData", e.target.value)} />
        </Form.Item>
        <div className="bank_title">{t("clientsWillSeeThisInformation")}</div>
        <Divider />
        <Form.Item
          label={t("howCanYouHelp")}
          name="howCanHelp"
          rules={[{ required: true, message: t("paymentMethodRuleRule") }]}
        >
          <TextArea
            className="description"
            onChange={(e) => handleFormChange("howCanHelp", e.target.value)}
            placeholder={t("describeYourTrainingMethod")}
          />
        </Form.Item>
        {/* eslint-disable-next-line no-mixed-operators */}
        {individualAppointmentsStore.workingSchedule?.length || individualAppointmentsStore.settings?.worktimes?.length ? (
          <CalendarProfile />
        ) : "" }
        <Button className="button" htmlType="submit">
          {individualAppointmentsStore.workingSchedule?.length || individualAppointmentsStore.settings?.worktimes?.length ? t("confirm") : t("continueText")}
        </Button>
      </Form>
    </div>
  );
});

export default TimeTableIndividualMentor;
