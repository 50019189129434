import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "../icon";
import { RootStoreContext } from "../../store/rootStore.ts";
import "./style.scss";

function DrawerNav() {
  const { userStore, authStore } = useContext(RootStoreContext);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location]);

  const logOut = () => {
    sessionStorage.removeItem("authToken");
    authStore.setToken(null);
    localStorage.clear();
  };
  return (
    <div className="drawer_nav">
      <NavLink to="/main">
        <div className="footer_nav" onClick={() => setActivePage("/main")}>
          <Icon
            name={activePage === "/main" ? "home_black" : "home_black"}
            className="footer_button"
          />
          <div className={activePage === "/main" ? "text_active" : "text"}>
            Главная
          </div>
        </div>
      </NavLink>
      <NavLink to="/client">
        <div className="footer_nav" onClick={() => setActivePage("/client")}>
          <Icon
            name={
              activePage === "/client" ? "client_black" : "client_black"
            }
            className="footer_button"
          />
          <div className={activePage === "/client" ? "text_active" : "text"}>
            {userStore.role === "coach" ? "Клиенты" : "Тренеры"}
          </div>
        </div>
      </NavLink>
      <NavLink to="/calendar">
        <div
          className="footer_nav"
          onClick={() => setActivePage("/calendar")}
        >
          <Icon
            name={activePage === "/calendar" ? "calendar_black" : "calendar_black"}
            className="footer_button"
          />
          <div className={activePage === "/calendar" ? "text_active " : "text"}>
            Календарь
          </div>
        </div>
      </NavLink>
      <NavLink to="/chat">
        <div className="footer_nav" onClick={() => setActivePage("/chat")}>
          <Icon
            name={activePage === "/chat" ? "chat_black" : "chat_black"}
            className="footer_button"
          />
          <div className={activePage === "/chat" ? "text_active " : "text"}>
            Чат
          </div>
        </div>
      </NavLink>
      <NavLink to="/settings">
        <div className="footer_nav">
          <Icon name="setting" />
          <div className={activePage === "/settings" ? "text_active " : "text"}>
            Настройки
          </div>
        </div>
      </NavLink>
      <NavLink to="/dashboard">
        <div className="footer_nav" onClick={logOut}>
          <Icon name="exit" />
          <div className={activePage === "/exit" ? "text_active " : "text"}>
            Выйти
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default DrawerNav;
