import React, { useContext, useEffect } from "react";
import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import moment from "moment/moment";
import mixpanel from "../../../helpers/mix-panel/mixpanel";
import Names from "../../../components/names";
import "./style.scss";
import { RootStoreContext } from "../../../store/rootStore.ts";
import AvatarAny from "../../../components/avatar-any";
import Icon from "../../../components/icon";

const Request = observer(() => {
  const { product } = useContext(RootStoreContext);
  const { t } = useTranslation();
  useEffect(() => {
    product.fetchRequest();
  }, []);

  const handleAccept = async (id) => {
    await product.confirmProduct(id);
    if (!product.isLoading) {
      await product.fetchRequest();
    }
  };

  const handleCancel = async (id, coach, client) => {
    mixpanel.track("Training Canceled", {
      Trainer: coach,
      Customer: client,
    });
    await product.cancelProduct(id);
    if (!product.isLoading) {
      await product.fetchRequest();
    }
  };

  const sortItemsByStatus = (a, b) => {
    if (a.status === "CREATED" && b.status !== "CREATED") {
      return -1;
    }
    if (a.status !== "CREATED" && b.status === "CREATED") {
      return 1;
    }
    return 0;
  };
  const formatAppointment = (appointment) => {
    const start = moment.utc(appointment.startOfAppointment);
    const end = moment.utc(appointment.endOfAppointment);
    return {
      date: start.format("DD MMMM"),
      time: `${start.format("HH:mm")} - ${end.format("HH:mm")}`,
    };
  };
  // Отсортированный массив
  const sortedContent = product.product?.content?.slice().sort(sortItemsByStatus) || [];

  return (
    <div className="container_mobile pt-20 coach_request">
      <Names name={t("requests")} title={t("profile")} />
      <div className="content">
        { product.isLoading ? <Spin /> : sortedContent.length > 0 ? sortedContent.map((item) => {
          const appointmentsFormatted = item.appointments
            ? item.appointments.map(formatAppointment)
            : [];

          let statusClass = "";
          let statusText = "";
          switch (item.status) {
            case "CREATED":
              statusText = t("awaitingConfirmation");
              statusClass = "status_created";
              break;
            case "CONFIRMED":
              statusText = t("confirmed");
              statusClass = "status_confirmed";
              break;
            case "DECLINED":
              statusText = t("rejected");
              statusClass = "status_declined";
              break;
            case "AWAITING_PAYMENT":
              statusText = t("awaitingPayment");
              statusClass = "status_awaiting_payment";
              break;
            default:
              break;
          }

          return (
            <div className="card" key={item.id}>
              <div className={`title ${statusClass}`}>{statusText}</div>
              <div className="flower">
                <div className="flow">
                  <AvatarAny url={item.clientAvatarUrl} />
                  <div className="flex flex-column gap-5">
                    <div className="text">
                      {item.clientFirstName}
                      {" "}
                      {item.clientLastName}
                    </div>
                    <div className="text">{item.isOffline ? t("offline") : t("online")}</div>
                  </div>
                </div>
              </div>
              <div className="train">
                {t("numberOfSessions")}
                :
                {" "}
                {appointmentsFormatted?.length}
              </div>
              {appointmentsFormatted.map((appointment, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="war">
                  <div className="flex gap-5">
                    <Icon name="calendar" />
                    <div className="text">
                      {appointment.date}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <Icon name="clock_big" />
                    <div className="text">
                      {appointment.time}
                    </div>
                  </div>
                </div>
              ))}
              <div className="time">
                {t("totalPrice")}
                :
                {" "}
                {item.fullPrice}
                {" "}
                {t("som")}
                .
              </div>
              {item.status === "CREATED" && (
              <div className="buttons">
                <Button block className="button cancel" type="primary" onClick={() => handleCancel(item.id, item.coachFirstName, item.clientFirstName)}>
                  {t("reject")}
                </Button>
                <Button block className="button" type="primary" onClick={() => handleAccept(item.id)}>
                  {t("confirm")}
                </Button>
              </div>
              )}
            </div>
          );
        }) : (
          <div className="title grow">
            {t("noRequest")}
          </div>
        )}
      </div>
    </div>
  );
});

export default Request;
