import React from "react";
import "./style.scss";

function ButtonPrimary(props) {
  const { text, size, primary, onClick } = props;
  return (
    <div className={`button_primary ${primary} ${size}`} onClick={onClick}>
      {text}
    </div>
  );
}

export default ButtonPrimary;
