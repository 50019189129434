import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment/moment";
import { getAppointments } from "../api";
import { IRootStore } from "./rootStore.ts";

export class AppointmentsStore {
  rootStore: IRootStore;
  appointments: any[];
  isLoading: boolean;

  today: any;
  future: any;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.appointments = [];
    this.isLoading = false;
    this.today = null;
    this.future = null || 0;
    makeAutoObservable(this);
  }
  calculateTodayAndFutureAppointments() {
    const now = moment();
    const today = moment();
    const todayEnd = moment().endOf("day");
    const dateIn20Days = today.clone().add(20, "days");
    const nowMinutes = now.hours() * 60 + now.minutes();
    this.today = this.appointments.filter((appointment) => {
      const endOfAppointment = moment.utc(appointment.endOfAppointment);
      const endMinutes = endOfAppointment.hours() * 60 + endOfAppointment.minutes();
      const isSameDay = endOfAppointment.isSame(today, "day");
      const isEndingTodayOrStarted = (endMinutes - nowMinutes) >= 0;
      return isEndingTodayOrStarted && isSameDay;
    }).length;
    this.future = this.appointments.filter((appointment) => {
      const startOfAppointment = moment.utc(appointment.startOfAppointment);
      const isAfterToday = startOfAppointment.isAfter(todayEnd, "day");
      const isBefore20Days = startOfAppointment.isBefore(dateIn20Days, "day");
      return isAfterToday && isBefore20Days;
    }).length;
  }
  async fetchAppointments(page = 0) {
    const today = moment().toISOString(); // Изменено для включения текущего времени
    // const sevenDaysAgo = moment(today).subtract(7, "days").toISOString();
    const twentyDaysFromNow = moment(today).add(20, "days").toISOString();
    const { authStore } = this.rootStore;

    try {
      if (authStore?.token) {
        runInAction(() => {
          this.isLoading = true;
        });

        const filter = {
          from: today,
          to: twentyDaysFromNow,
          size: 20,
          page,
        };

        const response = await getAppointments(filter);
        const existingIds = new Set(this.appointments?.map((coach) => coach.id));
        const uniqueNewCoaches = response?.content.filter(
          (coach: any) => !existingIds.has(coach.id),
        );
        runInAction(() => {
          this.appointments = [...this.appointments, ...uniqueNewCoaches];
          this.calculateTodayAndFutureAppointments();
        });
        if (response?.content.length > 18 && page === 0) {
          await this.fetchAppointments(page + 1);
        }
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
