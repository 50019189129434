import React from "react";
import Icon from "../icon";
import coach from "../../assets/imgs/hero_card.png";
import coach2 from "../../assets/imgs/hero_card2.png";
import coach5 from "../../assets/imgs/hero_card5.png";
import coach4 from "../../assets/imgs/hero_card4.png";
import coach3 from "../../assets/imgs/hero_card3.png";
import coach6 from "../../assets/imgs/hero_card6.png";
import coach7 from "../../assets/imgs/hero_card7.png";
import "./style.scss";

function LandingCoachesSlider(props) {
  const { onClick } = props;
  return (
    <div className="landing_coaches_slider">
      <div className="content">
        <div>
          <div className="img_container">
            <div className="crown">
              <div className="crown_text">Popular</div>
              <Icon name="crown" />
            </div>
            <img src={coach} alt="" className="img" />
            <div className="war_face">
              <div className="card">HIIT</div>
              <div className="card">Functional</div>
            </div>
          </div>
          <div className="paper">Perizat Z. 🇰🇬</div>
          <div>
            <div>
              <div className="flex alignC gap-5">
                <Icon name="star" />
                <div className="title">4.8</div>
                <div className="text">(Reviews: 79)</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="bag" />
                <div className="text">Experience:</div>
                <div className="title">3 years</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="discount" />
                <div className="text">Price:</div>
                <div className="title">80 AED / hour</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="img_container">
            <img src={coach2} alt="" className="img" />
            <div className="war_face">
              <div className="card">Strength</div>
              <div className="card">Functional</div>
            </div>
          </div>
          <div className="paper">Amir K. 🇦🇪</div>
          <div>
            <div>
              <div className="flex alignC gap-5">
                <Icon name="star" />
                <div className="title">4.9</div>
                <div className="text">(Reviews: 41)</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="bag" />
                <div className="text">Experience:</div>
                <div className="title">9 years</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="discount" />
                <div className="text">Price:</div>
                <div className="title">260 AED / hour</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="img_container">
            <img src={coach5} alt="" className="img" />
            <div className="war_face">
              <div className="card">Nutrition</div>
            </div>
          </div>
          <div className="paper">Jennie K. 🇨🇦</div>
          <div>
            <div>
              <div className="flex alignC gap-5">
                <Icon name="star" />
                <div className="title">4.7</div>
                <div className="text">(Reviews: 21)</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="bag" />
                <div className="text">Experience:</div>
                <div className="title">3 years</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="discount" />
                <div className="text">Price:</div>
                <div className="title">150 AED / hour</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="img_container">
            <div className="crown">
              <div className="crown_text">Popular</div>
              <Icon name="crown" />
            </div>
            <img src={coach4} alt="" className="img" />
            <div className="war_face">
              <div className="card">Crossfit</div>
            </div>
          </div>
          <div className="paper">Stas D. 🇦🇪</div>
          <div>
            <div>
              <div className="flex alignC gap-5">
                <Icon name="star" />
                <div className="title">4.6</div>
                <div className="text">(Reviews: 109)</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="bag" />
                <div className="text">Experience:</div>
                <div className="title">7 years</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="discount" />
                <div className="text">Price:</div>
                <div className="title">150 AED / hour</div>
              </div>
            </div>
          </div>
        </div>
        
        <div>
          <div className="img_container">
            <img src={coach3} alt="" className="img" />
            <div className="war_face">
              <div className="card">Fitness</div>
              <div className="card">Weight loss</div>
            </div>
          </div>
          <div className="paper">Alena A. 🇦🇪</div>
          <div>
            <div>
              <div className="flex alignC gap-5">
                <Icon name="star" />
                <div className="title">5.0</div>
                <div className="text">(Reviews: 17)</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="bag" />
                <div className="text">Experience:</div>
                <div className="title">4 years</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="discount" />
                <div className="text">Price:</div>
                <div className="title">220 AED / hour</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="img_container">
            <img src={coach6} alt="" className="img" />
            <div className="war_face">
              <div className="card">Stretching</div>
              <div className="card">Pilates</div>
            </div>
          </div>
          <div className="paper">Alina A. 🇰🇬</div>
          <div>
            <div>
              <div className="flex alignC gap-5">
                <Icon name="star" />
                <div className="title">4.6</div>
                <div className="text">(Reviews: 37)</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="bag" />
                <div className="text">Experience:</div>
                <div className="title">4 years</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="discount" />
                <div className="text">Price:</div>
                <div className="title">70 AED / hour</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="img_container">
            <div className="crown">
              <div className="crown_text">Popular</div>
              <Icon name="crown" />
            </div>
            <img src={coach7} alt="" className="img" />
            <div className="war_face">
              <div className="card">Fitness</div>
              <div className="card">Crossfit</div>
            </div>
          </div>
          <div className="paper">David K. 🇺🇦</div>
          <div>
            <div>
              <div className="flex alignC gap-5">
                <Icon name="star" />
                <div className="title">5.0</div>
                <div className="text">(Reviews: 43)</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="bag" />
                <div className="text">Experience:</div>
                <div className="title">7 years</div>
              </div>
              <div className="flex alignC gap-5">
                <Icon name="discount" />
                <div className="text">Price:</div>
                <div className="title">150 AED / hour</div>
              </div>
            </div>
          </div>
        </div>

        <div className="add_coach" onClick={onClick}>
          <div className="add_plus">+</div>
          <div className="add_text">Press to select your trainer</div>
        </div>
      </div>
    </div>
  );
}

export default LandingCoachesSlider;
