import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../components/icon";
import Validate from "../../../assets/imgs/validateps.png";

function ConfirmPassword() {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate("/login");
  };
  return (
    <div className="container_mobile validate_password ">
      <Icon name="close" onClick={handleNav} />
      <div className="validate_password_content">
        <div className="title">Отлично!!</div>
        <div className="text">Ваш пароль успешно сброшен.</div>
        <div>
          <img src={Validate} alt="" />
        </div>
      </div>
      <div className="title">
        По любым вопросам обращайтесь по номеру +996 123 456 789
      </div>
    </div>
  );
}

export default ConfirmPassword;
