import React, { useContext, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import ClientList from "../../../../components/client-list";
import Names from "../../../../components/names";
import { RootStoreContext } from "../../../../store/rootStore.ts";

function TimeTableChoseGroupMentor() {
  const { groupAppointmentsStore, clientStore } = useContext(RootStoreContext);
  const [selectedClients, setSelectedClients] = useState([]);
  const navigate = useNavigate();
  const handleClientsSelect = (clients) => {
    setSelectedClients(clients);
  };

  const handleButtonClick = () => {
    groupAppointmentsStore.setClientId(selectedClients);
    clientStore.fetchClient(selectedClients);
    navigate(-1);
  };
  return (
    <div className="container_mobile back_ground">
      <Names title="Назад" name="Ваши клиенты" />
      <ClientList mode="select" multi onClientsSelect={handleClientsSelect} />
      <Button className="button_done" onClick={handleButtonClick}>
        Выбрать
      </Button>
    </div>
  );
}

export default TimeTableChoseGroupMentor;
