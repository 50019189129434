import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import Avatar from "../avatar";
import { RootStoreContext } from "../../store/rootStore.ts";
import Hamburger from "../hamburger";
import Icon from "../icon";
import "./style.scss";

const Header = observer(() => {
  const { userStore } = useContext(RootStoreContext);
  return (
    <div className="header">
      <div className="header_left">
        <Hamburger />
      </div>
      <div className="div" />
      <div className="header_right">
        <NavLink to="/notifications">
          <Icon name="notification" />
        </NavLink>
        <div className="mobile">
          <Avatar />
        </div>
        <div className="content">
          <div className="title">
            Доброе утро,
          </div>
          <div>
            {userStore.user?.firstName}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Header;
