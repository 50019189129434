import { makeAutoObservable, runInAction } from "mobx";
import {
  cancelProduct,
  confirmProduct,
  getProductClient,
  getProductDetails,
  getRequest,
} from "../api/index";
import { RootStore } from "./rootStore.ts";

export class ProductStore {
  rootStore: RootStore;
  product: any | null;
  isOffline: boolean;
  locationName: null;
  locationAddress: null;
  startOfAppointment: string | null;
  endOfAppointment: string | null;
  id: number | null;
  description: null;
  duration: number;
  isLoading: boolean;
  time: any;
  created: any;
  productDetails: string | null;
  response: null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.product = null;
    this.isOffline = false;
    this.locationName = null;
    this.locationAddress = null;
    this.startOfAppointment = null;
    this.endOfAppointment = null;
    this.id = null;
    this.description = null;
    this.created = null;
    this.duration = 0;
    this.time = "";
    this.productDetails = null;
    this.isLoading = false;
    this.response = null;

    makeAutoObservable(this);
  }

  setId(id: number) {
    runInAction(() => {
      this.id = id;
    });
  }

  setDuration(duration: number) {
    runInAction(() => {
      this.duration = duration;
    });
  }

  setTime(time: any) {
    runInAction(() => {
      this.time = time;
    });
  }

  setOffline(offline: boolean) {
    runInAction(() => {
      this.isOffline = offline;
    });
  }

  setLocation(locationAddress: any) {
    runInAction(() => {
      this.locationAddress = locationAddress;
    });
  }

  async fetchProductClient(page = 0) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const filter = {
        // statuses: "CREATED",
        size: 20,
        page,
      };
      const response = await getProductClient(filter);
      runInAction(() => {
        if (page === 0) {
          this.product = response;
        } else {
          const existingIds = new Set(this.product?.content?.map((coach: any) => coach.id));
          const uniqueNewCoaches = response?.content?.filter(
            (coach: any) => !existingIds.has(coach.id),
          );
          this.product.content = [...this.product.content, ...uniqueNewCoaches];
        }
      });
      // runInAction(() => {
      //   this.product = response;
      // });
      if (response?.content.length > 18 && page === 0) {
        await this.fetchProductClient(page + 1);
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async fetchRequest(page = 0) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const filter = {
        size: 20,
        page,
      };
      const response = await getRequest(filter);
      runInAction(() => {
        if (page === 0) {
          this.product = response;
        } else {
          const existingIds = new Set(this.product?.content?.map((coach: any) => coach.id));
          const uniqueNewCoaches = response?.content?.filter(
            (coach: any) => !existingIds.has(coach.id),
          );
          this.product.content = [...this.product.content, ...uniqueNewCoaches];
        }
      });
      if (response?.content.length > 18 && page === 0) {
        await this.fetchRequest(page + 1);
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async fetchProductDetails() {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const response = await getProductDetails(this.id);
      runInAction(() => {
        this.productDetails = response;
      });
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async confirmProduct(id: any) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const response = await confirmProduct(id);
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async cancelProduct(id: any) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const response = await cancelProduct(id);
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async fetchRequestCreated() {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const filter = {
        statuses: "CREATED",
        size: 20,
        page: 0,
      };
      const response = await getRequest(filter);
      runInAction(() => {
        this.created = response?.content;
      });
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
