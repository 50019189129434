import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RootStoreContext } from "../../../store/rootStore.ts";
import CardAdd from "../../../components/card-add";
import TabMain from "../../../components/tab-main";
import "./style.scss";

const CoachMain = observer(() => {
  const { t } = useTranslation();
  const { userStore, appointmentsStore, notificationStore, individualAppointmentsStore } = useContext(RootStoreContext);
  const navigate = useNavigate();
  const today = moment().format("YYYY-MM-DD");
  const dateIn20Days = moment().add(20, "days").format("YYYY-MM-DD");

  useEffect(() => {
    appointmentsStore.fetchAppointments();
    individualAppointmentsStore.getIndividualAppointments();
    userStore.fetchUser();
  }, [userStore, appointmentsStore]); // Убедитесь, что все зависимости перечислены здесь

  const handleNav = () => {
    navigate("/subscriptions");
  };
  const handleApp = () => {
    navigate("/mentor/specialization/individual");
  };
  return (
    <div className="main">
      {/* <div className="web"> */}
      {/*  <Hamburger /> */}
      {/* </div> */}
      {/* /!* <Modal *!/ */}
      {/* /!*  title="" *!/ */}
      {/* /!*  open={userStore.user?.isSubscribed} *!/ */}
      {/* /!*  footer={<Button onClick={handleNav}>Купить</Button>} *!/ */}
      {/* /!*  closable={false} *!/ */}
      {/* /!* > *!/ */}
      {/* /!*  <p>Для доступа ко всем функциям приложения необходимо приобрести пакет.</p> *!/ */}
      {/* /!* </Modal> *!/ */}
      <div className="back_ground container_mobile main_page">
        <TabMain today={today} dateIn20Days={dateIn20Days} />
        { !individualAppointmentsStore.settings?.worktimes?.length
        && !individualAppointmentsStore.isLoading && <CardAdd title={t("createSession")} onClick={handleApp} />}
      </div>
    </div>
  );
});

export default CoachMain;
