import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { UserStore } from "./userStore.ts";
import { ClientIndividualAppointmentsStore } from "./clientIndividualAppointmentsStore.ts";
import { AvailableSlotsStore } from "./availableSlotsStore.ts";
import { GroupAppointmentsStore } from "./groupAppointmentsStore.ts";
import { IndividualAppointmentsStore } from "./individualAppointmentsStore.ts";
import { ChatRegisterStore } from "./chatRegisterStore.ts";
import { AuthStore } from "./authStore.ts";
import { ChatStore } from "./chatStore.ts";
import { SpecializationsStore } from "./specializationsStore.ts";
import { UsersListStore } from "./usersListStore.ts";
import { CoachesListStore } from "./coachesListStore.ts";
import { ClientAppointmentsStore } from "./clientAppointmentsStore.ts";
import { AppointmentsStore } from "./appointmenstStore.ts";
import { CoachStore } from "./coachStore.ts";
import { ClientStore } from "./clientStore.ts";
import { AppointmentDetailsStore } from "./appointmentDetailsStore.ts";
import { ChatDetailsStore } from "./chatDetailStore.ts";
import { CurrentAppointmentsStore } from "./currentAppointmentsStore.ts";
import { LastAppointmentsStore } from "./lastAppointmentsStore.ts";
import { WorkTimeStore } from "./workTimeStore.ts";
import { NotificationStore } from "./notificationsStore.ts";
import { Payment } from "./payment.ts";
import { LedgerStore } from "./ledger.ts";
import { ProductStore } from "./product.ts";

export interface IRootStore {
  authStore: AuthStore;
  userStore: UserStore;
  clientIndividualAppointmentsStore: ClientIndividualAppointmentsStore;
  availableSlotsStore: AvailableSlotsStore;
  groupAppointmentsStore: GroupAppointmentsStore;
  individualAppointmentsStore: IndividualAppointmentsStore;
  chatRegisterStore: ChatRegisterStore;
  chatStore: ChatStore;
  specializationsStore: SpecializationsStore;
  usersListStore: UsersListStore;
  coachesListStore: CoachesListStore;
  clientAppointmentsStore: ClientAppointmentsStore;
  appointmentsStore: AppointmentsStore;
  appointmentDetailsStore: AppointmentDetailsStore;
  coachStore: CoachStore;
  clientStore: ClientStore;
  chatDetailsStore: ChatDetailsStore;
  currentAppointmentsStore: CurrentAppointmentsStore;
  lastAppointmentsAtore: LastAppointmentsStore;
  workTimeStore: WorkTimeStore;
  notificationStore: NotificationStore;
  payment: Payment;
  ledger: LedgerStore;
  product: ProductStore;
}

export class RootStore {
  authStore: AuthStore;
  userStore: UserStore;
  clientIndividualAppointmentsStore: ClientIndividualAppointmentsStore;
  availableSlotsStore: AvailableSlotsStore;
  groupAppointmentsStore: GroupAppointmentsStore;
  individualAppointmentsStore: IndividualAppointmentsStore;
  chatRegisterStore: ChatRegisterStore;
  chatStore: ChatStore;
  specializationsStore: SpecializationsStore;
  usersListStore: UsersListStore;
  coachesListStore: CoachesListStore;
  clientAppointmentsStore: ClientAppointmentsStore;
  appointmentsStore: AppointmentsStore;
  appointmentDetailsStore: AppointmentDetailsStore;
  coachStore: CoachStore;
  clientStore: ClientStore;
  chatDetailsStore: ChatDetailsStore;
  currentAppointmentsStore: CurrentAppointmentsStore;
  lastAppointmentsAtore: LastAppointmentsStore;
  workTimeStore: WorkTimeStore;
  notificationStore: NotificationStore;
  payment: Payment;
  ledger: LedgerStore;
  product: ProductStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.clientIndividualAppointmentsStore = new ClientIndividualAppointmentsStore(this);
    this.availableSlotsStore = new AvailableSlotsStore(this);
    this.groupAppointmentsStore = new GroupAppointmentsStore(this);
    this.individualAppointmentsStore = new IndividualAppointmentsStore(this);
    this.chatRegisterStore = new ChatRegisterStore(this);
    this.chatStore = new ChatStore(this);
    this.specializationsStore = new SpecializationsStore(this);
    this.usersListStore = new UsersListStore(this);
    this.coachesListStore = new CoachesListStore(this);
    this.clientAppointmentsStore = new ClientAppointmentsStore(this);
    this.appointmentsStore = new AppointmentsStore(this);
    this.appointmentDetailsStore = new AppointmentDetailsStore(this);
    this.coachStore = new CoachStore(this);
    this.clientStore = new ClientStore(this);
    this.chatDetailsStore = new ChatDetailsStore(this);
    this.currentAppointmentsStore = new CurrentAppointmentsStore(this);
    this.lastAppointmentsAtore = new LastAppointmentsStore(this);
    this.workTimeStore = new WorkTimeStore(this);
    this.notificationStore = new NotificationStore(this);
    this.payment = new Payment(this);
    this.ledger = new LedgerStore(this);
    this.product = new ProductStore(this);
    makeAutoObservable(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
