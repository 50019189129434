import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { isSafari } from "react-device-detect";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

import "./style/index.scss";
import "./style/custom-bootstrap.scss";
import { RootStore } from "./store/rootStore.ts";

const queryClient = new QueryClient();

if ("serviceWorker" in navigator && !isSafari) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker зарегистрирован с помощью следующего scope:", registration.scope);

      let serviceWorker;
      if (registration.installing) {
        serviceWorker = registration.installing;
        console.log("Service worker installing");
      } else if (registration.waiting) {
        serviceWorker = registration.waiting;
        console.log("Service worker installed & waiting");
      } else if (registration.active) {
        serviceWorker = registration.active;
        console.log("Service worker active");
      }

      if (serviceWorker) {
        console.log("sw current state", serviceWorker.state);
        if (serviceWorker.state === "activated") {
          console.log("sw already activated - Do watever needed here");
        }
        serviceWorker.addEventListener("statechange", (e) => {
          console.log("sw statechange : ", e.target.state);
          if (e.target.state === "activated") {
            console.log("Just now activated. now we can subscribe for push notification");
            serviceWorker.subscribeForPushNotification(registration);
          }
        });
      }
    })
    .catch((error) => {
      console.error("Ошибка при регистрации Service Worker:", error);
    });
}

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider rootStore={RootStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root"),
);

reportWebVitals();
