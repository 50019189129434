import React from "react";
import { useTranslation } from "react-i18next";
import Names from "../../../components/names";
import Icon from "../../../components/icon";
import "./style.scss";

function Help() {
  const { t } = useTranslation();
  const telegramUrl = "https://t.me/KunduzAn";
  const handleNav = () => {
    window.location.href = telegramUrl;
  };
  return (
    <div className="help container_mobile back_ground">
      <Names name={t("helpAndFeedback")} title={t("profile")} />
      <div className="content">
        <div className="title">
          {t("forHelpAndFeedbackWriteToUs")}
        </div>
        <div className="telegram" onClick={handleNav}>
          <Icon name="telegram" />
          <div className="text">{t("writeInTelegram")}</div>
        </div>
      </div>
    </div>
  );
}
export default Help;
