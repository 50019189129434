import React, { useState } from "react";
import Icon from "../../icon";
import "./style.scss";
import DrawerMenuLanding from "../drawer";

function HamburgerLanding(props) {
  const { open, setOpen, onClick } = props;
  return (
    <div className="hamburger_landing">
      <Icon name="hambergermenu" onClick={() => setOpen(true)} />
      <DrawerMenuLanding open={open} setOpen={setOpen} scroll={onClick} />
    </div>
  );
}

export default HamburgerLanding;
