import React, { useContext, useEffect } from "react";
import { getToken } from "firebase/messaging";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { isSafari } from "react-device-detect";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import mixpanel from "../../../helpers/mix-panel/mixpanel";
import { RootStoreContext } from "../../../store/rootStore.ts";
import { registerToken } from "../../../helpers/constants/registerToken.ts";
import { messaging } from "../../../helpers/sdk/sdk";
import AppDownloadModal from "../../../components/modal-deep-link";
import { GOOGLE_CLIENT_ID } from "../../../helpers/api";
import img from "../../../assets/imgs/login.png";
import "./style.scss";

const LoginCoach = observer(() => {
  const { chatRegisterStore, authStore, userStore } = useContext(RootStoreContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const telegramUrl = "https://t.me/KunduzAn";
  const handleNav = () => {
    window.location.href = telegramUrl;
  };
  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
    });

    window.google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }, // Параметры кнопки
    );
  }, []);

  const handleCredentialResponse = async (response) => {
    authStore.setToken(response.credential);
    if (authStore.token) {
      await userStore.fetchUser();
    }
    if (userStore.user && !userStore.isLoading) {
      if (isSafari) {
        console.log("Safari browser detected. Redirecting to main...");
        mixpanel.track("Sign Up Started", {
          role: "coach",
        });
        navigate("/main");
        return;
      }

      try {
        console.log("Попытка получения токена FCM...");
        const token = await getToken(messaging, { vapidKey: registerToken });
        console.log("token from fire base??", token);
        await chatRegisterStore.fetchChat(token);
        mixpanel.track("Sign Up Started", {
          role: "coach",
        });
        navigate("/main");
      } catch (err) {
        console.error("Error getting token or sending it to backend:", err);
        notification.error({
          message: t("error"),
          description: t("notificationLogin"),
          duration: 4.5,
        });
      }
    } else {
      console.log("добавь уведомление об ошибке не получилось войти");
    }
  };
  return (
    <div className="login_coach">
      <div className="part_one back_ground container">
        <img src={img} alt="" className="img" />
        <div className="logo">
          Move Mates
        </div>
        <div className="title">Начните предлагать свои тренировки и поддерживать клиентов с приложением Move Mates</div>
        <div>
          <div
            id="g_id_onload"
            data-client_id={GOOGLE_CLIENT_ID}
          // data-login_uri="http://localhost:3000/"
            data-auto_prompt="false"
          />
          <div
            className="g_id_signin"
            data-type="standard"
            data-size="large"
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-logo_alignment="left"
          />
          <div id="signInDiv" className="war" />
        </div>
        {/* <NavLink to="/register" className="nav"> */}
        {/*  <div className="register_text"> */}
        {/*    <span className="register_title">Создать новый аккаунт</span> */}
        {/*  </div> */}
        {/* </NavLink> */}
        <div className="trouble">{t("anyTrouble")}</div>
        <div className="contact" onClick={handleNav}>{t("contactWithUs")}</div>
      </div>
      <AppDownloadModal url="login" />
    </div>
  );
});

export default LoginCoach;
