import React from "react";
import "./style.scss";
import { Spin } from "antd";
import Icon from "../icon";

function AvatarAny({ url }) {
  return (
    <div className="avatar_any">
      {url ? (
        <img src={url} alt="" className="avatar" />
      ) : (
        <Icon name="avatar" className="avatar" />)}
    </div>
  );
}

export default AvatarAny;
