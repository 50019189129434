import React from "react";
import Icon from "../../icon";
import "./style.scss";

function LandingImg(props) {
  const { img, text, subText, crown, rate } = props;
  return (
    <div className="landing_img">
      {rate
        && (
        <div className="crown">
          <div className="crown_text">{rate}</div>
          <Icon name="crown" />
        </div>
        )}
      {crown
      && (
      <div className="crown">
        <div className="crown_text">Popular</div>
        <Icon name="crown" />
      </div>
      )}
      <img src={img} alt="" className="img" />
      <div className="war_face">
        <div className="card">{text}</div>
        {subText 
        && <div className="card">{subText}</div>}
      </div>
    </div>
  );
}

export default LandingImg;
