import React, { useState, useContext } from "react";
import { getToken } from "firebase/messaging";
import {
  Button, Input, Divider, Form, Alert, Select,
} from "antd";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import "./style.scss";
import { login } from "../../../api";
import Names from "../../../components/names";
import { RootStoreContext } from "../../../store/rootStore.ts";
import { registerToken } from "../../../helpers/constants/registerToken.ts";
import { messaging } from "../../../helpers/sdk/sdk";
import Logo from "../../../components/logo";
import img from "../../../assets/imgs/login_img.png";
import AppDownloadModal from "../../../components/modal-deep-link";

const Login = observer(() => {
  const { userStore, chatRegisterStore, authStore } = useContext(RootStoreContext);
  const [role, setRole] = useState("CLIENT");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const handleSubmit = async (values) => {
    const payload = {
      username: values?.email,
      password: values?.password,
      role,
    };
    try {
      const response = await login(payload);
      if (response) {
        userStore.setRole(response.roles[0].toLowerCase());
        authStore.setToken(response?.token);
        getToken(messaging, { vapidKey: registerToken })
          .then((token) => {
            chatRegisterStore.fetchChat(token);
          })
          .catch((err) => {
            console.error("Error getting token or sending it to backend:", err);
          });
        try {
          const apiResponse = await axios.post("http://172.16.7.31:8086/api/users/register", { token: response?.token }, {
            headers: {
              Authorization: `Bearer ${response?.token}`,
            },
          });
          console.log("API response:", apiResponse);
        } catch (apiError) {
          console.error("Error during API call:", apiError);
        }
        navigate("/main");
      } else {
        setError(true);
      }
    } catch (e) {
      console.log("Error while trying to login:", e);
      setError(true);
    }
    localStorage.removeItem("formData");
  };

  return (
    <div className="login">
      <div className="part_one">
        <div>
          <Names title="назад" />
          <Logo text />
          <div className="login_text">
            Добро пожаловать в
            {" "}
            <span className="text_movemates">MoveMates</span>
          </div>
        </div>
        {error && (
        <Alert
          message="Неправильный логин или пароль"
          type="error"
          className="alert"
        />
        )}
        <div className="login_input">
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{ email: "", password: "", role: "CLIENT" }}
            className="form_login"
            layout="vertical"
          >
            <div>
              <div className="login_input_title">
                <Form.Item
                  name="email"
                  label="Е-майл"
                  rules={[
                    { required: true, message: "Пожалуйста, введите ваш е-майл" },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </div>

              <div className="title">Пароль</div>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Пожалуйста, введите ваш пароль" },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <div className="login_input_title">
                {/* <div className="title">Роль</div> */}
                <Form.Item
                  label="Роль"
                  name="role"
                  rules={[
                    { required: true, message: "Пожалуйста выберите вашу роль" },
                  ]}
                >
                  <Select onChange={(value) => setRole(value)} placeholder="Клиент">
                    <Select.Option value="CLIENT">Клиент</Select.Option>
                    <Select.Option value="COACH">Тренер</Select.Option>
                  </Select>
                </Form.Item>
              </div>

              <div className="forget_password_title">
                <NavLink to="/accounts/password/reset" className="nav text">
                  Забыли пароль ?
                  {" "}
                </NavLink>
              </div>
            </div>

            <div>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="button" block>
                  Войти
                </Button>
              </Form.Item>
              <Divider>или</Divider>
              {/* <Button className="button" block> */}
              {/*  Google */}
              {/* </Button> */}

              <NavLink to="/register" className="nav">
                <div className="register_text">
                  Нет аккаунта?
                  {" "}
                  <span className="register_title"> Зарегистрируйтесь.</span>
                </div>
              </NavLink>
            </div>
          </Form>
        </div>
      </div>

      <div className="part_two">
        <img src={img} alt="" />
      </div>

      <AppDownloadModal url="login" />
    </div>
  );
});

export default Login;
