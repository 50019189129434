import React, { useContext, useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import DailyIframe from "@daily-co/daily-js";
import { observer } from "mobx-react";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { RootStoreContext } from "../../../store/rootStore.ts";
import { VideoCallStart } from "../../../api";

const VideoCall = observer(() => {
  const videoRef = useRef(null);
  const {
    appointmentDetailsStore,
  } = useContext(RootStoreContext);
  const [loading, setLoading] = useState(true);
  const [callData, setCallData] = useState(null);
  const [key, setKey] = useState(0); // Дополнительное состояние для вызова перерендеринга
  const navigate = useNavigate(); // Использование useNavigate

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await VideoCallStart(appointmentDetailsStore.id);
        console.log("response?", response);
        if (response && response.roomUrl) {
          setCallData(response);
        }
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при выполнении запроса: ", error);
        setLoading(false);
      }
      setKey((prevKey) => prevKey + 1); // Обновляем ключ для перерендеривания
    };

    fetchData();
  }, [appointmentDetailsStore.id]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (callData && videoRef.current) {
      const callFrame = DailyIframe.createFrame(videoRef.current, {
        showLeaveButton: true,
        showFullscreenButton: true,
      });
      callFrame.join({ url: callData.roomUrl, token: callData.token });
      callFrame.on("left-meeting", () => {
        navigate("/main");
      });

      return () => {
        callFrame.destroy();
      };
    }
  }, [callData, key]);

  return (
    <div className="video_call" key={key}>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div ref={videoRef} className="video_call_ref" />
      )}
    </div>
  );
});

export default VideoCall;
