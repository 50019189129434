import React from "react";
import { useTranslation } from "react-i18next";
import AvatarAny from "../avatar-any";
import "./style.scss";

function CoachName(props) {
  const { name, url } = props;
  const { t } = useTranslation();
  return (
    <div className="coach_name">
      <AvatarAny url={url} />
      <div className="flex flex-column gap-5">
        <div className="coach_name_title">
          {name}
        </div>
        <div className="coach_name_text">{t("coach")}</div>
      </div>
    </div>
  );
}

export default CoachName;
