import React, { useEffect, useRef } from "react";
import img from "../../../../../assets/imgs/hii.png";
import img2 from "../../../../../assets/imgs/gym.png";
import img3 from "../../../../../assets/imgs/nut.png";
import img4 from "../../../../../assets/imgs/pos.png";
import img5 from "../../../../../assets/imgs/str.png";
import img6 from "../../../../../assets/imgs/yog.png";
import img7 from "../../../../../assets/imgs/fun.png";
import img8 from "../../../../../assets/imgs/fit.png";
import img9 from "../../../../../assets/imgs/fat.png";
import img10 from "../../../../../assets/imgs/cyc.png";
import img11 from "../../../../../assets/imgs/cro.png";
import img12 from "../../../../../assets/imgs/box.png";
import "./style.scss";

function LandingLine() {
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.scrollLeft = 180;
  }, []);
  return (
    <div className="landing_line">
      <div className="title">Select the areas that interest you</div>
      <div className="content container_landing" ref={contentRef}>
        <div className="img_container">
          <img src={img} alt="" className="img" />
          <div className="centered_text">HIIT</div>
        </div>
        <div className="img_container">
          <img src={img2} alt="" className="img" />
          <div className="centered_text">Gym</div>
        </div>
        <div className="img_container">
          <img src={img3} alt="" className="img" />
          <div className="centered_text">Nutrition</div>
        </div>
        <div className="img_container">
          <img src={img4} alt="" className="img" />
          <div className="centered_text">Postnatal</div>
        </div>
        <div className="img_container">
          <img src={img5} alt="" className="img" />
          <div className="centered_text">Strength</div>
        </div>
        <div className="img_container">
          <img src={img6} alt="" className="img" />
          <div className="centered_text">Yoga</div>
        </div>
        <div className="img_container">
          <img src={img7} alt="" className="img" />
          <div className="centered_text">Functional</div>
        </div>
        <div className="img_container">
          <img src={img8} alt="" className="img" />
          <div className="centered_text">Fitness</div>
        </div>
        <div className="img_container">
          <img src={img9} alt="" className="img" />
          <div className="centered_text">Fatloss</div>
        </div>
        <div className="img_container">
          <img src={img10} alt="" className="img" />
          <div className="centered_text">Cycling</div>
        </div>
        <div className="img_container">
          <img src={img11} alt="" className="img" />
          <div className="centered_text">Crossfit</div>
        </div>
        <div className="img_container">
          <img src={img12} alt="" className="img" />
          <div className="centered_text">Boxing</div>
        </div>
      </div>
    </div>
  );
}

export default LandingLine;
