import React, { useContext } from "react";
import { Tabs } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import CardInfo from "../card-info";
import "./style.scss";
import { RootStoreContext } from "../../store/rootStore.ts";

const TabMain = observer((props) => {
  const { userStore, clientAppointmentsStore, appointmentsStore } = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { today, dateIn20Days } = props;

  // Define the tabs using the 'items' format
  const items = [
    {
      label: (
        <div>
          {t("today")}
          {" "}
          (
          {userStore.role === "coach" ? appointmentsStore.today : clientAppointmentsStore.today}
          )
        </div>
      ),
      key: "today",
      children: <CardInfo selectedDate={today} />,
    },
    {
      label: (
        <div>
          {t("upcoming")}
          {" "}
          (
          {userStore.role === "coach" ? appointmentsStore.future : clientAppointmentsStore.future}
          )
        </div>
      ),
      key: "future",
      children: <CardInfo selectedDate={dateIn20Days} />,
    },
  ];
  return (
    <Tabs defaultActiveKey="today" className="tab_main" type="card" items={items} />
  );
});

export default TabMain;
