import React from "react";
import { Modal } from "antd";

function ModalWrapper({ children, isVisible, onClose, title }) {
  return (
    <Modal
      title={title}
      open={isVisible}
      onOk={onClose}
      onCancel={onClose}
    >
      {children}
    </Modal>
  );
}

export default ModalWrapper;
