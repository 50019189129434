import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { isIOS, isMobile } from "react-device-detect";
import Icon from "../icon";
import "./style.scss";

function AppDownloadModal(props) {
  const { url } = props;
  const [showAppStoreButton, setShowAppStoreButton] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const appUrl = `move-mates.app://${url}`;
  const appStoreUrl = "https://apps.apple.com/kg/app/move-mates/id6470747632";
  useEffect(() => {
    const modalShown = localStorage.getItem("modalShown");
    if (isMobile && !modalShown && isIOS) {
      showAppDownloadModal();
      localStorage.setItem("modalShown", "true");
    }
  }, []);

  const showAppDownloadModal = () => {
    setIsModalVisible(true);
  };

  const hideAppDownloadModal = () => {
    setIsModalVisible(false);
  };

  const openInApp = () => {
    const timeout = 2000;

    window.location.href = appUrl;

    setTimeout(() => {
      if (document.hasFocus()) {
        setShowAppStoreButton(true);
      }
    }, timeout);
  };

  return (
    <Modal
      title="Открыть в приложении"
      open={isModalVisible}
      onCancel={hideAppDownloadModal}
      footer={null}
      className="deep_link"
      closable={false}
    >
      <div className="content">
        {!showAppStoreButton && (
          <div className="flex alignC justify-s">
            <div className="flex gap-10 alignC">
              <Icon name="logo" />
              <div>Move Mate App</div>
            </div>
            <Button onClick={openInApp} type="primary">
              Открыть
            </Button>
          </div>
        )}
        {showAppStoreButton && (
          <div className="flex alignC justify-s">
            <div className="flex gap-10 alignC">
              <Icon name="apple_black" />
              <div>App Store</div>
            </div>
            <Button href={appStoreUrl} target="_blank" type="primary">Скачать</Button>
          </div>
        )}
        <div className="flex alignC justify-s">
          <div className="flex gap-10 alignC">
            <Icon name="google" />
            <div>Browser</div>
          </div>
          <Button onClick={hideAppDownloadModal}>Продолжить</Button>
        </div>
      </div>
    </Modal>
  );
}

export default AppDownloadModal;
