import React, { useContext } from "react";
import { observer } from "mobx-react";
import ProfileCoach from "../../coach/profile-coach";
import ProfileClient from "../../client/profile-client";
import { RootStoreContext } from "../../../store/rootStore.ts";

const Profile = observer(() => {
  const { userStore } = useContext(RootStoreContext);
  const userRoles = {
    client: <ProfileClient />,
    coach: <ProfileCoach />,
  };
  return <div>{userRoles[userStore.role] || null}</div>;
});

export default Profile;
