import React from "react";
import { Button, Input, Form } from "antd";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Names from "../../../components/names";
import { requestResetPassword } from "../../../api";

function ResetPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      await requestResetPassword(values);
      navigate("/accounts/password/validate");
    } catch (error) {
      console.log("Failed:", error);
    }
  };

  return (
    <div className="container_mobile reset_password">
      <Names title="Назад" name="Восстановление пароля" />

      <div className="reset_password_content">
        <div className="title">Забыли пароль?</div>
        <div className="text">
          Пожалуйста, введите свой адрес электронной почты, и мы вышлем вам
          инструкции по сбросу пароля.
        </div>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Введите ваш e-mail!" },
              { type: "email", message: "Неверный формат e-mail!" },
            ]}
          >
            <Input placeholder="Е-майл" />
          </Form.Item>

          <Button type="primary" htmlType="submit" className="button">
            Отправить инструкцию
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;
