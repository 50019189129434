import { makeAutoObservable, runInAction } from "mobx";
import { IRootStore } from "./rootStore.ts";

export class NotificationStore { 
  rootStore: IRootStore;
  notifications: any[];
  isLoading: boolean;
  current: boolean;
  message: string | null;
  startAppointment: null;
  videoCall: object;
  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.notifications = [];
    this.isLoading = false;
    this.current = false;
    this.message = null;
    this.videoCall = {};
    this.startAppointment = null;
    makeAutoObservable(this);
  }

  setCurrent(current: boolean) {
    runInAction(() => {
      this.current = current;
    });
  }

  setVideoCall(video: any) {
    runInAction(() => {
      this.videoCall = video;
    });
  }

  setStart(message: any) {
    runInAction(() => {
      this.startAppointment = message;
    });
  }
}
