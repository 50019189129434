import React, { useContext, useEffect } from "react";
import { Button, notification, Spin } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import Icon from "../icon";
import "./style.scss";
import MySlider from "../slider";
import { RootStoreContext } from "../../store/rootStore.ts";
import AvatarAny from "../avatar-any";

const CardInfo = observer((props) => {
  const { t } = useTranslation();
  const {
    userStore,
    clientAppointmentsStore,
    appointmentsStore,
    appointmentDetailsStore,
    usersListStore,
    notificationStore,
  } = useContext(RootStoreContext);
  const selectedDate = moment(props.selectedDate);
  const navigate = useNavigate();
  const today = moment();
  const tomorrow = moment().add(1, "days").startOf("day"); // Начало завтрашнего дня
  const dateIn20Days = moment(tomorrow).add(19, "days").endOf("day"); // Конец 20-дневного периода, начиная с завтра
  const handleStart = (id, video) => {
    console.log("video", video);
    if (userStore.role === "coach") {
      navigate("/video-call");
    } else if (userStore.role === "client") {
      if (video.roomUrl || notificationStore.startAppointment) {
        notificationStore.setVideoCall(video);
        console.log("polojilos video ???", notificationStore.videoCall);
        navigate("/video-call/client");
      } else {
        notification.info({
          message: t("notificationStartClient"),
          description: t("notificationDesClient"),
          duration: 5,
        });
      }
    }
    appointmentDetailsStore.setId(id);
  };
  const getTimeRemaining = (endOfAppointment) => {
    const now = moment();
    const endTime = moment.utc(endOfAppointment);
    if (selectedDate.isSame(today, "day")) {
      let remainingHours = endTime.hours() - now.hours();
      let remainingMinutes = endTime.minutes() - now.minutes();
      if (remainingMinutes < 0) {
        remainingHours -= 1;
        remainingMinutes += 60;
      }
      if (remainingHours < 0 || (remainingHours === 0 && remainingMinutes < 0)) {
        return { past: true };
      }

      return { hours: remainingHours, minutes: remainingMinutes };
    }
    // Логика для тренировок в рамках 20 дней
    const offset = now.utcOffset(); // смещение локального времени от UTC в минутах

    // приведение endTime к локальному времени с учетом смещения
    const adjustedStartTime = endTime.subtract(offset, "minutes");
    const duration = moment.duration(adjustedStartTime.diff(now));
    const days = duration.days();
    let remainingHours = duration.hours();
    const remainingMinutes = duration.minutes();
    if (days > 0) {
      remainingHours += days * 24;
    }

    if (remainingHours < 0 || (remainingHours === 0 && remainingMinutes < 0)) {
      return { past: true };
    }

    return { hours: remainingHours, minutes: remainingMinutes };
  };

  const formatTimeRemaining = (time) => {
    const duration = moment.duration(time);
    const days = Math.floor(duration.asDays()); // Оставшиеся дни
    const hours = duration.hours(); // Оставшиеся часы
    const minutes = duration.minutes(); // Оставшиеся минуты
    let formattedTime = "";
    if (days > 0) {
      formattedTime += `${days} ${t("days")} `;
    }
    if (hours > 0) {
      formattedTime += `${hours} ${t("hours")} `;
    }
    if (minutes > 0) {
      formattedTime += `${minutes} ${t("minutes")}`;
    }
    return `${t("startAppointment")}: ${formattedTime}`;
  };

  const isButtonEnabled = (startOfAppointment, endOfAppointment) => {
    const now = moment();
    const startTime = moment.utc(startOfAppointment);
    const endTime = moment.utc(endOfAppointment);
    const nowHours = now.hours();
    const nowMinutes = now.minutes();
    const startHours = startTime.hours();
    const startMinutes = startTime.minutes();
    const endHours = endTime.hours();
    const endMinutes = endTime.minutes();
    const nowInMinutes = nowHours * 60 + nowMinutes;
    const startTimeMinus15InMinutes = startHours * 60 + startMinutes - 15;
    const endTimeInMinutes = endHours * 60 + endMinutes;
    const isToday = startTime.isSame(today, "day");

    return isToday && nowInMinutes >= startTimeMinus15InMinutes && nowInMinutes < endTimeInMinutes;
  };

  let filteredCards = [];
  const nowMinutes = today.hours() * 60 + today.minutes();
  if (userStore.role === "coach") {
    if (appointmentsStore.appointments) {
      if (selectedDate.isSame(today, "day")) {
        filteredCards = appointmentsStore.appointments?.filter((item) => {
          const startOfAppointment = moment.utc(item.endOfAppointment);
          const startMinutes = startOfAppointment.hours() * 60 + startOfAppointment.minutes();
          const isSameDay = startOfAppointment.isSame(today, "day");
          const isStartingSoonOrStarted = (startMinutes - nowMinutes) && startMinutes >= nowMinutes;
          return isStartingSoonOrStarted && isSameDay;
        });
      } else if (selectedDate.isSame(dateIn20Days, "day")) {
        filteredCards = appointmentsStore.appointments?.filter((item) => {
          const startOfAppointment = moment.utc(item.endOfAppointment);
          const isAfterToday = startOfAppointment.isAfter(today, "day"); // Проверка, что тренировка после сегодняшнего дня
          const isBefore20Days = startOfAppointment.isBefore(dateIn20Days, "day"); // Проверка, что тренировка до конца 20-дневного периода
          return isAfterToday && isBefore20Days;
        });
      }
    }
  } else if (userStore.role === "client") {
    if (clientAppointmentsStore.appointments) {
      if (selectedDate.isSame(today, "day")) {
        filteredCards = clientAppointmentsStore.appointments?.filter((item) => {
          const startOfAppointment = moment.utc(item.endOfAppointment);
          const startMinutes = startOfAppointment.hours() * 60 + startOfAppointment.minutes();
          const isSameDay = startOfAppointment.isSame(today, "day");
          const isStartingSoonOrStarted = (startMinutes - nowMinutes) && startMinutes >= nowMinutes;
          return isStartingSoonOrStarted && isSameDay;
        });
      } else if (selectedDate.isSame(dateIn20Days, "day")) {
        filteredCards = clientAppointmentsStore.appointments.filter((item) => {
          const startOfAppointment = moment.utc(item.startOfAppointment);
          const isAfterToday = startOfAppointment.isAfter(today, "day"); // Проверка, что тренировка после сегодняшнего дня
          const isBefore20Days = startOfAppointment.isBefore(dateIn20Days, "day"); // Проверка, что тренировка до конца 20-дневного периода
          return isAfterToday && isBefore20Days;
        });
      }
    }
  }
  filteredCards.sort((a, b) => moment(a.calendar).diff(moment(b.calendar)));
  return (
    <div className="card_info">
      {userStore.role === "coach" && usersListStore.users?.length < 0 ? (
        <MySlider
          child1="У вас пока нет клиентов, для добавления клиентов в базу, отправьте им ссылку из «Действия»."
          child1_sub="Примечание: вас клиенты в системе уже видят и могу записаться к вам на тренировку."
          child2={
            "Для установления прочного доверия среди клиентов и обеспечения гладкой работы приложения для вас, рекомендуем заполнить ваш профиль. Просто зайдите в раздел настроек, выберите \"Edit\" и обновите информацию о себе. Это поможет нам обеспечить вам наилучший сервис.\n"
            + "\n"
          }
          className="main_slider"
        />
      ) : (
        !filteredCards?.length && (
          <div className="name">{t("noSessions")}</div>
        )
      )}
      {/* eslint-disable-next-line no-mixed-operators */}
      {clientAppointmentsStore.isLoading || appointmentsStore.isLoading && <Spin /> }
      {filteredCards.map((item) => {
        const buttonEnabled = isButtonEnabled(item.startOfAppointment, item.endOfAppointment);
        const formattedTimeRemaining = (startOfAppointment, endOfAppointment) => {
          const now = moment();
          const startTime = moment.utc(startOfAppointment);
          const endTime = moment.utc(endOfAppointment);
          const nowInMinutes = now.hours() * 60 + now.minutes();
          const startTimeInMinutes = startTime.hours() * 60 + startTime.minutes();
          const endTimeInMinutes = endTime.hours() * 60 + endTime.minutes();
          const startTimeMinus15InMinutes = startTimeInMinutes - 15;
          const isWithin15Minutes = nowInMinutes >= startTimeMinus15InMinutes && nowInMinutes < endTimeInMinutes;
          const isToday = startTime.isSame(today, "day");
          if (isWithin15Minutes && isToday) {
            return t("join");
          }
          const timeRemaining = getTimeRemaining(startOfAppointment);
          return formatTimeRemaining(timeRemaining);
        };
        return (
          <div className="card_info_container" key={`${item.id}${item.type}`}>
            {/* <div className="card_info_status right_conor"> */}
            {/*  <Icon name="check" /> */}
            {/* </div> */}
            <div className="card_info_info">
              <AvatarAny url={item.clientAvatarUrl ? item.clientAvatarUrl : item.coachAvatarUrl} />
              <div className="text">
                <div className="name">
                  {item.clientName ? item.clientName : item.coachFirstName}
                  {" "}
                  {item.clientName ? "" : item.coachLastName}
                </div>
                <div className="flex gap-5 alignC">
                  <div className="level">{item.speciality}</div>
                  <div className="level">
                    {item.isOffline ? `${t("offline")} •` : t("online")}
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card_content">
                <div className="card_item">
                  <Icon name="small_calendar" />
                  <div>
                    {moment.utc(item.startOfAppointment).format("dd, D MMMM")}
                  </div>
                </div>
                <div className="card_item">
                  <Icon name="clock" />
                  <div>
                    {moment.utc(item.startOfAppointment).format("HH:mm")}
                    -
                    {moment.utc(item.endOfAppointment).format("HH:mm")}
                  </div>
                </div>
              </div>
              <Button
                className="button_color"
                block
                onClick={() => handleStart(item.id, item)}
                disabled={!buttonEnabled}
              >
                {formattedTimeRemaining(item.startOfAppointment, item.endOfAppointment)}
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default CardInfo;
