import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./rootStore.ts";
import { getClientsList } from "../api";

export class UsersListStore { 
  rootStore: RootStore;
  users: any[];
  usersLoading: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.users = [];
    this.usersLoading = false;

    makeAutoObservable(this);
  }

  async fetchUsers(page = 0) {
    const { authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.usersLoading = true;
        });

        const filter = {
          size: 20,
          page,
        };

        const response = await getClientsList(filter);
        const existingIds = new Set(this.users?.map((coach) => coach.id));
        const uniqueNewCoaches = response?.content.filter(
          (coach: any) => !existingIds.has(coach.id),
        );

        runInAction(() => {
          this.users = [...this.users, ...uniqueNewCoaches];
        });
        if (response?.content.length > 18 && page === 0) {
          await this.fetchUsers(page + 1);
        }
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.usersLoading = false;
      });
    }
  }
}
