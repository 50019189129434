import React, { useState } from "react";
import Icon from "../icon";
import DrawerMenu from "../drawer";
import Menu from "../menu";
import "./style.scss";

function Hamburger() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Menu />
      <div className="hamburger">
        <Icon name="hambergermenu" onClick={() => setOpen(true)} />
        <DrawerMenu open={open} setOpen={setOpen} />
      </div>
    </>
  );
}

export default Hamburger;
