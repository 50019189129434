import React from "react";
import { Button } from "antd";
import Names from "../../../components/names";
import "./style.scss";

function PrivacyPolicy() {
  return (
    <div className="container_landing pt-20 privacy_policy">
      <Names title="Назад" name="Политика конфиденциальности" />
      <div className="box">
        <div className="content">
          <div className="text">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Политика обработки персональных данных (далее – Политика) регулирует правоотношения по обработке персональных данных между ИП "Лю-Мин-Чин Жылдыз", ИНН 10609198300672, адрес: г. Бишкек, ул. Токтогула 106 (далее – Компания) и Пользователем сайта            
            {" "}
            <a href="https://move-mates.com/" target="_blank" rel="noopener noreferrer" className="text">www.move-mates.com</a>
            {" "}
            (далее - Пользователь).
          </div>
          <div className="text">
            Под «Пользователем» понимается дееспособное физическое лицо, достигшее 18-летнего возраста и желающее заказать услуги Компании, оставить комментарий, зарегистрироваться на веб- сайте либо совершить иные действия, предусмотренные функционалом интернет ресурса Компании.
          </div>
          <div className="text">
            Под «Сайтом» или «Интернет-ресурсом Компании» понимается веб-сайт            
            {" "}
            <a href="https://move-mates.com/" target="_blank" rel="noopener noreferrer" className="text">www.move-mates.com</a>
            {" "}
            с учетом всех уровней доменных имен, принадлежащий Компании.
          </div>
          <div className="text">
            Под «обработкой персональных данных» понимается любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без использования таких средств. К таким действиям (операциям) можно отнести: сбор, получение, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
          </div>
          <div className="content">
            <div className="text">
              Настоящая Политика определяет порядок обработки персональных данных пользователей сайта            
              {" "}
              <a href="https://move-mates.com/" target="_blank" rel="noopener noreferrer" className="text">www.move-mates.com</a>
              {" "}
              (далее – Сайт), условия и принципы обработки персональных данных, права Пользователей и обязанности Компании, сведения о реализуемых мерах по защите обрабатываемых персональных данных.
            </div>
            <div className="text">
              Настоящая Политика действует в отношении всех персональных данных, которые Компания получает от Пользователей.
            </div>
            <div className="text">
              Пользователь соглашается с настоящей Политикой путем ввода его имени, номера телефона, а при необходимости – адреса электронной почты (e-mail) в специальное поле на Сайте с предложением отправить запрос на консультацию, записаться, отправить заказ или совершить иные действия, предусмотренные функционалом Сайта, и последующего нажатия кнопки «Отправить»,
              «Запросить консультацию» или других кнопок.
              {" "}
            </div>
          </div>
          <div className="content">
            <div className="text">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              В случае несогласия с условиями Политики Пользователь должен немедленно прекратить любое использование Сайта.
            </div>
          </div>
          <div className="content">
            <div className="text">
              При использовании функционала Сайта Пользователь может предоставлять Компании следующие персональные данные (перечень и виды персональных данных зависят от конкретных функциональных возможностей интернет-ресурса, используемых Пользователем): фамилия, имя, адрес электронной почты, номер контактного телефона, идентификационные данные пользователя (user ID).
            </div>
            <div className="text">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Предоставляя свои персональные данные, Пользователь соглашается на их обработку (вплоть до отзыва Пользователя своего согласия на обработку персональных данных), включая сбор, хранение, обезличивание, передачу третьим лицам в предусмотренных Политикой случаях, Компанией в целях предоставления Пользователю рекламной, справочной информации, сервисов в соответствии с функциональными возможностями Сайта и в иных целях согласно п.8 настоящей Политики. При обработке персональных данных Компания руководствуется Законом Кыргызской Республики от 14 апреля 2008 года № 58 «Об информации персонального характера» с учетом положений Общего регламента по защите данных (General Data Protection Regulation) Европейского Союза от 27 апреля 2016 года, иными законодательными актами Кыргызской Республики и локальными нормативными правовыми актами.
              {" "}
            </div>
          </div>
          <div className="content">
            <div className="text">
              Предоставленное Пользователем согласие на обработку персональных данных действует со дня предоставления такого согласия до момента достижения целей обработки или отзыва Пользователем согласия, если иное не предусмотрено действующим законодательством.
              Пользователь в любое момент может отозвать предоставленное Компании согласие в порядке, установленном настоящей Политикой.
              {" "}
            </div>
            <div>
              <div className="text">
                {/* eslint-disable-next-line no-tabs */}
                -	Если Пользователь желает уточнить персональные данные в случае, когда персональные данные являются неполными, неточными или неактуальными, либо желает отозвать свое согласие на обработку персональных данных, Пользователь должен направить официальный запрос Компании с темой «Уточнить персональные данные» или «Прекратить обработку персональных данных» на адрес электронной почты movematesapp@gmai.com. В письме необходимо указать свой электронный адрес и соответствующее требование.
              </div>
              <div className="text">
                - Компания использует предоставленные Пользователем данные в целях:
              </div>
              <div className="text">
                - регистрация и идентификация Пользователя на Сайте, предоставление Пользователю возможности полноценного использования Сайта;
              </div>
              <div className="text">
                - дальнейшей коммуникации по заявке Пользователя, в том числе третьими лицами – партнерами Компании для исполнения обязательств по заявке Пользователя,
                консультирования по вопросам оказания Компанией услуг;
              </div>
            </div>
            <div className="text">
              Я хочу уведомить пользователей этого Сервиса о том, что эти сторонние лица имеют доступ к их личной информации. Это необходимо для выполнения ими порученных им задач от нашего имени. Однако они обязаны не раскрывать и не использовать информацию в иных целях.

            </div>
          </div>
          <div className="content">
            <div className="text">
              создания аккаунта и представления доступа к своей учетной записи/ аккаунта на сайтах- партнерах;
              {" "}
            </div>
          </div>
          <div className="content">
            <div className="text">
              отправки сообщений информационного характера;
              {" "}
            </div>
          </div>
          <div className="content">
            <div className="text">
              рекламы, продвижения товаров, работ (услуг), в том числе на основании полученной информации о персональных предпочтениях и настройках Пользователя;
            </div>
            <div className="text">
              оценки и анализа работы Сайта Компании;
              {" "}
            </div>
          </div>
          <div className="content">
            <div className="text">
              аналитики эффективности размещения рекламы, статистических исследований на основе обезличенной информации, предоставленной Пользователем;
              {" "}
            </div>
            <div className="text">
              информирования Пользователя об акциях, скидках и специальных предложениях посредством рассылок по электронной почте, телефонной связи;
            </div>
            <div className="text">
              проведения маркетинговых исследований, в том числе с привлечением третьих лиц в качестве подрядчика (исполнителя, консультанта);
            </div>
            <div className="text">
              ведения коммуникации с банками-партнерами относительно предоставления Пользователю кредитов и/или согласования условий кредитования.
            </div>
            <div className="text">
              Компания не проверяет данные, представленные или указанные Пользователем. В связи с этим Компания исходит из того, что при представлении персональных данных Пользователь:
            </div>
            <div className="text">
              является дееспособным лицом. В случае недееспособности лица, использующего Сайт, согласие на обработку персональных данных предоставляется законным представителем.
              {" "}
            </div>
            <div className="text">
              указывает достоверную информацию о себе (либо о представляемом им недееспособном лице). Пользователь самостоятельно поддерживает представленные персональные данные в актуальном состоянии.
              {" "}
            </div>
            <div className="text">
              самостоятельно поддерживает представленные персональные данные в актуальном состоянии.
              {" "}
            </div>
            <div className="text">
              осознает, что информация на Сайте, размещаемая Пользователем, может становиться доступной для других лиц, может быть скопирована или распространена такими пользователями в случаях, предусмотренных Политикой.
              {" "}
            </div>
            <div className="text">
              Компания обрабатывает персональные данные на основе следующих принципов:
              {" "}
            </div>
            <div className="text">
              законности;
              {" "}
            </div>
            <div className="text">
              ограничения обработки персональных данных достижением конкретных заранее определенных законных целей;
              {" "}
            </div>
            <div className="text">
              недопущения обработки персональных данных, несовместимой с целями сбора и/или хранения полученных от Пользователя данных;
              {" "}
            </div>
            <div className="text">
              соответствия содержания и объема обрабатываемых персональных данных целям их обработки;
              {" "}
            </div>
            <div className="text">
              недопущения обработки избыточных по отношению к целям обработки данных;
              {" "}
            </div>
            <div className="text">
              уничтожение или обезличивание персональных данных в целях недопущения их раскрытия при достижении целей обработки данных, утраты необходимости такой обработки или при получении от Пользователя требования об уничтожении персональных данных либо поступлении заявления об отзыве согласия на обработку персональных данных.
            </div>
            <div className="text">
              Компания при обработке персональных данных принимает необходимые и достаточные организационные и технические меры для защиты персональных данных от неправомерного доступа к ним, а также от иных неправомерных действий в отношении персональных данных.
            </div>
            <div className="text">
              Обработка персональных данных Пользователей осуществляется Компанией с учетом следующего:
            </div>
            <div className="text">
              Обработка персональных данных Пользователей осуществляется с использованием баз данных на территории Кыргызской Республики.
            </div>
            <div className="text">
              {/* eslint-disable-next-line no-tabs */}
              Обработка	персональных данных осуществляется	как	с	использованием автоматизированных средств, так и без их использования.
            </div>
            <div className="text">
              Компания обязуется не передавать полученную от Пользователя информацию третьим лицам, кроме случаев, специально оговоренных в настоящей Политике.
            </div>
            <div className="text">
              Передача персональных данных Пользователей третьим лицам - партнерам Компании при необходимости осуществляется на основании согласия Пользователей в целях исполнения обязательств перед Пользователями.
              {" "}
            </div>
            <div className="text">
              Компания, а в случае необходимости передачи персональных данных Пользователей третьим лицам - партнерам Компании эти лица обязуются сохранять в тайне, не раскрывать и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено действующим законодательством и/или настоящей Политикой.
            </div>
            <div className="text">
              Хранение персональных данных Пользователей осуществляется на электронных носителях, а для целей исполнения обязательств перед Пользователями может осуществляться на материальных носителях после извлечения персональных данных.
            </div>
            <div className="text">
              Хранение персональных данных осуществляется в пределах срока, объективно необходимого для исполнения обязательств перед Пользователями, и определяется следующими событиями (в зависимости от того, какое событие наступит ранее):
              {" "}
            </div>

            <div className="text">до момента удаления Пользователем персональных данных посредством личного кабинета;</div>
            <div className="text">до момента уничтожения персональных данных Компанией в связи с поступлением от Пользователя требования об уничтожении персональных данных или отзыве согласия на их обработку;</div>
            <div className="text">до момента истечения срока действия согласия Пользователя.</div>
            <div className="text">
              Компания имеет право хранить персональные данные Пользователя, кроме случаев получения требования об уничтожении данных или отзыва согласия на обработку данных, в обезличенном виде после исполнения обязательств перед Пользователем для целей, указанных в п.
              8.7. настоящей Политики.
            </div>
            <div className="text">При необходимости передачи персональных данных Пользователей третьим лицам – партнерам Компании в случаях, прямо предусмотренных Политикой, такая передача осуществляется с соблюдением следующих условий:</div>
            <div className="text">третье лицо – партнер Компании обеспечивает конфиденциальность персональных данных при их обработке и использовании и обязуется не раскрывать данные иным лицам, а равно не распространять персональные данные Пользователей без их согласия;</div>
            <div className="text">Tретье лицо – партнер Компании гарантирует соблюдение следующих мер по обеспечению безопасности персональных данных при их обработке: использование средств защиты информации; обнаружение и фиксация фактов несанкционированного доступа к персональным данным и принятие мер по восстановлению персональных данных; ограничение доступа к персональным данным; контроль и оценка эффективности применяемых мер по обеспечению безопасности персональных данных, иных мер, предусмотренных законодательством;</div>
            <div className="text">Tретьему лицу – партнеру Компании запрещается осуществлять передачу и распространение персональных данных Пользователей.</div>
            <div className="text">Не считается нарушением обязательств, предусмотренных настоящей Политикой, передача информации в соответствии с обоснованными и применимыми требованиями законодательства Кыргызской Республики, а равно предоставление Компанией информации партнерам, действующим на основании договора (соглашения) с Компанией, для исполнения обязательств перед Пользователем; передача Компанией третьим лицам данных о Пользователе в обезличенной форме в целях оценки и анализа работы Сайта Компании, предоставления персональных рекомендаций, показа рекламных объявлений на основании персональных предпочтений и настроек Пользователя, а также проведения маркетинговых, аналитических и/или статистических исследований.</div>
            <div className="text">
              Компания вправе использовать технологию «cookies» («куки»). Cookies - данные, которые автоматически передаются Компании в процессе использования Сайта с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, географическое местоположение, информация о браузере и виде операционной системы устройства Пользователя, технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сайту.
              Файлы «сookies» не содержат конфиденциальную информацию. Файлы «cookies» используются для того, чтобы запоминать предпочтения и настройки Пользователя, а также для сбора аналитических данных о посещениях Сайта. Использование Сайта означает, что Пользователь соглашается с использованием всех файлов «cookies» и аналитических данных о посещениях Сайта, а также на их передачу третьим лицам.
            </div>
            <div className="text">Компания получает информацию об ip-адресе Пользователя и сведения о том, по ссылке с какого интернет-сайта он пришел. Данная информация не используется для установления личности посетителя.</div>
            <div className="text">Пользователь имеет право на получение от Компании информации, касающейся обработки его персональных данных. Компания безвозмездно предоставляет Пользователю или его представителю возможность ознакомления с персональными данными, относящимися к Пользователю.</div>
            <div className="text">В случае выявления неполноты, неточности или неактуальности сведений Компания на основании информации Пользователя вносит в персональные данные Пользователя необходимые изменения в срок, не превышающий 7 (семь) рабочих дней, и уведомляет Пользователя о внесенных изменениях.</div>
            <div className="text">В случае если Пользователь или его представитель предоставит Компании подтверждение факта незаконного получения или обработки его персональных данных, а равно факта несоответствия действий с его персональными данными целям обработки, Компания в срок, не превышающий 7 (семь) рабочих дней обязуется уничтожить такие персональные данные Пользователя и уведомить Пользователя о предпринятых мерах.</div>
            <div className="text">Компания обязуется прекратить обработку персональных данных Пользователя или обеспечить прекращение обработки третьим лицом – партнером Компании в случае:</div>
            <div className="text">выявления неправомерной обработки персональных данных Пользователя;</div>
            <div className="text">отзыва Пользователем согласия на обработку его персональных данных;</div>
            <div className="text">получения от Пользователя требования об уничтожении персональных данных;</div>
            <div className="text">достижения цели обработки персональных данных.</div>
            <div className="text">При наступлении указанных в настоящем пункте случаев Компания прекращает обработку персональных данных и обеспечивает уничтожение данных в срок, не превышающий 30 (тридцать) дней, если иной срок не установлен законодательством.</div>
            <div className="text">При невозможности уничтожения персональных данных Пользователя в указанный срок Компания производит блокировку персональных данных Пользователя и обеспечивает их уничтожение в срок, установленный законодательством, но не более 6 (шести) месяцев.</div>
            <div className="text">Компания самостоятельно определяет перечень третьих лиц – Партнеров Компании и доводит его до сведения Пользователей любыми доступными способами, в том числе путем публикации на Сайте.</div>
            <div className="text">Компания вправе вносить изменения в настоящую Политику в любое время. Актуальный текст Политики размещается на Сайте.</div>
            <div className="text">
              Продолжение пользования Сайтом или его сервисами после публикации новой редакции Политики означает принятие Политики и ее условий Пользователем.
              В случае несогласия с условиями Политики Пользователь должен немедленно прекратить использование Сайта и его сервисов.
            </div>
            <div className="text">Все вопросы по настоящей Политике и/или обработке персональных данных направляются на адрес электронной почты Компании: movematesapp@gmail.com</div>
            <div className="title">
              {" "}
              Сведения о Компании:
            </div>
            <div className="text">
              ИП “Лю-Мин-Чин Жылдыз”
              ИНН 10609198300672
              г. Бишкек, ул. Токтогула 106, 720001
              +996777255553
            </div>
          </div>
        </div>
        <a href="/pdf/privacy_policy_rus_jildiz.docx" download="Privacy_Policy.pdf">
          <Button className="button_done">Скачать Политику конфиденциальности</Button>
        </a>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
