import { makeAutoObservable, runInAction } from "mobx";
import { getCurrentAppointments } from "../api";
import { IRootStore } from "./rootStore.ts";

export class CurrentAppointmentsStore {
  rootStore: IRootStore;
  data: any[];
  id: number | null;
  isLoading: boolean;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.data = [];
    this.id = null;
    this.isLoading = false;

    makeAutoObservable(this);
  }

  setId(id: number) {
    runInAction(() => {
      this.id = id;
    });
  }

  async fetchCurrentAppointments() {
    try {
      if (this.id) {
        runInAction(() => {
          this.isLoading = true;
        });

        const response = await getCurrentAppointments(this.id);
        runInAction(() => {
          this.data = response?.content;
        });
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
