import React, { useContext, useState } from "react";
import {
  Button, Form, DatePicker, message,
} from "antd";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import Names from "../../components/names";
import CustomInput from "../../components/input";
import "./style.scss";
import { rescheduleAppointment } from "../../api";
import CalendarTime from "../../components/calendar-time";
import { BASE_TIME_ZONE } from "../../helpers/api";
import { RootStoreContext } from "../../store/rootStore.ts";

const Reschedule = observer(() => {
  const { clientIndividualAppointmentsStore, availableSlotsStore, appointmentDetailsStore } = useContext(RootStoreContext);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showTimeSlots, setShowTimeSlots] = useState(true);
  const navigate = useNavigate();
  const handleDateChange = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    availableSlotsStore.setDate(formattedDate);
    setSelectedDate(date);
    setShowTimeSlots(true);
  };
  const handleSubmit = async () => {
    try {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const [startTime, endTime] = clientIndividualAppointmentsStore.time?.split("-");
      const dateSelected = availableSlotsStore.date;
      const startMomentLocal = moment(`${dateSelected} ${startTime}`, "YYYY-MM-DD HH:mm");
      const endMomentLocal = moment(`${dateSelected} ${endTime}`, "YYYY-MM-DD HH:mm");
      const startMoment = startMomentLocal.clone().tz(BASE_TIME_ZONE, true);
      const endMoment = endMomentLocal.clone().tz(BASE_TIME_ZONE, true);
      const startOfAppointment = startMoment.format();
      const endOfAppointment = endMoment.format();

      const data = {
        startOfAppointment,
        endOfAppointment,
      };
      await rescheduleAppointment(data, appointmentDetailsStore.appointments.id);
      message.success("Данные успешно отправлены!");
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
      message.error("Произошла ошибка при отправке данных");
    }
    navigate(-1);
  };

  return (
    <Form className="container_mobile back_ground reschedule" onFinish={handleSubmit}>
      <Names name="Перенос" title="Отменить" className="cansel" noArrow />

      <div className="flex flex-column">
        <Form.Item label="Тип тренировки">
          <CustomInput noNav text={appointmentDetailsStore.appointments.type} />
        </Form.Item>

        <Form.Item label="Специализация">
          <CustomInput noNav text="стретчинг (МФР)" />
        </Form.Item>

        <Form.Item>
          <CustomInput
            noNav
            text={
              appointmentDetailsStore.appointments.locationAddress
                ? appointmentDetailsStore.appointments.locationAddress
                : "Онлайн"
            }
          />
        </Form.Item>

        <Form.Item label="Дата тренировки">
          <DatePicker
            format="YYYY-MM-DD"
            placeholder="Выберите дату"
            style={{ width: "100%" }}
            onChange={handleDateChange}
          />
        </Form.Item>

        {selectedDate && showTimeSlots && (
        <CalendarTime />
        )}
        <Form.Item label="Клиент" className="client_name">
          <CustomInput
            noNav
            text={appointmentDetailsStore.appointments.clientName}
          />
        </Form.Item>

        <Button className="button" type="primary" htmlType="submit">
          Готово
        </Button>
      </div>
    </Form>
  );
});

export default Reschedule;
