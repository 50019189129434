import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Icon from "../icon";
import Avatar from "../avatar";
import "./style.scss";
import { RootStoreContext } from "../../store/rootStore.ts";
import Hamburger from "../hamburger";
import Header from "../header";
import Url from "../url";

const ClientList = observer((props) => {
  const { usersListStore } = useContext(RootStoreContext);
  const { mode, multi, onClientsSelect } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [selectedClients, setSelectedClients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    onClientsSelect && onClientsSelect(selectedClients);
  }, [selectedClients, onClientsSelect]);

  useEffect(() => {
    usersListStore.fetchUsers();
  }, [usersListStore]);

  const handleClientClick = (item, id) => {
    if (mode === "display") {
      navigate(`/client/${id}`);
    } else if (mode === "select") {
      if (selectedClients.includes(item.id)) {
        setSelectedClients((prev) => prev.filter((clientId) => clientId !== item.id));
      } else if (multi) {
        setSelectedClients((prev) => [...prev, item.id]);
      } else {
        setSelectedClients([item.id]);
      }
    }
  };

  // if (usersListStore.usersLoading) return <Spin />;
  return (
    <div className="client_list">
      <div className="web">
        <Hamburger />
      </div>
      <div className="web_content back_ground">
        <Header />

        <Input
          prefix={<Icon name="search" />}
          placeholder={t("search")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {usersListStore.usersLoading && <Spin />}
        <div className="content">
          {usersListStore.users.length
            ? usersListStore.users.map((item) => (
              <div
                key={item.id}
                onClick={() => handleClientClick(item, item.id)}
                className={`card_client ${mode === "select" ? "select_mode" : ""} ${
                  selectedClients.includes(item.id) ? "selected" : ""
                }`}
              >
                {mode === "display" && (
                <>
                  {item.avatarUrl ? (
                    <img
                      src={item.avatarUrl}
                      alt="no img"
                      className="img"
                    />
                  ) : (
                    <Avatar />
                  )}
                  <div>
                    <div className="title">{item.firstName}</div>
                    <div className="text">Клиент</div>
                  </div>
                </>
                )}
                {mode === "select" && (
                <>
                  <div className="flex alignC gap-10">
                    <Avatar />
                    <div>
                      <div className="title">{item.firstName}</div>
                      <div className="text">item.place</div>
                    </div>
                  </div>
                  {selectedClients.includes(item.id) && <Icon name="check" />}
                </>
                )}
              </div>
            )) : (
              <div className="flex alignC flex-column justify-c pt-20 gap-20 title">
                <div>У вас нет клиентов</div>
                <Url /> 
              </div>
            )}
        </div>
      </div>
    </div>
  );
});

export default ClientList;
