import React from "react";
import Names from "../../../components/names";
import "./style.scss";

function Refund() {
  return (
    <div className="container_landing pt-20 privacy_policy">
      <Names title="Назад" name="Правила оплаты и возврата денежных средств" />
      <div className="box">
        <div className="content">
          <div className="text">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Настоящие Правила оплаты и возврата денежных средств (далее — Правила) регулируют отношения между Исполнителем и Заказчиком, связанные с оплатой услуг по Договору и возвратом оплаченной стоимости услуг в случае досрочного расторжения Договора.
          </div>
          <div className="text">
            1. Окончательная стоимость Услуг формируется с учетом выбранного Заказчиком товара/услуги. Оплата Услуг осуществляется в безналичном порядке одним из способов, указанных на Сайте, по выбору Заказчика путем зачисления денежных средств на счет Исполнителя.
          </div>
          <div className="text">
            2. При оплате на Сайте или посредством Личного кабинета к оплате принимаются карты VISA, MasterCard, ЭЛКАРТ, а также электронные кошельки.
            Перечисление платежей производится в соответствии с Правилами международных платежных систем Visa и MasterCard (далее – платежные системы) на принципах соблюдения конфиденциальности и безопасности совершения платежа с использованием современных методов проверки, шифрования и передачи данных по закрытым каналам связи. По вопросам правил и порядка осуществления оплаты посредством платежных систем Заказчик руководствуется правилами соответствующих платежных систем и обращается напрямую к операторам соответствующих платежных систем за разъяснениями и консультациями. Исполнитель настоящим оставляет за собой право оказывать информационную поддержку относительно осуществления оплаты Услуг с помощью платежных систем в пределах своих компетенций, однако ничто в настоящем приложении не обязывает его осуществлять такую информационную поддержку, отвечать за полноту предоставленной информации или нести иные последствия выбора способа оплаты услуг и/или платежной системы Заказчиком.
          </div>
          <div className="text">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            3. Для оплаты Услуг банковской картой Visa или MasterCard при выборе способа оплаты необходимо зарегестрироваться на платфоме, как "тренер" нажать кнопку "настройки" и далее "платежи", выбрать тариф, нажать "оплатить", ввести данные и для завершения платежа необходимо нажать  кнопку «Оплатить».
          </div>
          <div className="content">
            <div className="text">
              4. Оплата заказа осуществляется после перенаправления на защищенную платежную страницу оператора платежной системы и/или соответствующего банка для ввода реквизитов банковской карты. Соединение с платежной страницей и передача информации осуществляются в защищенном режиме с использованием технологии 3D Secure.
              На странице для ввода данных банковской карты Заказчику необходимо ввести данные банковской карты: номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные пропечатаны на самой карте. Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты. Если банк Заказчика поддерживает технологию безопасного проведения интернет-платежей MasterCard SecureCode, Verified by Visa, для проведения платежа может потребоваться ввод специального пароля.
            </div>
            <div className="text">
              5. Реквизиты банковской карты Заказчика не хранятся на сервере Исполнителя или на Сайте, Исполнитель доступа к платежным реквизитам Заказчика не имеет.
              Конфиденциальность сообщаемой Заказчиком информации, включая персональные данные и платежные реквизиты, обеспечивается оператором соответствующей платежной системы и/или банком            Заказчика.
              Введенная Заказчиком информация, включая платежные реквизиты, данные банковской карты не предоставляется третьим лицам за исключением случаев, предусмотренных законодательством Кыргызской Республики.

            </div>
          </div>
          <div className="content">
            <div className="text">
              6. В проведении платежа может быть отказано банком в следующих случаях:
            </div>
          </div>
          <div className="content">
            <div className="text">
              6.1 банковская карта не предназначена для совершения платежей через интернет; Заказчик может узнать об этом, обратившись в обслуживающий банк.
            </div>
            <div>
              <div className="text">
                6.2 недостаточно средств для оплаты на банковской карте.
                Подробнее о наличии средств на банковской карте Заказчик может узнать, обратившись в банк, выпустивший банковскую карту;

              </div>
              <div className="text">
                6.3 данные банковской карты введены неверно;
                истек срок действия банковской карты.
                Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Заказчик может узнать, обратившись в банк, выпустивший банковскую карту.
              </div>
              <div className="text">
                6.4 истек срок действия банковской карты.
                Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Заказчик может узнать, обратившись в банк, выпустивший банковскую карту.
              </div>
            </div>
            <div className="text">
              7. Исполнитель не контролирует программно-аппаратный комплекс платежной системы и/или банка. Если в результате каких-либо ошибок произошло списание денежных средств Заказчика, но платеж не был авторизован платежной системой и/или банком, обязанности по возврату денежных средств Заказчику возлагаются на оператора платежной системы и/или соответствующий банк.
            </div>
          </div>
          <div className="content">
            <div className="text">
              8. Возврат уплаченных Заказчиком денежных средств может быть осуществлен в случаях:
            </div>
          </div>
          <div className="content">
            <div className="text">
              8.1. Услуга/товар не оказана по вине Исполнителя в установленный срок;
            </div>
          </div>
          <div className="content">
            <div className="text">
              8.2. Заказчик до начала оказания услуги обратился с уведомлением о расторжении Договора и возврате денежных средств;
              в случаях, указанных в п. 14 настоящего Приложения.
            </div>
            <div className="text">
              9. Полный или частичный возврат уплаченных Заказчиком денежных средств производится Исполнителем на основании письменного заявления Заказчика, направленного на электронную почту Исполнителя с последующим направлением оригинала на почтовый адрес movemates@gmail.com Исполнителя: Кыргызская Республика Бишкек, Токтогула 106
              Исполнитель вправе потребовать от Заказчика заполнения утвержденной формы заявления о возврате денежных средств.
            </div>
          </div>
          <div className="content">
            <div className="text">
              10. В случае если Заказчик отказывается от Договора до предоставления Исполнителем товара/услуги, за исключением внесения оплаты на условиях оплаты с использованием кредитных средств, Исполнитель возвращает Заказчику 100% уплаченной суммы.
            </div>
            <div className="text">
              11. В случае если Заказчик отказывается от Договора до предоставления Исполнителем товара/услуги, оплаченному с использованием кредитных средств, Исполнитель производит возврат денежных средств в адрес соответствующего банка, за вычетом сумм комиссий, удержанных банком с Исполнителя. Исполнитель не несет ответственности за оплату и не возмещает Заказчику сумму начисленных процентов за пользование денежными средствами, подлежащих оплате Заказчиком банку в соответствии с заключенным Заказчиком с банком договором.
            </div>
            <div className="text">
              12. В случае если Заказчик отказывается от Договора в течение 30 (тридцати) календарных дней с даты предоставления Исполнителем товара/услуги, Исполнитель возвращает Заказчику 50% от уплаченной суммы. При этом если товар/услуга оплачен с использованием кредитных средств, Исполнитель производит возврат денежных средств в адрес соответствующего банка, за вычетом сумм комиссий, удержанных банком с Исполнителя. Исполнитель не несет ответственности за оплату и не возмещает Заказчику сумму начисленных процентов за пользование денежными средствами, подлежащих оплате Заказчиком банку в соответствии с заключенным Заказчиком с банком договором.
            </div>
            <div className="text">
              13. В целях однозначного толкования: при отказе Заказчика от услуг, оплаченных с использованием кредитных средств (банковских продуктов – потребительских кредитов, рассрочек и пр.), сумма к возврату и расходы Исполнителя рассчитываются от суммы денежных средств, полученных Исполнителем от банка в счет оплаты услуг, оказываемых Заказчику.
            </div>
            <div className="text">
              14. В случае если Заказчик отказывается от Договора по истечении 30 (тридцати) календарных дней с даты предоставления Исполнителем товара/услуги, возврат денежных средств не осуществляется.
            </div>
            <div className="text">
              15. Возврат денежных средств производится на счет, с которого была совершена оплата, в срок до 30 дней с даты получения Заявления Заказчика (конкретный срок возврата зависит от банка, эмитировавшего банковскую карту Заказчика).
            </div>
            <div className="text">
              16. При изменении платежных реквизитов Заказчика, а равно при оплате услуг банковской организацией с привлечением кредитных средств, Заказчик обязуется в Заявлении указать банковские реквизиты для возврата денежных средств.
            </div>
            <div className="text">
              17. При неуказании банковских реквизитов Заказчиком Исполнитель не несет ответственности за некорректное и/или несвоевременное перечисление и/или неперечисление денежных средств по платежным реквизитам Заказчика, указанным последним при оплате услуг.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Refund;
