import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Button, Divider, Form, notification } from "antd";
import Names from "../../../components/names";
import mixpanel from "../../../helpers/mix-panel/mixpanel";
import { RootStoreContext } from "../../../store/rootStore.ts";
import { BASE_TIME_ZONE } from "../../../helpers/api";
import CoachName from "../../../components/coach-name";
import CalendarProfile from "../../../components/calendar-profile";
import "./style.scss";

const CoachDetails = observer(() => {
  const { clientIndividualAppointmentsStore, coachStore } = useContext(RootStoreContext);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const currentDate = new Date();
  const totalPrice = (coachStore.session?.individualPrice || 0) * (clientIndividualAppointmentsStore.time.length || 0);
  const dateFrom = currentDate.toISOString().split("T")[0]; // Formats the date to YYYY-MM-DD
  const fourWeeksLater = new Date(currentDate.setDate(currentDate.getDate() + 28));
  const dateTo = fourWeeksLater.toISOString().split("T")[0]; // Formats the date to YYYY-MM-DD
  const isButtonDisabled = clientIndividualAppointmentsStore.time.length === 0;

  const filter = {
    coachId: coachStore.id,
    dateFrom,
    dateTo,
    timeZone: BASE_TIME_ZONE,
  };
  useEffect(() => {
    if (coachStore.id) {
      coachStore.fetchCoachSession(filter);
    } else {
      navigate(-1);
    }
  }, [coachStore]);
  const handleNav = () => {
    navigate("/client/specialization/individual");
    clientIndividualAppointmentsStore.setName(coachStore.coach.firstName);
  };
  const handleSubmit = async () => {
    clientIndividualAppointmentsStore.setSpeciality(coachStore.session.specialities);
    clientIndividualAppointmentsStore.setOffline(coachStore.session.availableOffline);
    clientIndividualAppointmentsStore.setLocation(coachStore.session.offlineLocation);
    await clientIndividualAppointmentsStore.fetchIndividualAppointments();
    if (clientIndividualAppointmentsStore.response && !clientIndividualAppointmentsStore.isLoading) {
      navigate(-1);
      localStorage.setItem('notificationPayment', 'true');
      // notification.success({
      //   message: t("notificationOk"),
      //   description: t("notificationOkText"),
      //   placement: "topRight",
      //   duration: 3,
      // });
      const specializationString = coachStore.session?.specialities.join(", ");
      mixpanel.track("Client Booked", {
        "Trainer Name": coachStore.session?.coachFirstName,
        Format: coachStore.session?.availableOffline ? t("offline") : t("onfline"),
        // Type: type,
        Specialization: specializationString,
        // Trainings: trainings,
        // Duration: duration,
        Price: coachStore.session?.individualPrice,
      });
    } else {
      notification.error({
        message: t("error"),
        description: "Произошла ошибка при записи на тренировку.",
        placement: "topRight",
        duration: 3,
      });
    }
  };

  console.log("clientIndividualAppointmentsStore", clientIndividualAppointmentsStore.startOfAppointment);
  return (
    <div className="container_mobile coach_detail back_ground">
      <Names title={t("back")} />

      <div className="info">
        <CoachName name={coachStore.session?.coachFirstName} url={coachStore.session?.coachAvatarUrl} />
      </div>

      <div className="specialities">
        {coachStore.session?.specialities?.map((item) => (
          <div key={item} className="type">
            {item}
          </div>
        ))}
      </div>

      <div className="content">
        <div className="flow">
          <div className="title">{t("experience")}</div>
          <div className="price">
            {coachStore.session?.experience}
          </div>
        </div>
        <Divider />
        <div className="flow">
          <div className="title">{t("price")}</div>
          <div className="title">
            {coachStore.session?.individualPrice}
          </div>
        </div>
        <Divider />
        <div className="flow">
          <div className="title">{t("trainingFormat")}</div>
          <div className="title">
            {coachStore.session?.availableOffline ? t("offline") : t("online") }
          </div>
        </div>
        <Divider />
        {coachStore.session?.availableOffline
        && (
          <>
            <div className="flow">
              <div className="title">{t("address")}</div>
              <div className="title">
                {coachStore.session?.offlineLocation}
              </div>
            </div>
            <Divider />
          </>
        )}
        <div className="area">{coachStore.session?.howCanHelp}</div>
        <div className="flex flex-column gap-10">
          <div className="title">{t("paymentMethod")}</div>
          <div className="area">{coachStore.session?.bankData}</div>
        </div>
        <Divider />
      </div>
      <CalendarProfile />
      <Divider />
      <div>
        {t("total")}
        : 
        {" "}
        {totalPrice}
        {" "}
        {t("som")}
        .
      </div>
      <Button type="primary" onClick={handleSubmit} disabled={isButtonDisabled}>{t("sendRequest")}</Button>
    </div>
  );
});

export default CoachDetails;
