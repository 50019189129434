import React, { useContext } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import ClientList from "../../../components/client-list";
import MentorList from "../../mentor-list";
import { RootStoreContext } from "../../../store/rootStore.ts";

const Clients = observer(() => {
  const { userStore } = useContext(RootStoreContext);
  return (
    <div className="client">
      {userStore.role === "coach" ? (
        <ClientList mode="display" />
      ) : (
        <MentorList name="Специалисты" mode="display" />
      )}
    </div>
  );
});

export default Clients;
