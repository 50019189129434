import React from "react";
import moment from "moment";
import "moment/locale/en-gb"; // Для английского языка
import "moment/locale/ru"; // Для русского языка
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Select } from "antd";

const { Option } = Select;

function Language() {
  const { i18n } = useTranslation();

  const handleChange = (value) => {
    i18n.changeLanguage(value);
    moment.locale(value); // меняем локаль moment
    localStorage.setItem("language", value);
  };
  return (
    <div>
      <Select value={i18n.language} style={{ width: 120 }} onChange={handleChange}>
        <Option value="en">English</Option>
        <Option value="ru">Русский</Option>
      </Select>
    </div>
  );
}

export default Language;
