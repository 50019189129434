import { makeAutoObservable, runInAction } from "mobx";
import { IRootStore } from "./rootStore.ts";
import { makePayment, subscriptionOffers } from "../api";

export class Payment {
  rootStore: IRootStore;
  payment = null;
  paymentUrl = null;
  isLoading: boolean;
  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.isLoading = false;
    makeAutoObservable(this);
  }

  async fetchPayment() {
    const { authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.isLoading = true;
        });
        const response = await subscriptionOffers();
        runInAction(() => {
          this.payment = response;
        });
      }
    } catch (error) {
      console.log("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async fetchUrl(id: number) {
    const { authStore } = this.rootStore;
    try {
      const filter = {
        subscriptionId: id,
      };
      if (authStore?.token) {
        const response = await makePayment(filter);
        runInAction(() => {
          this.paymentUrl = response;
        });
      }
    } catch (error) {
      console.log("Failed to fetch user:", error);
    }
  }
}
