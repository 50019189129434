import React from "react";
import Icon from "../icon";
import "./style.scss";

function Logo(props) {
  const { text } = props;
  return (
    <div className="logo">
      <Icon name="logo" />
      {text && <div className="text">MoveMates</div> }
      {!text && <div className="title">MoveMates</div>}
    </div>
  );
}

export default Logo;
