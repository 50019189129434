import React from "react";
import { Button } from "antd";
import Names from "../../../components/names";

function TermOfUse() {
  return (
    <div className="container_landing pt-20 privacy_policy">
      <Names title="Назад" name="Условия использования" />
      <div className="box">
        <div className="content">
          <div className="title">Условия использования</div>
          <div className="text">
            Скачивая или используя данное приложение, вы автоматически
            соглашаетесь с данными условиями. Поэтому обязательно ознакомьтесь с
            ними внимательно перед использованием приложения. Вам запрещается
            копировать или модифицировать приложение, его части или наши
            товарные знаки любым образом. Вы не можете пытаться извлекать
            исходный код приложения, а также переводить его на другие языки или
            создавать производные версии. Само приложение и все товарные знаки,
            авторские права, права на базы данных и другие права
            интеллектуальной собственности, связанные с ним, по-прежнему
            принадлежат Калмурату Джангазиеву.
          </div>
          <div className="text">
            Калмурат Джангазиев обязуется обеспечивать максимальную полезность и
            эффективность приложения. По этой причине мы оставляем за собой
            право внесения изменений в приложение или взимания платы за его
            услуги в любое время и по любой причине. Мы никогда не будем взимать
            плату за приложение или его услуги, не ясно и подробно объяснив вам,
            за что именно вы платите.
          </div>
          <div className="text">
            Приложение Move Mates хранит и обрабатывает персональные данные,
            которые вы предоставили нам, для предоставления наших услуг. Вашей
            ответственностью является обеспечение безопасности вашего телефона и
            доступа к приложению. Поэтому мы рекомендуем вам не осуществлять
            взлом (jailbreak) или рутирование (root) вашего телефона, что
            представляет собой процесс удаления ограничений и ограничений,
            установленных официальной операционной системой вашего устройства.
            Это может сделать ваш телефон уязвимым для вредоносных программ,
            вирусов и злонамеренных программ, может нарушить функции
            безопасности вашего телефона и может означать, что приложение Move
            Mates не будет работать должным образом или вообще не будет
            работать.
          </div>

          <div className="text">
            Приложение Move Mates хранит и обрабатывает персональные данные,
            которые вы предоставили нам, для предоставления наших услуг. Вашей
            ответственностью является обеспечение безопасности вашего телефона и
            доступа к приложению. Поэтому мы рекомендуем вам не осуществлять
            взлом (jailbreak) или рутирование (root) вашего телефона, что
            представляет собой процесс удаления ограничений и ограничений,
            установленных официальной операционной системой вашего устройства.
            Это может сделать ваш телефон уязвимым для вредоносных программ,
            вирусов и злонамеренных программ, может нарушить функции
            безопасности вашего телефона и может означать, что приложение Move
            Mates не будет работать должным образом или вообще не будет
            работать.
          </div>

          <div className="text">
            Приложение Move Mates хранит и обрабатывает персональные данные,
            которые вы предоставили нам, для предоставления наших услуг. Вашей
            ответственностью является обеспечение безопасности вашего телефона и
            доступа к приложению. Поэтому мы рекомендуем вам не осуществлять
            взлом (jailbreak) или рутирование (root) вашего телефона, что
            представляет собой процесс удаления ограничений и ограничений,
            установленных официальной операционной системой вашего устройства.
            Это может сделать ваш телефон уязвимым для вредоносных программ,
            вирусов и злонамеренных программ, может нарушить функции
            безопасности вашего телефона и может означать, что приложение Move
            Mates не будет работать должным образом или вообще не будет
            работать.
          </div>
          <div className="text">
            -
            {" "}
            <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer" className="text">Google Play Services</a>
          </div>
          <div className="text">
            -
            {" "}
            <a href="https://mixpanel.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer" className="text">Mixpanel</a>
          </div>
          <div className="text">
            Приложение Move Mates хранит и обрабатывает персональные данные,
            которые вы предоставили нам, для предоставления наших услуг. Вашей
            ответственностью является обеспечение безопасности вашего телефона и
            доступа к приложению. Поэтому мы рекомендуем вам не осуществлять
            взлом (jailbreak) или рутирование (root) вашего телефона, что
            представляет собой процесс удаления ограничений и ограничений,
            установленных официальной операционной системой вашего устройства.
            Это может сделать ваш телефон уязвимым для вредоносных программ,
            вирусов и злонамеренных программ, может нарушить функции
            безопасности вашего телефона и может означать, что приложение Move
            Mates не будет работать должным образом или вообще не будет
            работать.
          </div>
          <div className="text">
            Если вы используете приложение за пределами зоны Wi-Fi, помните, что
            условия соглашения с вашим мобильным оператором по-прежнему будут
            применяться. В результате ваш мобильный оператор может взимать плату
            за передачу данных при доступе к приложению или за другие сторонние
            услуги. Используя приложение, вы несете ответственность за все такие
            платежи, включая плату за роуминг в случае использования приложения
            за пределами вашей территории (региона или страны) без отключения
            роуминга. Если вы не являетесь плательщиком счета за устройство, на
            котором используется приложение, имейте в виду, что мы предполагаем,
            что вы получили разрешение от плательщика счета для использования
            приложения.
          </div>
          <div className="text">
            В этом же духе Калмурат Джангазиев не всегда может нести
            ответственность за способ использования вами приложения, то есть вам
            нужно обеспечить постоянное зарядное устройство для вашего
            устройства. Если устройство разряжается и вы не можете включить его
            для использования Сервиса, Калмурат Джангазиев не может принять на
            себя ответственность.
          </div>
          <div className="text">
            Что касается ответственности Калмурата Джангазиева за ваше
            использование приложения, при использовании приложения важно
            помнить, что, хотя мы стараемся обеспечить его постоянное обновление
            и правильность, мы полагаемся на сторонних поставщиков информации,
            чтобы сделать ее доступной вам. Калмурат Джангазиев не несет
            ответственности за любые потери, как непосредственные, так и
            косвенные, которые вы можете испытать вследствие полного полагания
            на эту функциональность приложения.
          </div>
          <div className="text">
            В некоторый момент мы можем захотеть обновить приложение. Приложение
            в настоящее время доступно на Android и iOS. Требования для обе
          </div>
          <div className="text">
            их систем (и для любых других систем, для которых мы решим расширить
            доступность приложения) могут измениться, и вам потребуется
            загрузить обновления, если вы хотите продолжать использовать
            приложение. Калмурат Джангазиев не обещает всегда обновлять
            приложение так, чтобы оно было актуальным для вас и/или работало с
            версией Android и iOS, установленной на вашем устройстве. Однако вы
            обещаете всегда принимать обновления приложения, когда они вам
            предлагаются. Мы также можем захотеть прекратить предоставление
            приложения и в любое время прекратить его использование, не
            предоставляя вам уведомления о прекращении. Если мы не сообщим вам
            обратное, то при прекращении (а) права и лицензии, предоставленные
            вам в этих условиях, завершатся; (б) вы должны прекратить
            использование приложения и, при необходимости, удалить его с вашего
            устройства.
          </div>
        </div>
        <div className="content">
          <div className="title">
            Изменения в настоящих Условиях использования
          </div>
          <div className="text">
            Я могу время от времени обновлять наши Условия использования.
            Поэтому вам рекомендуется периодически проверять эту страницу на
            предмет изменений. Я уведомлю вас об изменениях, разместив новые
            Условия использования на этой странице.
          </div>
          <div className="text">
            Данные условия использования действуют с 6 октября 2023 года.
          </div>
        </div>
      </div>
      <a href="/pdf/terms_conditions_rus.pdf" download="Privacy_Policy.pdf">
        <Button className="button_done">Скачать Условия использования</Button>
      </a>
    </div>
  );
}

export default TermOfUse;
