import React from "react";
import Names from "../../../components/names";
import PaymentSuccess from "../../../components/payment-success";

function PaymentDetails() {
  return (
    <div className="payment_details back_ground container_mobile">
      <Names title="Назад" name="Детали транзакции" />
      <PaymentSuccess />
    </div>
  );
}

export default PaymentDetails;
