import React from "react";

function Iska() {
  // TASK 1
  const a = [
    ["Помидоры", "Аня", "Саша"],
    ["Огурцы", "Саша", "Толя"],
    ["Рис", "Толя"],
  ];
  const result = a.reduce((acc, item) => {
    item.splice(1).forEach((con) => {
      if (!acc[con]) {
        acc[con] = [];
      }
      acc[con].push(item[0]);
    });
    return acc;
  }, {});
  // console.log("финальный результат ", result);

  // TASK 2
  const b = [0, 3, 0, 4, 1, 2, 3];
  const res = [];
  const resultB = b.reduce((acc, item, index) => {
    if (item === 0) {
      res.push(item);
      return acc;
    }
    return [...acc, item];
  }, []);
  const finale = resultB.concat(res);
  // console.log("finale", finale);
  
  // TASK 3
  
  const resultC = b.reduce((acc, item) => {
    if (!acc[item]) {
      acc[item] = [];
    }
    acc[item].push(item);
    return acc;
  }, {});
  
  const final = Object.keys(result).reduce((acc, item) => {
    if (result[item].length > 1) {
      acc.push(item); // Добавляем число в аккумулятор, если оно повторяется
    }
    return acc;
  }, []).map(Number); // Преобразуем ключи обратно в числа

  const d = [1, 2, 3];
  const f = [1, 2, 3];
  const resultD = d.reduce((acc, item) => [...acc, item], []);
  const resultF = resultD.concat(d);
  console.log("result", resultF);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      iska
    </div>
  );
}

export default Iska;
