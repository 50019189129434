import { makeAutoObservable, runInAction } from "mobx";
import { coachesLedger } from "../api";
import { RootStore } from "./rootStore.ts";

export class LedgerStore {
  rootStore: RootStore;
  ledger: any[];
  usersLoading: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.ledger = [];
    this.usersLoading = false;

    makeAutoObservable(this);
  }
  async fetchLedger(page = 0) {
    const { userStore, authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.usersLoading = true;
        });

        const filter = {
          size: 20,
          page,
        };
        let lowercaseRole = userStore.role?.toLowerCase();
        if (lowercaseRole === "coach") {
          lowercaseRole += "es";
        }
        if (lowercaseRole === "client") {
          lowercaseRole += "s";
        }
        const response = await coachesLedger(filter);
        const existingIds = new Set(this.ledger?.map((chat: any) => chat.id));
        const uniqueNewCoaches = response?.content?.filter(
          (coach: any) => !existingIds.has(coach.id),
        );
        runInAction(() => {
          this.ledger = [...this.ledger, ...uniqueNewCoaches];
        });

        if (response?.content.length > 18 && page === 0) {
          await this.fetchLedger(page + 1);
        }
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.usersLoading = false;
      });
    }
  }
}
