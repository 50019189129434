import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";

function NotificationRequestModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Notification.permission", Notification.permission);
    if (Notification.permission === "default" || Notification.permission === "denied") {
      setIsModalVisible(true);
    }
  }, []);

  const requestNotificationPermission = () => {
    Notification.requestPermission().then((permission) => {
      console.log("permission", permission);
      if (permission === "granted") {
        console.log("Разрешение на уведомления получено!");
      }
      setIsModalVisible(false);
    });
  };

  return (
    <Modal
      title={t("notificationTitle")}
      open={isModalVisible}
      onOk={requestNotificationPermission}
      onCancel={() => setIsModalVisible(false)}
      footer={Notification.permission === 'default' && (
        <Button key="cancel" onClick={() => setIsModalVisible(false)}>
          {t("back")}
        </Button>,
          <Button key="request" type="primary" onClick={requestNotificationPermission}>
            {t("allowNotifications")}
          </Button>)}
    >
      {Notification.permission === 'default' && (
        <p>{t("notificationDefault")}</p>
      )}
      {Notification.permission === 'denied' && (
        <p>{t("notificationDenied")}</p>
      )}
    </Modal>
  );
}

export default NotificationRequestModal;
