import React, { useEffect, useRef } from "react";
import "./style.scss";

function LandingScroll({ img }) {
  const scrollRef = useRef(null);

  const setupHorizontalScroll = () => {
    const element = scrollRef.current;
    const scrollStep = 1; // Регулировка скорости скролла
    let currentPosition = 0;

    const scroll = () => {
      currentPosition += scrollStep;
      element.scrollLeft = currentPosition;

      // Перезапуск скролла, если достигнут конец
      if (element.scrollWidth <= element.clientWidth + currentPosition) {
        currentPosition = 0;
      }
    };

    const intervalId = setInterval(scroll, 25);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    const stopHorizontalScroll = setupHorizontalScroll();

    return () => {
      stopHorizontalScroll();
    };
  }, []);

  return (
    <div className="scroll_loop_mobile" ref={scrollRef}>
      {img?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img src={item.img} alt={`Image ${index}`} className="scroll-image" />
        </div>
      ))}
    </div>
  );
}

export default LandingScroll;
