import { makeAutoObservable, runInAction } from "mobx";
import { getSpecialization } from "../api";
import { RootStore } from "./rootStore.ts";

export class SpecializationsStore {
  rootStore: RootStore;
  specializations: string[];
  usersLoading: boolean;
  current: string[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.specializations = [];
    this.usersLoading = false;
    this.current = [];

    makeAutoObservable(this);
  }

  setCurrent(newSpecializations: string[]) {
    this.current = [...newSpecializations];
  }

  async fetchSpecializations() {
    const { authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.usersLoading = true;
        });
        const response = await getSpecialization();
        runInAction(() => {
          if (response) {
            this.specializations = response;
          }
        });
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.usersLoading = false;
      });
    }
  }
}
