import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input, Spin } from "antd";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import onMessageListener from "../../helpers/sdk/sdk";
import { RootStoreContext } from "../../store/rootStore.ts";
import Icon from "../../components/icon";
import Names from "../../components/names";
import AvatarAny from "../../components/avatar-any";
import "./style.scss";

const Chat = observer(() => {
  const { userStore, chatStore, chatDetailsStore } = useContext(RootStoreContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    chatStore.fetchChat();
  }, [chatStore]);

  onMessageListener()
    .then((payload) => {
      if (payload) {
        console.log("eto sms ili tranings?", payload);
        chatStore.addNewMessage(payload);
        const parsedData = JSON.parse(chatStore.newMessages.data.body);
        chatStore.setName(parsedData);
      }
    })
    .catch((err) => console.log("failed: ", err));
  const handleNav = (id, name, client, img) => {
    navigate(`/chat/${id}`);
    chatStore.clearUnreadChat(client);
    chatDetailsStore.setName(name);
    chatDetailsStore.setClient(client);
    chatStore.setId(id);
    chatDetailsStore.setImg(img);
  };
  console.log("chatStore.name", chatStore.name);
  console.log("chatStore?.chat", chatStore?.chat);
  return (
    <div className="chat ">
      {/* <div className="web"> */}
      {/*  <Hamburger /> */}
      {/* </div> */}
      <div className="web_content container_mobile back_ground">
        <Names name={t("chat")} title={t("back")} />
        <Input
          prefix={<Icon name="search" />}
          placeholder={t("search")}
        />
        <div className="flex flex-column gap-20">
          
          {chatStore.usersLoading ? <Spin />   
            : chatStore.chat.length ? (
              chatStore?.chat?.map((item) => {
                const avatarUrl = userStore.role === "client" ? item.coach.avatarUrl : item.client.avatarUrl;
                const firstName = userStore.role === "coach" ? item.client.firstName : item.coach.firstName;
                const hasUnreadMessages = chatStore.unreadChats.has(item.name);
                return (
                
                  <div
                    className="flex alignC justify-s content"
                    onClick={() => handleNav(item.id, firstName, item.name, avatarUrl)}
                    key={item.name}
                  >
                    {hasUnreadMessages && <div className="green-dot" />}
                    <div className="flex alignC gap-10">
                      {userStore.role === "client" ? (
                        <>
                          <AvatarAny url={item.coach.avatarUrl} />
                          <div>
                            <div className="title">
                              {item?.coach?.firstName}
                            </div>
                            <div className="text">{item?.coach?.lastName}</div>
                          </div>
                        </>
                      ) : (
                        <>
                          <AvatarAny url={item.client.avatarUrl} />
                          <div>
                            <div className="title">
                              {item?.client?.firstName}
                            </div>
                            <div className="text">{item?.client?.lastName}</div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ); 
              })
            ) : (
              <div className="flex alignC justify-c title">{t("emptyChat")}</div>
            )}
        </div>
      </div>
    </div>
  );
});

export default Chat;
