import React, { useEffect, useState } from "react";
import { Button, Divider, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "../icon";
import "./style.scss";

function ModalPaymentWait() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const notificationPayment = localStorage.getItem("notificationPayment");
    if (notificationPayment === "true") {
      setIsModalVisible(true);
      localStorage.removeItem("notificationPayment");
    }
  }, []);

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleNav = () => {
    navigate("/request/client");
  };

  return (
    <Modal
      open={isModalVisible}
      onCancel={handleClose}
      footer={null}
      className="modal_payment_wait"
    >
      <div className="content">
        <Icon name="wait_payment" />
        <div className="title">{t("modalPaymentWait")}</div>
        <div className="text">{t("notificationOkText")}</div>
        <Divider />
        <Button className="button" type="primary" onClick={handleNav}>{t("mySessions")}</Button>
      </div>
    </Modal>
  );
}

export default ModalPaymentWait;
