import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useParams } from "react-router-dom";
import moment from "moment";
import { observer } from "mobx-react";
import Icon from "../../../components/icon";
import CardUserInfo from "../../../components/card-user-info";

import { getClient } from "../../../api";
import Names from "../../../components/names";
import Avatar from "../../../components/avatar";
import Tab from "../../../components/tab";
import { RootStoreContext } from "../../../store/rootStore.ts";

const ClientDetails = observer(() => {
  const { id } = useParams();
  const { lastAppointmentsAtore } = useContext(RootStoreContext);
  const [clientData, setClientData] = useState(null);
  const currentDate = moment();
  useEffect(() => {
    const fetchClientData = async () => {
      if (id) {
        const response = await getClient(id);
        setClientData(response);
      }
    };

    fetchClientData();
    lastAppointmentsAtore.setId(id);
    lastAppointmentsAtore.fetchAppointments();
  }, [id, lastAppointmentsAtore]);

  return (
    <div className="client_details container_mobile back_ground">
      <div>
        <Names title="Клиенты" name="Профиль клиента" />
        <div className="flex alignC justify-c flex-column gap-5">
          {clientData?.avatarUrl ? (
            <img
              src={clientData?.avatarUrl}
              alt="no img"
              className="img"
            />
          ) : (
            <Avatar />
          )}
          <div className="name">
            {clientData?.name ? clientData?.name : "Имя клиента"}
          </div>
          <div className="title">Клиент</div>
        </div>
      </div>

      <CardUserInfo
        weight={clientData?.weight}
        height={clientData?.height}
        gender={clientData?.gender}
        dateOfBirth={currentDate.diff(clientData?.dateOfBirth, "years")}
      />

      <div className="info">
        <div className="title">Обо мне</div>
        <div className="text">{clientData?.aboutMeForCoach}</div>
      </div>

      <Tab
        name="Предстоящие"
        subName="Прошедшие"
        content={(
          <div className="content">
            {clientData?.upcommingAppointments?.map((appointment) => (
              <div
                key={appointment?.startOfAppointment}
                className="card_clien_content card"
              >
                <div className="flex gap-10 alignC">
                  <Icon name="calendar" />
                  {" "}
                  <div className="text">{appointment.type}</div>
                </div>
                <div className="title">
                  {moment(appointment?.startOfAppointment).format(
                    "dd, D MMMM | HH:mm",
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        subContent={(
          <div className="content">
            {lastAppointmentsAtore?.appointments?.map((item) => (
              <div
                key={item?.startOfAppointment}
                className="card_clien_content card"
              >
                <div className="flex gap-10 alignC">
                  <Icon name="check" />
                  {" "}
                  <div className="text">{item.type}</div>
                </div>
                <div className="title">
                  {moment(item?.startOfAppointment).format(
                    "dd, D MMMM | HH:mm",
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      />
    </div>
  );
});

export default ClientDetails;
