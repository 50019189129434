import { makeAutoObservable, runInAction } from "mobx";
import { createIndividualAppointment, getIndividualAppointment } from "../api";
import { Rule } from "./clientIndividualAppointmentsStore.ts";
import { RootStore } from "./rootStore.ts";

export class IndividualAppointmentsStore {
  rootStore: RootStore;
  speciality: string | null;
  isOffline: boolean;
  locationName: string | null;
  locationAddress: string | null;
  error: string | null = null;
  workingSchedule: any;
  settings: any;
  response: any;
  isLoading: boolean;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.speciality = null;
    this.isOffline = false;
    this.locationName = null;
    this.locationAddress = null;
    this.isLoading = true;
    this.response = null;
    this.workingSchedule = null;
    this.settings = null;

    makeAutoObservable(this);
  }

  setError(error: string) {
    runInAction(() => {
      this.error = error;
    });
  }
  setSpeciality(speciality: string) {
    runInAction(() => {
      this.speciality = speciality;
    });
  }

  setWorkingSchedule(workingSchedule: any) {
    runInAction(() => {
      this.workingSchedule = workingSchedule;
    });
  }

  setOffline(offline: boolean) {
    runInAction(() => {
      this.isOffline = offline;
    });
  }

  async fetchIndividualAppointments(data: any) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const filter = {
        availableOffline: this.isOffline,
        specialities: this.speciality,
      };
      const combinedFilter = {
        ...filter,
        ...data,
        workingSchedule: { worktimeRequests: this.workingSchedule },
      };
      const response = await createIndividualAppointment(combinedFilter);
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      console.error("Failed to fetch user:", error);
      console.log("Error details: ", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async getIndividualAppointments() {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const response = await getIndividualAppointment();
      runInAction(() => {
        this.settings = response;
      });
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
