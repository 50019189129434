import React, { useContext, useEffect } from "react";
import { Button, Divider, message, Spin } from "antd";
import "./style.scss";
import { observer } from "mobx-react";
import Icon from "../icon";
import { RootStoreContext } from "../../store/rootStore.ts";

const CardSubscription = observer((props) => {
  const { nav } = props;
  const { payment, userStore } = useContext(RootStoreContext);

  useEffect(() => {
    payment.fetchPayment();
  }, []);
  const handleNav = (id) => {
    try {
      if (id) {
        payment.fetchUrl(id).then(() => {
          if (payment.paymentUrl && payment.paymentUrl.pg_redirect_url && !payment.isLoading) {
            window.open(payment.paymentUrl.pg_redirect_url, "_blank");
          } else {
            message.error("Что-то пошло не так, ссылка для перенаправления отсутствует.");
          }
        });
      }
      if (nav && userStore.isCoach) {
        nav();
      }
    } catch (error) {
      console.error("Ошибка при навигации", error);
      message.error("Что-то пошло не так, попробуйте еще раз.");
    }
  };

  return (
    <div className="card_subscription">
      {payment.payment?.map((item) => (
        <div key={item.id} className="card_subscription_content">
          <div className="info">
            <div className="title">
              Премиум аккаунт
              {" "}
              {item.monthPeriod}
              {" "}
              месяцев
            </div>
            {/* <div className="price"> */}
            {/*  <span className="new-price"> */}
            {/*    {item.monthlyPriceUsd} */}
            {/*    {" "} */}
            {/*    $ */}
            {/*    {" "} */}
            {/*    {item.monthlyPriceUsd * item.monthPeriod !== item.monthlyPriceUsd && "/месяц всего"} */}
            {/*    {" "} */}
            {/*    {item.monthlyPriceUsd * item.monthPeriod !== item.monthlyPriceUsd && item.monthlyPriceUsd * item.monthPeriod} */}
            {/*    {" "} */}
            {/*    {item.monthlyPriceUsd * item.monthPeriod !== item.monthlyPriceUsd && "$"} */}
            {/*  </span> */}
            {/*  <span className="old-price"> */}
            {/*    {item.oldPrice} */}
            {/*    {" "} */}
            {/*    $ */}
            {/*  </span> */}
            {/* </div> */}
            <div className="price">
              {Math.round(item.monthlyPriceKgs)}
              {" "}
              c
              {" "}
              {item.monthlyPriceUsd * item.monthPeriod !== item.monthlyPriceUsd && "/месяц всего"}
              {" "}
              {item.monthlyPriceKgs * item.monthPeriod !== item.monthlyPriceKgs && Math.round(item.monthlyPriceKgs) * item.monthPeriod}
              {" "}
              {item.monthlyPriceUsd * item.monthPeriod !== Math.round(item.monthlyPriceKgs) && ""}
            </div>
          </div>
          <Divider />
          <div className="content">
            <div className="item">
              <Icon name="done" />
              Новые клиенты. Вы будете видны клиентам из Кыргызстана, ОАЭ и США
            </div>
            <div className="item">
              <Icon name="done" />
              Выставляйте свои рабочие часы и планируйте все свои тренировки с
              клиентами в удобном календаре
            </div>
            <div className="item">
              <Icon name="done" />
              Получайте уведомления о предстоящих тренировках
            </div>
            <div className="item">
              <Icon name="done" />
              Общайтесь с клиентами в чате
            </div>
            <div className="item">
              <Icon name="done" />
              Совершайте видео-тренировки не выходя из платформы
            </div>
          </div>
          {!userStore.user?.isSubscribed
          && (
          <Button onClick={() => handleNav(item.id)}> 
            {" "}
            {payment.isLoading ? <Spin /> : "Купить"}
            {" "}
          </Button>
          )}
        </div>
      ))}
    </div>
  );
});

export default CardSubscription;
