import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { isSafari } from "react-device-detect";

// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyD75mrmaFCR4IqP4YRyNQr2YS44e82noEY",

  authDomain: "move-mates.firebaseapp.com",

  projectId: "move-mates",

  storageBucket: "move-mates.appspot.com",

  messagingSenderId: "927098525254",

  appId: "1:927098525254:web:7694a0170837a20fc99c47",
};

const app = initializeApp(firebaseConfig);
export const messaging = !isSafari && getMessaging(app);

export default () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});
