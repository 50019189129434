import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppDownloadModal from "../../../components/modal-deep-link";
import "./style.scss";
import Icon from "../../../components/icon";
import Language from "../../../components/language";
import { RootStoreContext } from "../../../store/rootStore.ts";

function WelcomePage() {
  const { t } = useTranslation();
  const { userStore, chatRegisterStore, authStore } = useContext(RootStoreContext);
  const handleClient = () => {
    userStore.setRole("client");
  };

  const handleCoach = () => {
    userStore.setRole("coach");
  };
  return (
    <div className="welcome container_mobile back_ground">
      <Language />
      <div className="name">{t("select_role")}</div>
      <NavLink to="/login/client" className="nav" onClick={handleClient}>
        <div className="content">
          <div className="title">{t("client")}</div>
          <div className="text">{t("client_description")}</div>
        </div>
        <Icon name="coachDashboard" className="img_dashboard" />
      </NavLink>
      <NavLink to="/login/coach" className="nav" onClick={handleCoach}>
        <div className="content">
          <div className="title">{t("coach")}</div>
          <div className="text">{t("coach_description")}</div>
        </div>
        <Icon name="clientDashboard" className="img_dashboard" />
      </NavLink>
      <AppDownloadModal />
    </div>
  );
}

export default WelcomePage;
