import React, { useContext } from "react";
import moment from "moment";
import { observer } from "mobx-react";
import Icon from "../icon";
import "./style.scss";
import { RootStoreContext } from "../../store/rootStore.ts";

const ListInfoTrain = observer(() => {
  const { userStore, appointmentDetailsStore } = useContext(RootStoreContext);
  const formattedDate = moment(
    appointmentDetailsStore.appointments.startOfAppointment,
  ).format("D MMM, YYYY");
  const formattedStartTime = moment(
    appointmentDetailsStore.appointments.startOfAppointment,
  ).format("HH:mm");
  const formattedEndTime = moment(
    appointmentDetailsStore.appointments.endOfAppointment,
  ).format("HH:mm");
  const formattedTimeRange = `${formattedStartTime}-${formattedEndTime}`;

  return (
    <div className="flex flex-column gap-20 list_info_train">
      <div className="flex alignC gap-5">
        <Icon name="wallet" />
        <div className="flex flex-column">
          <div className="title">Специализация</div>
          <div className="text">{appointmentDetailsStore.appointments.type}</div>
        </div>
      </div>

      <div className="flex alignC gap-5">
        <Icon name="wallet" />
        <div className="flex flex-column">
          <div className="title">Локация</div>
          <div className="text">
            {appointmentDetailsStore.appointments.locationAddress
              ? appointmentDetailsStore.appointments.locationAddress
              : "Онлайн"}
          </div>
        </div>
      </div>

      <div className="flex alignC gap-5">
        <Icon name="wallet" />
        <div className="flex flex-column">
          <div className="title">Appointment date</div>
          <div className="text">
            {formattedDate}
            {" "}
            года
          </div>
        </div>
      </div>

      <div className="flex alignC gap-5">
        <Icon name="wallet" />
        <div className="flex flex-column">
          <div className="title">Время</div>
          <div className="text">{formattedTimeRange}</div>
        </div>
      </div>

      <div className="flex alignC gap-5">
        <Icon name="wallet" />
        <div className="flex flex-column">
          <div className="title">Общая цена</div>
          <div className="text">{userStore.user.individualPrice}</div>
        </div>
      </div>
    </div>
  );
});

export default ListInfoTrain;
