import React from "react";
import "./style.scss";

function ButtonEmpty(props) {
  const { text, size, onClick, className } = props;
  return (
    <div className={`button_empty ${className} ${size}`} onClick={onClick}>
      {text}
    </div>
  );
}

export default ButtonEmpty;
