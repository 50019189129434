import { useContext, useEffect, useState } from "react";
import "./style.scss";
import { observer } from "mobx-react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Names from "../../../components/names";
import Avatar from "../../../components/avatar";
import Reschedule from "../../../components/reschedule";
import ListInfoTrain from "../../../components/list-info-train";
import ContactClient from "../../../components/contact-client";
import ModalCancel from "../../../components/modal-cancel";
import { RootStoreContext } from "../../../store/rootStore.ts";

const AppointmentsBegin = observer(() => {
  const {
    clientIndividualAppointmentsStore,
    chatStore,
    userStore,
    specializationsStore,
    appointmentDetailsStore,
    chatDetailsStore,
  } = useContext(RootStoreContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleCall = () => {
    navigate("/video-call-old");
  };

  useEffect(() => {
    appointmentDetailsStore.fetchAppointments();
    specializationsStore.fetchSpecializations();
    chatStore.fetchChat();
    if (appointmentDetailsStore.appointments.coachId) {
      setTimeout(() => {
        clientIndividualAppointmentsStore.setId(appointmentDetailsStore.appointments.coachId);
      }, 500);
    }
    if (userStore.role === "coach" && chatStore.chat.length) {
      setTimeout(() => {
        const chatNew = chatStore.chat?.find((item) => item.client.id === appointmentDetailsStore.appointments.clientId);
        chatDetailsStore.setName(appointmentDetailsStore.appointments.clientName);
        chatDetailsStore.setClient(chatNew.name);
      }, 500);
    } else {
      setTimeout(() => {
        const chatNew = chatStore.chat?.find((item) => item.coach.id === appointmentDetailsStore.appointments.coachId);
        chatDetailsStore.setName(appointmentDetailsStore.appointments.coachFirstName);
        chatDetailsStore.setClient(chatNew?.name);
      }, 500);
    }
  }, [clientIndividualAppointmentsStore, chatStore, userStore, appointmentDetailsStore, specializationsStore, chatDetailsStore]);
  const handleChange = (value) => {
    if (value === "cancel") {
      setShow(true);
    } else if (value === "reschedule") {
      navigate("/appointments-begin/reschedule");
    }
  };

  const shouldDisableButton = () => {
    const trainingDate = moment(appointmentDetailsStore.appointments.startOfAppointment).startOf("day");
    const currentDate = moment().startOf("day");

    return !trainingDate.isSame(currentDate);
  };
  const handleChat = () => {
    if (userStore === "coach") {
      navigate(`/chat/${appointmentDetailsStore.appointments.clientId}`);
    } else {
      navigate(`/chat/${appointmentDetailsStore.appointments.coachId}`);
    }
  };

  const isTrainingPast = () => {
    const trainingDate = moment(appointmentDetailsStore.appointments.startOfAppointment);
    const currentDate = moment();

    return trainingDate.isBefore(currentDate);
  };
  return (
    <div className="container_mobile appointments_begin secondary pt-20 back_ground">
      <div className="appointments_begin_content">
        <Names title="Главная" name="О тренировке" />

        <div className="card">
          <Reschedule onChange={handleChange} isDisabled={isTrainingPast()} />

          {appointmentDetailsStore.appointments.coachAvatarUrl ? (
            <img
              src={appointmentDetailsStore.appointments.coachAvatarUrl}
              alt="no img"
              className="img"
            />
          ) : (
            <Avatar />
          )}

          <div className="flex flex-column">
            <div className="title">
              {appointmentDetailsStore.appointments.clientName ? (
                appointmentDetailsStore.appointments.clientName
              ) : (
                <>
                  {appointmentDetailsStore.appointments.coachFirstName}
                  {" "}
                  {appointmentDetailsStore.appointments.coachLastName}
                </>
              )}
            </div>
            <div className="flex alignCc gap-5">
              {appointmentDetailsStore.appointments.clientGender ? (
                <>
                  {" "}
                  <div className="text">
                    {appointmentDetailsStore.appointments.clientGender}
                    {" "}
                    -
                  </div>
                  <div className="text">
                    {appointmentDetailsStore.appointments.clientBirthDate}
                    {" "}
                    -
                  </div>
                  <div className="text">
                    {appointmentDetailsStore.appointments.clientHeight}
                    см -
                  </div>
                  <div className="text">
                    {appointmentDetailsStore.appointments.clientWeight}
                    кг
                  </div>
                </>
              ) : (
                <div className="text">Тренер</div>
              )}
            </div>
          </div>
        </div>

        <ListInfoTrain />

        <ContactClient onClick={handleChat} />
      </div>

      {show && <ModalCancel show={show} />}

      <Button onClick={handleCall} className="button_done button_color" disabled={shouldDisableButton()}>
        Начать тренировку
      </Button>
    </div>
  );
});

export default AppointmentsBegin;
