import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "../icon";
import { RootStoreContext } from "../../store/rootStore.ts";
import "./style.scss";

function Footer() {
  const { userStore } = useContext(RootStoreContext);
  const location = useLocation();
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location]);

  return (
    <div className="footer container_mobile">
      <NavLink to="/main">
        <div className="footer_nav" onClick={() => setActivePage("/main")}>
          <Icon
            name={activePage === "/main" ? "home_active" : "home"}
            className="footer_button"
          />
          <div className={activePage === "/main" ? "text_active" : "text"}>
            {t("main")}
          </div>
        </div>
      </NavLink>
      {userStore.role === "client"
      && (
      <NavLink to="/client">
        <div className="footer_nav" onClick={() => setActivePage("/client")}>
          <Icon
            name={
              activePage === "/client" ? "search_active" : "search_footer"
            }
            className="footer_button"
          />
          <div className={activePage === "/client" ? "text_active" : "text"}>
            {userStore.role === "coach" ? "Клиенты" : t("find")}
          </div>
        </div>
      </NavLink>
      )}
      {/* <NavLink to="/calendar"> */}
      {/*  <div */}
      {/*    className="footer_nav" */}
      {/*    onClick={() => setActivePage("/calendar")} */}
      {/*  > */}
      {/*    <Icon */}
      {/*      name={activePage === "/calendar" ? "big_calendar_active" : "big_calendar"} */}
      {/*      className="footer_button" */}
      {/*    /> */}
      {/*    <div className={activePage === "/calendar" ? "text_active " : "text"}> */}
      {/*      Календарь */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </NavLink> */}
      <NavLink to="/settings">
        <div className="footer_nav" onClick={() => setActivePage("/chat")}>
          <Icon
            name={activePage === "/settings" ? "profile_active" : "profile"}
            className="footer_button"
          />
          <div className={activePage === "/settings" ? "text_active " : "text"}>
            {t("profile")}
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default Footer;
