import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import "./style.scss";
import Avatar from "../avatar";
import { RootStoreContext } from "../../store/rootStore.ts";
import Icon from "../icon";

const Transactions = observer(() => {
  const { ledger } = useContext(RootStoreContext);
  const [todayTransactions, setTodayTransactions] = useState([]);
  const [yesterdayTransactions, setYesterdayTransactions] = useState([]);
  const [previousTransactions, setPreviousTransactions] = useState([]);
  const { t } = useTranslation();
  const formatTime = (isoDateString) => {
    const pastDate = new Date(isoDateString);
    const diffInSeconds = Math.floor((new Date() - pastDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} секунд назад`;
    }
    if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} минут назад`;
    }
    if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} часов назад`;
    }
    return `${Math.floor(diffInSeconds / 86400)} дней назад`;
  };

  useEffect(() => {
    ledger.fetchLedger();
  }, [ledger]);

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    setTodayTransactions(
      ledger.ledger.filter(
        (transaction) => new Date(transaction.paymentDate).toDateString()
          === today.toDateString(),
      ),
    );
    setYesterdayTransactions(
      ledger.ledger.filter(
        (transaction) => new Date(transaction.paymentDate).toDateString()
          === yesterday.toDateString(),
      ),
    );
    setPreviousTransactions(
      ledger.ledger.filter(
        (transaction) => new Date(transaction.paymentDate) < yesterday
          && new Date(transaction.paymentDate).toDateString()
            !== today.toDateString(),
      ),
    );
  }, [ledger.ledger]);

  return (
    <div className="transaction">
      {!!todayTransactions.length && (
        <>
          <div className="flex alignC justify-s">
            <div className="name">Сегодня</div>
            <div>
              <Icon name="calendar" />
            </div>
          </div>
          {todayTransactions.map((item) => (
            <NavLink to={item.link} key={item.id}>
              <div className="card_transaction">
                <div className="flex gap-10">
                  <Avatar />
                  <div className="flex flex-column gap-5 justify-c">
                    <div className="title">{item.clientFirstName}</div>
                    <div className="text">{formatTime(item.paymentDate)}</div>
                  </div>
                </div>
                <div className={`text ${
                  item.type === "CHARGE" ? "text_red" : "text_green"
                }`}
                >
                  {item.type === "CHARGE"
                    ? `- ${item.amount}`
                    : `+ ${item.amount}`}
                  {" "}
                  {t("som")}
                  .
                </div>
              </div>
            </NavLink>
          ))}
        </>
      )}

      {!!yesterdayTransactions.length && (
        <>
          <div className="name">Вчера</div>
          {yesterdayTransactions.map((item) => (
            <div className="card_transaction" key={item.id}>
              <div className="flex gap-10">
                <Avatar />
                <div className="flex flex-column gap-5 justify-c">
                  <div className="title">{item.clientFirstName}</div>
                  <div className="text">{formatTime(item.paymentDate)}</div>
                </div>
              </div>
              <div
                className={`text ${
                  item.type === "CHARGE" ? "text_red" : "text_green"
                }`}
              >
                {item.type === "CHARGE"
                  ? `- ${item.amount}`
                  : `+ ${item.amount}`}
                {" "}
                {t("som")}
                .
              </div>
            </div>
          ))}
        </>
      )}
      {!!previousTransactions.length && (
        <>
          <div className="name">Предыдущие</div>
          {previousTransactions.map((item) => (
            <div className="card_transaction" key={item.id}>
              <div className="flex gap-10">
                <Avatar />
                <div className="flex flex-column gap-5 justify-c">
                  <div className="title">{item.clientFirstName}</div>
                  <div className="text">{formatTime(item.paymentDate)}</div>
                </div>
              </div>
              <div className={`text ${
                item.type === "CHARGE" ? "text_red" : "text_green"
              }`}
              >
                {item.type === "CHARGE"
                  ? `- ${item.amount}`
                  : `+ ${item.amount}`}
                {" "}
                {t("som")}
                .
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
});

export default Transactions;
