import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { notification } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import CardAdd from "../../../components/card-add";
import onMessageListener from "../../../helpers/sdk/sdk";
import "./style.scss";
import { RootStoreContext } from "../../../store/rootStore.ts";
import TabMain from "../../../components/tab-main";
import PaymentSuccess from "../../../components/payment-success";
import PaymentDetailsFailed from "../../../components/payment-failed";

const ClientMain = observer(() => {
  const { userStore, clientAppointmentsStore, notificationStore } = useContext(RootStoreContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const today = moment().format("YYYY-MM-DD");
  const tomorrow = moment().add(1, "days").startOf("day"); // Начало завтрашнего дня
  const dateIn20Days = moment(tomorrow).add(19, "days").endOf("day"); // Конец 20-дневного периода, начиная с завтра

  useEffect(() => {
    clientAppointmentsStore.fetchAppointments();
    userStore.fetchUser();
  }, [userStore, clientAppointmentsStore, notificationStore.startAppointment]); // Убедитесь, что все зависимости перечислены здесь

  return (
    <div className="client_main">
      <div className="content container_mobile back_ground main_page">
        <TabMain today={today} dateIn20Days={dateIn20Days} />
        {/* {!!clientAppointmentsStore.today?.length === false && !!clientAppointmentsStore.today === false */}
        {/* && !!clientAppointmentsStore.future?.length === false && !!clientAppointmentsStore.future === false */}
        {/*  && <CardAdd title={t("findCoach")} onClick={handleClick} />} */}
      </div>
      <PaymentSuccess />
      <PaymentDetailsFailed />
    </div>
  );
});

export default ClientMain;
