import { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { RootStoreContext } from "../../store/rootStore.ts";
import "./style.scss";

const CalendarTime = observer((props) => {
  const { individualAppointmentsStore, coachStore, userStore, clientIndividualAppointmentsStore } = useContext(RootStoreContext);
  const [slotsForFourWeeks, setSlotsForFourWeeks] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  let worktimes;
  useEffect(() => {
    const bookedSlots = coachStore.session?.worktimes?.booked;
    if (userStore.role === "coach") {
      if (!individualAppointmentsStore.isLoading && !individualAppointmentsStore.workingSchedule?.length) {
        individualAppointmentsStore.setWorkingSchedule(individualAppointmentsStore.settings.worktimes);
      }
      worktimes = individualAppointmentsStore.workingSchedule || individualAppointmentsStore.settings.worktimes;
    } else if (!coachStore.isLoading) { // Клиент
      worktimes = coachStore.session?.worktimes?.worktimes;
    }
    const slots = createSlotsForNextFourWeeks(worktimes, bookedSlots);
    setSlotsForFourWeeks(slots);
  }, [individualAppointmentsStore, individualAppointmentsStore.settings?.worktimes, coachStore.session?.worktimes?.worktimes, userStore.role]);
  useEffect(() => {
    clientIndividualAppointmentsStore.setTime(selectedTimeSlots);
  }, [selectedTimeSlots, clientIndividualAppointmentsStore]);
  const generateTimeSlots = (start, end, currentDate, currentDateTime) => {
    let startTime = moment(start, "HH:mm:ss");
    const endTime = moment(end, "HH:mm:ss");
    const timeSlots = [];

    while (startTime.isBefore(endTime)) {
      if (currentDate.isSame(moment(), "day") && startTime.isBefore(currentDateTime)) {
        // Если слот уже прошел, пропускаем его
        startTime = startTime.add(1, "hours");
        // eslint-disable-next-line no-continue
        continue;
      }
      timeSlots.push({ time: startTime.format("HH:mm"), isAvailable: true });
      startTime = startTime.add(1, "hours");
    }

    return timeSlots;
  };

  const getFormattedDate = (date) => date.format("dd D MMM YYYY");
  const dayOfWeekFromMoment = (dayNumber) => {
    const daysMap = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
    return daysMap[dayNumber];
  };
  const createSlotsForNextFourWeeks = (worktimes, bookedSlots) => {
    const slotsByDate = [];
    let currentDate = moment();
    const currentDateTime = moment();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 7; i++) { // Для каждого дня в течение 4 недель
      const dayOfWeekString = dayOfWeekFromMoment(currentDate.day());

      const worktimesForDay = worktimes?.filter(
        (worktime) => worktime.dayOfWeek === dayOfWeekString,
      );
      const formattedDate = getFormattedDate(currentDate);
      let daySlots = [];

      // eslint-disable-next-line no-loop-func
      worktimesForDay?.forEach((worktime) => {
        if (worktime.start !== worktime.end) {
          const timeSlots = generateTimeSlots(worktime.start, worktime.end, currentDate, currentDateTime);
          daySlots = daySlots.concat(timeSlots);
        }
      });

      if (daySlots.length > 0) {
        slotsByDate.push({
          date: formattedDate,
          slots: daySlots,
        });
      }

      currentDate = currentDate.add(1, "day");
    }

    bookedSlots?.forEach((bookedSlot) => {
      const bookedStartDate = moment.utc(bookedSlot.startOfAppointment).format("dd D MMM YYYY");
      const bookedStartTime = moment.utc(bookedSlot.startOfAppointment).format("HH:mm");
      slotsByDate.forEach((dateSlot) => {
        dateSlot.slots.forEach((slot) => {
          if (slot.time === bookedStartTime && dateSlot.date === bookedStartDate) {
            slot.isAvailable = false;
          }
        });
      });
    });
    return slotsByDate;
  };

  const handleSelectTimeSlot = (timeSlot, day) => {
    const selectedSlot = { time: timeSlot, date: day.date };
    setSelectedTimeSlots((prevSelectedSlots) => {
      // Проверяем, содержит ли массив уже этот слот
      const isAlreadySelected = prevSelectedSlots.some((slot) => slot.time === selectedSlot.time && slot.date === selectedSlot.date);

      if (isAlreadySelected) {
        // Удаляем слот, если он уже выбран
        return prevSelectedSlots.filter((slot) => slot.time !== selectedSlot.time || slot.date !== selectedSlot.date);
      } 
      // Добавляем новый слот
      return [...prevSelectedSlots, selectedSlot];
    });
  };
  return (
    <div className="calendar_time">
      {userStore.role === "coach" && slotsForFourWeeks.map((daySlots, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="content">
          <div className="title">{daySlots.date}</div>
          <div className="time-slots">
            {daySlots.slots.map((slot, slotIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={slotIndex} className="time-slot card">
                {slot.time}
              </div>
            ))}
          </div>
        </div>
      ))}
      {userStore.role === "client" && (
        slotsForFourWeeks?.map((daySlots, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="content">
            <div className="title">{daySlots.date}</div>
            <div className="time-slots">
              {daySlots.slots?.map((slot, slotIndex) => {
                // Определяем, является ли слот выбранным
                const isSelected = selectedTimeSlots.some((selectedSlot) => selectedSlot.time === slot.time && selectedSlot.date === daySlots.date);
                const slotClassName = `time-slot card ${isSelected ? "selected" : ""} ${!slot.isAvailable ? "unavailable" : ""}`;

                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={slotIndex}
                    className={slotClassName}
                    onClick={() => slot.isAvailable && handleSelectTimeSlot(slot.time, daySlots)}
                  >
                    {slot.time}
                  </div>
                );
              })}
            </div>
          </div>
        ))
      )}
    </div>
  );
});

export default CalendarTime;
