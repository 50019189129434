import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Icon from "../icon";
import CalendarTime from "../calendar-time";
import { RootStoreContext } from "../../store/rootStore.ts";
import "./style.scss";

const CalendarProfile = observer(() => {
  const { userStore } = useContext(RootStoreContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNav = () => {
    navigate("/setting/profile-coach/work-hours");
  };

  return (
    <div className="calendar_profile">
      { userStore.role === "coach" 
      && (
      <div className="flex justify-s alignC" onClick={handleNav}>
        <div className="title">{t("schedule")}</div>
        <div className="flex alignC gap-10 pointer">
          <div className="text">{t("change")}</div>
          <Icon name="arrow_rigth" />
        </div>
      </div>
      )}
      <CalendarTime />
    </div>
  );
});

export default CalendarProfile;
