import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Form, Input, Select, Spin,
} from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from "lodash";
import { NavLink, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import i18n from "i18next";

import mixpanel from "../../helpers/mix-panel/mixpanel";
import Icon from "../../components/icon";
import Avatar from "../../components/avatar";
import "./style.scss";
import { RootStoreContext } from "../../store/rootStore.ts";
import AvatarAny from "../../components/avatar-any";
import ModalPaymentWait from "../../components/modal-payment-wait";

const { Option } = Select;
const MentorList = observer((props) => {
  const {
    userStore,
    clientIndividualAppointmentsStore,
    specializationsStore,
    usersListStore,
    coachesListStore,
    coachStore,
    lastAppointmentsAtore,
  } = useContext(RootStoreContext);
  const { mode, multi, name } = props;
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState("");
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [activeTab, setActiveTab] = useState("Тренеры");
  const navigate = useNavigate();
  const filteredList = [];
  const { t } = useTranslation();

  useEffect(() => {
    coachesListStore.fetchCoaches();
    specializationsStore.fetchSpecializations();
  }, []);

  // eslint-disable-next-line consistent-return
  function getYearText(number, language) {
    if (language === "en") {
      return number === 1 ? "year" : "years";
    } if (language === "ru") {
      const lastDigit = number % 10;
      const lastTwoDigits = number % 100;

      if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
        return "лет";
      } if (lastDigit === 1) {
        return "год";
      } if (lastDigit >= 2 && lastDigit <= 4) {
        return "года";
      } 
      return "лет";
    }
  }

  const handleClientClick = (item) => {
    if (mode === "display") {
      //
    } else if (mode === "select") {
      if (selectedClients.includes(item.id)) {
        setSelectedClients((prev) => prev.filter((clientId) => clientId !== item.id));
      } else if (multi) {
        setSelectedClients((prev) => [...prev, item.id]);
      } else {
        setSelectedClients([item.id]);
      }
    }
  };

  const handleSubmit = () => {
    console.log("done");
  };
  
  const handleNav = (id, name) => {
    navigate(`/coach/${id}`);
    coachStore.setId(id);
    clientIndividualAppointmentsStore.setId(id);
    lastAppointmentsAtore.setId(id);
    clientIndividualAppointmentsStore.setName(name);
    mixpanel.track("Trainer Profile Viewed", {
      "Trainer Name": name,
    });
  };

  const handleSpecialityChange = (value) => {
    coachesListStore.setSpecialities(value);
    coachesListStore.fetchCoaches();
  };

  const handleLanguageChange = (value) => {
    coachesListStore.setLanguages(value);
    coachesListStore.fetchCoaches();
  };
  const handleResetExperience = () => {
    setSelectedExperience(null); // Сброс выбранного значения
    coachesListStore.setExperienceRange(null); // Сброс значения в хранилище
    coachesListStore.fetchCoaches();
  };
  const handleNumber = (value) => {
    coachesListStore.setExperienceRange(value);
    coachesListStore.fetchCoaches();
  };

  const handleSearchChange = debounce((event) => {
    coachesListStore.setSearchRequest(event.target.value);
    coachesListStore.fetchCoaches();
  }, 500); // 500 мс задержки
  if (usersListStore.usersLoading) return <Spin />;
  return (
    <div className="mentor_list">
      <div className="container_mobile back_ground pb-120">
        {/* <Names name={name} title="Назад" /> */}
        <Form onFinish={handleSubmit} className="form" layout="vertical">
          <Form.Item name="search">
            <Input
              prefix={<Icon name="search" />}
              placeholder={`${t("search")}...`}
              onChange={handleSearchChange}
            />
          </Form.Item>
          {/* <TabCircle */}
          {/*  name="Тренеры" */}
          {/*  subName="Тренеры" */}
          {/*  onClick={setActiveTab} */}
          {/*  activeTab={activeTab} */}
          {/* /> */}

          {activeTab === "Тренеры" && (
          <>
            <Form.Item label={t("specialization")} name="trainerSpecialist">
              <Select
                mode="multiple"
                value={selectedSpecialist}
                onChange={handleSpecialityChange}
                placeholder={t("findCoach")}
              >
                {specializationsStore.specializations?.map((spec) => (
                  <Option value={spec} key={spec}>
                    {spec.charAt(0) + spec.slice(1).toLowerCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item label="Язык" name="language"> */}
            {/*  <Select */}
            {/*    value={selectedLanguage} */}
            {/*    onChange={handleLanguageChange} */}
            {/*    placeholder="Выберите язык" */}
            {/*    mode="select" */}
            {/*  > */}
            {/*    <Option value="ENG">Английский</Option> */}
            {/*    <Option value="KGZ">Кыргызский</Option> */}
            {/*    <Option value="RUS">Русский</Option> */}
            {/*  </Select> */}
            {/* </Form.Item> */}

            <Form.Item label={t("experience")} name="experience">
              <Select
                allowClear
                value={selectedExperience}
                onChange={handleNumber}
                placeholder={t("chooseExperience")}
                onClear={handleResetExperience}
              >
                <Option value="1-5">
                  1-5
                  {" "}
                  {t("years")}
                </Option>
                <Option value="6-10">
                  6-10
                  {" "}
                  {t("years")}
                </Option>
                <Option value="11-15">
                  11-15
                  {" "}
                  {t("years")}
                </Option>
                <Option value="16-20">
                  16-20
                  {" "}
                  {t("years")}
                </Option>
                <Option value="21+">
                  21+
                  {" "}
                  {t("years")}
                </Option>
              </Select>
            </Form.Item>

            <div className="coaches_list">
              {coachesListStore.coaches?.map((item) => (
                <div
                  className="coaches_cards"
                  onClick={() => handleNav(item.id, item.firstName)}
                  key={item.id}
                >
                  <div className="flower">
                    <AvatarAny url={item.avatarUrl} />
                    <div className="flex flex-column justify-c gap-5">
                      <div className="title">
                        {item.firstName} 
                        {" "}
                        {item.lastName && item.lastName[0]}
                      </div>
                      <div className="text">
                        {t("coach")}
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      {t("experience")}
                      :
                      {" "}
                      {item.experience ? item.experience : "0" }
                      {" "}
                      {getYearText(item.experience, i18n.language)}
                    </div>
                    <div className="title">
                      {t("online")}
                      {" "}
                      {item.availableOffline && `• ${t("offline")}`}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
          )}

          {usersListStore.usersLoading && <Spin />}
          {!usersListStore.usersLoading
          && filteredList.map((item) => (
            <div
              key={item.id}
              onClick={() => handleClientClick(item)}
              className={`card_client ${
                mode === "select" ? "select_mode" : ""
              } ${selectedClients.includes(item.id) ? "selected" : ""}`}
            >
              {mode === "display" && (
                <NavLink to={`/client/${item.id}`} className="nav">
                  {userStore.user.avatarUrl ? (
                    <img
                      src={userStore.user.avatarUrl}
                      alt="no img"
                      className="img"
                    />
                  ) : (
                    <Avatar />
                  )}
                  <div>
                    <div className="title">{item.firstName}</div>
                    <div className="text">item.place</div>
                  </div>
                </NavLink>
              )}
              {mode === "select" && (
                <>
                  <div className="flex alignC gap-10">
                    {userStore.user.avatarUrl ? (
                      <img
                        src={userStore.user.avatarUrl}
                        alt="no img"
                        className="img"
                      />
                    ) : (
                      <Avatar />
                    )}
                    <div>
                      <div className="title">{item.firstName}</div>
                      <div className="text">item.place</div>
                    </div>
                  </div>
                  {selectedClients.includes(item.id) && <Icon name="check" />}
                </>
              )}
            </div>
          ))}
        </Form>
      </div>
      <ModalPaymentWait />
    </div>
  );
});

export default MentorList;
