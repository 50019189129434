import { useContext } from "react";
import { observer } from "mobx-react";

import { Button } from "antd";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import MyCalendar from "../../../components/calendar";
import Names from "../../../components/names";
import CalendarTime from "../../../components/calendar-time";
import { RootStoreContext } from "../../../store/rootStore.ts";

const TimetableWorkHoursCalendarClient = observer(() => {
  const { clientIndividualAppointmentsStore } = useContext(RootStoreContext);

  const navigate = useNavigate();

  // const handleNav = () => {
  //   navigate("/client/reservation");
  // };
  
  const handleDateChange = (newDate) => {
    const formattedDate = moment(newDate).format("D MMMM");
    clientIndividualAppointmentsStore.setDate(formattedDate);
  };
  const handleDone = () => {
    navigate(-1);
  };
  return (
    <div className="container_mobile flex time_table_work_hours_calendar flex-column gap-20 back_ground">
      <Names title="Назад" name="Выходные дни" />

      <MyCalendar onDateChange={handleDateChange} />

      <div className="time_table_work_hours_calendar_content">
        {/* <CustomInput text={"Бронировать наперед"} onClick={handleNav} /> */}
        <CalendarTime />
      </div>

      <Button className="button_done" onClick={() => handleDone()}>
        Выбрать
      </Button>
    </div>
  );
});

export default TimetableWorkHoursCalendarClient;
