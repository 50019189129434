import { makeAutoObservable, runInAction } from "mobx";
import { getCoachClientDetail, getCoachClientDetailTest } from "../api";

import { IRootStore } from "./rootStore.ts";

export class ClientStore {
  rootStore: IRootStore;
  client: any[];
  isLoading: boolean;
  clients: any[];

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.client = [];
    this.clients = [];
    this.isLoading = false;

    makeAutoObservable(this);
  }

  async fetchClient(ids: number[]) {
    const { authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.isLoading = true;
        });
      }
      if (ids.length > 1) {
        const promises = ids.map((item) => getCoachClientDetailTest(item, [item]));
        runInAction(() => {
          if (ids) {
            this.clients = promises;
          }
        });
      } else {
        const response = await getCoachClientDetail(ids[0]);
        runInAction(() => {
          if (ids[0]) {
            this.client = response;
          }
        });
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
