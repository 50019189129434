import React from "react";
import { Button } from "antd";
import work from "../../../../../assets/imgs/landing_work.png";
import mobile from "../../../../../assets/imgs/landing_work_mobile.png";
import "./style.scss";
import Icon from "../../../../../components/icon";

function LandingWork(props) {
  const { coachRef } = props;
  const handleNav = () => {
    window.open("https://apps.apple.com/kg/app/move-mates/id6470747632", "_blank");
  };
  return (
    <div className="landing_work" ref={coachRef}>
      <div className="container_landing">
        <div className="flower">
          <img src={work} alt="" className="img" />
          <img src={mobile} alt="" className="img_mobile" />
        </div>
        <div className="content">
          <div className="title">Become a trainer</div>
          <div className="text">
            Earn money sharing your expert knowledge with students. Sign up to
            start tutoring online with Move Mates.
          </div>
          <div className="war">
            <div className="flex alignC gap-16">
              <Icon name="person" />
              <div className="war_title"> Find new clients</div>
            </div>
            <div className="flex alignC gap-16">
              <Icon name="big_bag" />
              <div className="war_title">Grow your business</div>
            </div>
            <div className="flex alignC gap-16">
              <Icon name="credit_card" />
              <div className="war_title">Get paid securely</div>
            </div>
          </div>
          <Button type="primary" className="button" onClick={handleNav}>
            <div className="button_text">
              Become a trainer
            </div>
            <Icon name="arrow_right_white" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LandingWork;
