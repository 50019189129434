import React, { useContext } from "react";
import { Form, Select, Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { observer } from "mobx-react";
import Names from "../../../components/names";
import CustomInput from "../../../components/input";
import { RootStoreContext } from "../../../store/rootStore.ts";

const { Option } = Select;

const TimeTableIndividualClient = observer(() => {
  const { clientIndividualAppointmentsStore, coachStore } = useContext(RootStoreContext);
  const navigate = useNavigate();
  const userFromLocalStorage = localStorage.getItem("savedData");
  const userObject = JSON.parse(userFromLocalStorage);
  const specialization = userObject?.specialization;

  const handleTime = () => {
    navigate("/client/specialization/work-hours/calendar");
  };

  const handleSpecializationChange = (value) => {
    clientIndividualAppointmentsStore.setSpeciality(value?.toUpperCase());

    const currentSavedData = JSON.parse(localStorage.getItem("savedData")) || {};
    currentSavedData.specialization = value;
    localStorage.setItem("savedData", JSON.stringify(currentSavedData));

    // handleSave();
  };

  // const handleMeetingTypeChange = (e) => {
  //   setMeetingType(e.target.value);
  // };
  const handleDone = () => {
    clientIndividualAppointmentsStore.fetchIndividualAppointments();
    localStorage.removeItem("savedData");
    localStorage.removeItem("type");
    localStorage.setItem("showNotification", "true");
    navigate(-1);
  };

  const handleClear = () => {
    localStorage.removeItem("savedData");
    localStorage.removeItem("type");
  };

  const isFormComplete = () => (
    clientIndividualAppointmentsStore.name
      && specialization
      && clientIndividualAppointmentsStore.date
      && clientIndividualAppointmentsStore.time
  );

  return (
    <div className="container_mobile timetable_individual back_ground">
      <Names
        title="Назад"
        name="Индивидуальная"
        handleClick={handleClear}
      />

      <Form layout="vertical">
        <Form.Item label="Тренер">
          <CustomInput text={clientIndividualAppointmentsStore.name} noNav />
        </Form.Item>
        <Form.Item label="Специализация">
          <Select
            placeholder="Выбрать"
            onChange={handleSpecializationChange}
            value={specialization}
          >
            {coachStore.coach.specialities.map((spec) => (
              <Option value={spec} key={spec}>
                {spec.charAt(0) + spec.slice(1).toLowerCase()}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* {selectedSpecialization && selectedSpecialization.length > 0 && ( */}
        {/*  <Form.Item label="Тип встречи"> */}
        {/*    <Radio.Group onChange={handleMeetingTypeChange} value={meetingType}> */}
        {/*      <Radio value="online">Онлайн</Radio> */}
        {/*      <Radio value="offline">Офлайн</Radio> */}
        {/*    </Radio.Group> */}
        {/*  </Form.Item> */}
        {/* )} */}

        {/* {selectedSpecialization.length > 0 && meetingType === "offline" && ( */}
        {/*  <Form.Item label="Адрес"> */}
        {/*    <CustomInput text={"Введите адрес"} /> */}
        {/*  </Form.Item> */}
        {/* )} */}

        <Form.Item label="Дата и время">
          <CustomInput
            text={
              clientIndividualAppointmentsStore.date
              && clientIndividualAppointmentsStore.time ? (
                <>
                  {" "}
                  {clientIndividualAppointmentsStore.date}
                  {" "}
                  {clientIndividualAppointmentsStore.time}
                  {" "}
                </>
                ) : (
                  "Выбрать"
                )
            }
            onClick={handleTime}
          />
        </Form.Item>

        <Button
          className="button"
          onClick={handleDone}
          disabled={!isFormComplete()}
        >
          Готово
        </Button>
      </Form>
    </div>
  );
});

export default TimeTableIndividualClient;
