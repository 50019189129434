import React from "react";
import Names from "../../../components/names";
import PaymentDetailsFailed from "../../../components/payment-failed";

function PaymentFailed() {
  return (
    <div className="payment_details back_ground container_mobile">
      <Names title="Назад" name="Детали транзакции" />
      <PaymentDetailsFailed />
    </div>
  );
}

export default PaymentFailed;
