import React from "react";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";
import ButtonBeta from "../../../../../components/buttons/button-beta";
import Icon from "../../../../../components/icon";
import "./style.scss";
import AppleButton from "../../../../../components/buttons/button-apple";

function LandingFooter() {
  const navigate = useNavigate();
  const handlePrivacy = () => {
    navigate("/privacy-policy");
  };

  const handleTerm = () => {
    navigate("/term-of-use");
  };

  const handleOffer = () => {
    navigate("/offer-contact");
  };

  const handlePay = () => {
    navigate("/refund");
  };
  return (
    <div className="landing_footer">
      <div className="container_landing">
        <div className="flex gap-10 alignC">
          <div className="name">MoveMates</div>
          <ButtonBeta />
        </div>
        <Divider />
        <div className="content">
          <div className="war">
            <div className="title">Contacts</div>
            <div className="text">Bishkek, st. Toktogul 106</div>
            <div className="text">+996777255553</div>
            <div className="text">contacts@move-mates.com</div>
          </div>
          <div className="war">
            <div className="title">TSE Account details</div>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <div className="text">Bishkek Central Branch of OJSC "Optima Bank"</div>
            <div className="text">BIС: 109018</div>
            <div className="text">Bank Account: 1091811183110286</div>
            <div className="text">SWIFT: ENEJKG22</div>
          </div>
          <div className="war">
            <div className="title">Payment options</div>
            <div className="flex gap-20 alignC">
              <Icon name="footer_visa" />
              <Icon name="footer_maestro" />
              <Icon name="footer_master" />
              <Icon name="footer_ssl" />
            </div>
            <AppleButton className="button_apple_border" dark />
          </div>
        </div>
        <Divider />
        <div className="flower">
          <div className="flex alignC gap-5">
            <Icon name="c" />
            <div className="title">
              Move Mates. All rights reserved.
            </div>
          </div>
          <div className="flex gap-20 alignE">
            <div className="title" onClick={handlePay}>Help & Support</div>
            <div className="title" onClick={handlePrivacy}>Privacy</div>
            <div className="title" onClick={handleTerm}>Terms of Service</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingFooter;
