import React from "react";

function PaymentProgress() {
  return (
    <div className="container_mobile pt-20">
      iska
    </div>
  );
}

export default PaymentProgress;
