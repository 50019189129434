// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from "i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import { initReactI18next } from "react-i18next";
import enJSON from "./language/en.json";
import ruJSON from "./language/ru.json";

// console.log("Resources loaded:", { en: enJSON, ru: ruJSON });
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enJSON,
      },
      ru: {
        translation: ruJSON,
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
