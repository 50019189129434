import React, { useContext, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import ClientList from "../../../../components/client-list";
import Names from "../../../../components/names";
import { RootStoreContext } from "../../../../store/rootStore.ts";

const TimeTableChoseClient = observer(() => {
  const { individualAppointmentsStore, clientStore } = useContext(RootStoreContext);
  const [selectedClients, setSelectedClients] = useState(null);
  const navigate = useNavigate();
  const handleClientsSelect = (clients) => {
    setSelectedClients(clients);
  };

  const handleButtonClick = () => {
    individualAppointmentsStore.setClientId(selectedClients[0]);
    clientStore.fetchClient(selectedClients);
    navigate(-1);
  };

  return (
    <div className="container_mobile back_ground">
      <Names title="Назад" name="Ваши клиенты" />
      <ClientList mode="select" onClientsSelect={handleClientsSelect} />
      <Button className="button_done" onClick={handleButtonClick}>
        Выбрать
      </Button>
    </div>
  );
});

export default TimeTableChoseClient;
