import React, { useContext, useEffect, useState } from "react";
import {
  Form, Select, Radio, Button,
} from "antd";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Names from "../../../../components/names";
import CustomInput from "../../../../components/input";
import "./style.scss";
import { RootStoreContext } from "../../../../store/rootStore.ts";

const { Option } = Select;

const TimeTableGroupMentor = observer(() => {
  const { groupAppointmentsStore, clientStore } = useContext(RootStoreContext);
  const [specializationSelected, setSpecializationSelected] = useState(false);
  const [trainingType, setTrainingType] = useState(null);

  const [savedData, setSavedData] = useState(null);

  const [name, setName] = useState("");
  const [specialization, setSpecialization] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const savedDataFromStorage = JSON.parse(localStorage.getItem("savedData"));
    if (savedDataFromStorage) {
      setSavedData(savedDataFromStorage);
      setSpecialization(savedDataFromStorage.specialization || []);
      setSpecializationSelected(true);
      setTrainingType(savedData?.trainingType || null);
    }
    if (clientStore.clients.length) {
      Promise.all(clientStore.clients).then((resolvedValues) => {
        resolvedValues.forEach((value) => {
          setName(value.name);
        });
      });
    }
  }, [savedData?.trainingType, clientStore.clients]);

  const handleSave = () => {
    if (specialization && trainingType) {
      const dataToSave = {
        specialization,
        trainingType,
      };
      setSavedData(dataToSave);
      localStorage.setItem("savedData", JSON.stringify(dataToSave));
    }
  };

  const handleSpecializationChange = (value) => {
    setSpecialization(value);
    groupAppointmentsStore.setSpeciality(value?.toUpperCase());
    if (value && value.length > 0) {
      setSpecializationSelected(true);
    } else {
      setSpecializationSelected(false);
      setTrainingType(null);
    }
    const currentSavedData = JSON.parse(localStorage.getItem("savedData")) || {};
    currentSavedData.specialization = value;
    localStorage.setItem("savedData", JSON.stringify(currentSavedData));
    handleSave();
  };

  const handleTrainingTypeChange = (e) => {
    const { value } = e.target;
    setTrainingType(value);
    groupAppointmentsStore.setOffline(value === "offline");

    handleSave();
  };

  const handleTime = () => {
    navigate("/mentor/specialization/work-hours/calendar");
  };

  const handleClient = () => {
    navigate("/mentor/specialization/group/chose-client");
  };

  const handleDone = () => {
    groupAppointmentsStore.fetchGroupAppointments();
    localStorage.removeItem("savedData");
    localStorage.removeItem("type");
    localStorage.setItem("showNotification", "true");
    navigate(-1);
  };
  const handleClear = () => {
    localStorage.removeItem("savedData");
    localStorage.removeItem("type");
  };

  return (
    <div className="container_mobile timetable_individual back_ground">
      <Names title="Назад" name="Группа" handleClick={handleClear} />

      <Form layout="vertical">
        <Form.Item label="Специализация">
          <Select
            placeholder="Выбрать"
            onChange={handleSpecializationChange}
            value={specialization}
          >
            <Option value="Fitness">Fitness</Option>
            <Option value="Stretching">Stretching</Option>
            <Option value="Bench">Bench</Option>
          </Select>
        </Form.Item>
        {specializationSelected && (
          <Form.Item className="button_chose">
            <Radio.Group
              onChange={handleTrainingTypeChange}
              value={groupAppointmentsStore.isOffline ? "offline" : "online"}
            >
              <Radio value="online">Онлайн</Radio>
              <Radio value="offline">Офлайн</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {groupAppointmentsStore.isOffline && (
          <CustomInput
            text="Укажите адресс в профиле"
            noNav
            className="input_custom"
          />
        )}

        <Form.Item label="Дата и время">
          <CustomInput
            text={
              groupAppointmentsStore.date && groupAppointmentsStore.time ? (
                <>
                  {" "}
                  {groupAppointmentsStore.date}
                  {" "}
                  {groupAppointmentsStore.time}
                  {" "}
                </>
              ) : (
                "Выбрать"
              )
            }
            onClick={handleTime}
          />
        </Form.Item>

        <Form.Item label="Клиент">
          <CustomInput
            text={
              name === null ? (
                <>
                  {name === null
                    ? "Клиент выбран"
                    : clientStore.clients.map((item) => item.name)}
                </>
              ) : (
                "Выбрать"
              )
            }
            onClick={handleClient}
          />
        </Form.Item>

        <Button className="button" onClick={handleDone}>
          Готово
        </Button>
      </Form>
    </div>
  );
});

export default TimeTableGroupMentor;
