import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import Avatar from "../avatar";
import { RootStoreContext } from "../../store/rootStore.ts";
import "./style.scss";

const AvatarName = observer(() => {
  const { userStore } = useContext(RootStoreContext);
  const { t } = useTranslation();
  return (
    <div className="avatar_name">
      <Avatar />
      <div className="flex flex-column gap-5">
        <div>
          {userStore.user?.firstName}
          {" "}
          {userStore.user?.lastName}
        </div>
        <div>
          {userStore.role === "coach" ? t("coach") : t("client") }
        </div>
      </div>
    </div>
  );
});

export default AvatarName;
