import React from "react";
import { observer } from "mobx-react";
import "./style.scss";
import SettingsComponent from "../../../components/settings";

const Settings = observer(() => (
  <div className="settings container_mobile back_ground main_page">
    <SettingsComponent />
  </div>
));

export default Settings;
