import React, { useEffect, useState, useContext } from "react";
import { getToken } from "firebase/messaging";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button, Form, Input, Checkbox, Select, Radio,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import "./style.scss";
import "react-phone-input-2/lib/style.css";
import { signUp } from "../../../api";
import Names from "../../../components/names";
import { RootStoreContext } from "../../../store/rootStore.ts";
import { registerToken } from "../../../helpers/constants/registerToken.ts";
import { messaging } from "../../../helpers/sdk/sdk";
import img from "../../../assets/imgs/login_img.png";
import Logo from "../../../components/logo";
import AppDownloadModal from "../../../components/modal-deep-link";

const { Option } = Select;

class FormStore {
  formData = {};

  constructor() {
    makeAutoObservable(this);
  }

  setFormData(data) {
    this.formData = data;
  }
}
const formStore = new FormStore();
const Register = observer(() => {
  const { userStore, chatRegisterStore, authStore } = useContext(RootStoreContext);
  const navigate = useNavigate();
  const [form] = useForm();
  const params = useParams();
  const { refCode } = params;
  useEffect(() => {
    form.setFieldsValue(formStore.formData);
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      form.setFieldsValue(JSON.parse(savedData));
    }
  }, [form]);
  const handleValuesChange = (_, allValues) => {
    localStorage.setItem("formData", JSON.stringify(allValues));
  };
  const onFinish = async (values) => {
    userStore.setCoach(false);
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      role: values.role,
      token: refCode,
    };

    try {
      const response = await signUp(payload, values.role);
      if (response) {
        userStore.setRole(values.role);
        authStore.setToken(response?.loginResponse?.token);
        getToken(messaging, { vapidKey: registerToken })
          .then((token) => {
            chatRegisterStore.fetchChat(token);
          })
          .catch((err) => {
            console.error("Error getting token or sending it to backend:", err);
          });

        navigate("/main");
      }
    } catch (error) {
      if (error.response?.status === 417) {
        form.setFields([
          {
            name: "email",
            errors: ["Эта почта уже занята"],
          },
        ]);
      } else {
        console.log("error");
      }
    }
    localStorage.removeItem("formData");
  };

  const validatePassword = (_, value) => {
    const letterCount = (value.match(/[a-zA-Z]/g) || []).length;

    if (value.length < 8 || letterCount < 2) {
      return Promise.reject(
        new Error("Пароль должен содержать минимум 8 символов и 2 буквы"),
      );
    }
    return Promise.resolve();
  };

  const handleNavPrivasy = () => {
    navigate("/privacy-policy");
  };

  const handleNavTerm = () => {
    navigate("/term-of-use");
  };

  return (
    <div className="register ">
      <div className="part_one">
        <div>
          <Names title="назад" />
          <Logo text />
          <div className="login_text">
            Создать учетную запись
          </div>
        </div>

        <div>
          <Form
            name="register"
            className="validation"
            form={form}
            onValuesChange={handleValuesChange}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="role"
              label="Роль"
            >
              <Radio.Group>
                <Radio value="client">Клиент</Radio>
                {!refCode
                && <Radio value="coach">Тренер</Radio>}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="firstName"
              label="Введите ваше имя"
              rules={[
                { required: true, message: "Введите ваше имя!" },
                { min: 2, message: "Имя должно содержать не менее 2-х символов" },
              ]}
            >
              <Input placeholder="имя" />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Введите ваше фамилию"
              rules={[
                { required: true, message: "Введите ваше фамилию!" },
                { min: 2, message: "Имя должно содержать не менее 2-х символов" },
              ]}
            >
              <Input placeholder="фамилия" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Е-майл"
              rules={[
                { required: true, message: "Введите ваш е-майл!" },
                {
                  type: "email",
                  message: "Введен некорректный е-майл",
                },
              ]}
            >
              <Input placeholder="Введите ваш е-майл" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Пароль"
              rules={[
                { required: true, message: "Введите пароль!" },
                { validator: validatePassword },
              ]}
            >
              <Input.Password placeholder="Введите ваш пароль" />
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => (value
                    ? Promise.resolve()
                    : Promise.reject(
                      new Error(
                        "Необходимо принять условия использования и политику конфиденциальности!",
                      ),
                    )),
                },
              ]}
            >
              <div className="check_box">
                <Checkbox />
                <div className="text">
                  Я принимаю и согласен
                  {" "}
                  <span className="privacy_policy" onClick={handleNavTerm}>Условия использования</span>
                  {" "}
                  и
                  {" "}
                  <span className="terms_of_use" onClick={handleNavPrivasy}>
                    Политика
                    Конфиденциальности
                  </span>
                </div>
              </div>
            </Form.Item>
            <Button htmlType="submit" block>
              Зарегистрироваться
            </Button>
          </Form>
        </div>
      </div>
      <div className="part_two">
        <img src={img} alt="" />
      </div>
      <AppDownloadModal url={`register?refUrl=${refCode}`} />
    </div>
  );
});

export default Register;
