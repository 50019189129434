import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment/moment";
import { createAppointments } from "../api/index";
import { IRootStore } from "./rootStore.ts";

export class LastAppointmentsStore {
  rootStore: IRootStore;
  appointments: any[];
  isLoading: boolean;
  id: number | null;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.appointments = [];
    this.isLoading = false;
    this.id = null;

    makeAutoObservable(this);
  }

  setId(id: number) {
    runInAction(() => {
      this.id = id;
    });
  }

  async fetchAppointments(page = 0) {
    const today = moment();
    const { authStore } = this.rootStore;

    try {
      if (authStore?.token) {
        runInAction(() => {
          this.isLoading = true;
        });

        const filter = {
          from: null,
          to: moment(today).toISOString(),
          clientId: this.id,
          size: 20,
          page,
        };

        const response = await createAppointments(filter);
        const existingIds = new Set(this.appointments.map((coach) => coach.id));
        const uniqueNewCoaches = response?.content.filter(
          (coach: any) => !existingIds.has(coach.id),
        );
        runInAction(() => {
          if (page === 0) {
            this.appointments = [...this.appointments, ...uniqueNewCoaches];
          }
        });
        if (response?.content.length > 18 && page === 0) {
          await this.fetchAppointments(page + 1);
        }
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
