import { useContext, useEffect } from "react";
import {
  Route, Routes, useLocation, Navigate, useNavigate,
} from "react-router-dom";
import { isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { observer } from "mobx-react";
import routing from "./routing";
import { authNotRequiredPathes } from "./helpers/auth/authNotRequiredPathes.ts";

import Footer from "./components/footer";
import { RootStoreContext } from "./store/rootStore.ts";
import "./i18n";
import NotificationRequestModal from "./components/button-notification";

function App() {
  const { authStore, notificationStore, chatStore } = useContext(RootStoreContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    i18n.changeLanguage(savedLanguage);
    moment.locale(savedLanguage);
  }, [i18n]);

  useEffect(() => {
    const authNotRequired = authNotRequiredPathes.some((path) => location.pathname.startsWith(path.replace(/\/:\w+\*?$/, "")));
    if (!authNotRequired && !authStore.token) {
      navigate("/dashboard");
    }
  }, [authStore.token, navigate, location.pathname]);

  const FooterRoutes = [
    "/main",
    "/client",
    "/calendar",
    "coach",
    "client",
    "/settings",
    "/main/confirmed",
    "/main/failed",
  ];

  useEffect(() => {
    console.log("isSafari?", isSafari);
  }, []);

  const showFooter = FooterRoutes.includes(location.pathname);

  useEffect(() => {
    // if (isSafari) {
    //   console.log("dont need notification in app.jsx");
    //   return;
    // }
    const handleMessage = (event) => {
      console.log("Сообщение получено: ", event.data);
      if (event.data.notification.title === "CALL") {
        notificationStore.setStart(event.data.data);
        console.log("notif data poluchen?", notificationStore.startAppointment);
      } else if (event.data.data && event.data.data.type === "CHAT") {
        // const newChatName = event.data.data.chatName;
        // if (newChatName === chatStore.currentChatName) {
        //   // Логика для обработки прочитанных сообщений
        //   console.log("сообщение прочитанно");
        // }
        // chatStore.setChatName(event.data.data.chatName);
        const newChatName = event.data.data.chatName;
        chatStore.setUnreadChat(newChatName);
      }
    };

    navigator.serviceWorker.addEventListener("message", handleMessage);
    // Очистка при размонтировании компонента
    // eslint-disable-next-line consistent-return
    return () => {
      navigator.serviceWorker.removeEventListener("message", handleMessage);
    };
  }, [notificationStore]);

  return (
    <main className="app">
      {!isSafari 
      && <NotificationRequestModal /> }
      <Routes>
        {Object.keys(routing).map((key) => {
          const route = routing[key];
          if (route.role === "all") {
            return (
              <Route key={key} path={route.path} element={route.element} />
            );
          }
          return null;
        })}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {showFooter && <Footer />}
    </main>
  );
}

export default observer(App);
