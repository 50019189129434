import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import CardSubscription from "../../../components/card-subscription";
import Names from "../../../components/names";
import Tab from "../../../components/tab";
import "./style.scss";
import { RootStoreContext } from "../../../store/rootStore.ts";

const Subscriptions = observer(() => {
  const { payment, userStore } = useContext(RootStoreContext);
  useEffect(() => {
    try {
      payment.fetchPayment();
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  return (
    <div className="container_mobile back_ground pay">
      <Names title="Назад" name="Пакеты" />
      <CardSubscription />
      {/* <Tab */}
      {/*  name="Пакеты" */}
      {/*  subName="Статистика" */}
      {/*  content={<CardSubscription />} */}
      {/*  subContent={<div className="name pt-20">Пусто</div>} */}
      {/* /> */}
    </div>
  );
});

export default Subscriptions;
