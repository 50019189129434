import React from "react";
import Icon from "../icon";
import "./style.scss";

function CardAdd(props) {
  const { title, text, onClick } = props;
  return (
    <div className="card card_add" onClick={onClick}>
      <Icon name="add" className="icon" />
      <div className="">
        <div className="title">{title}</div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
}

export default CardAdd;
