import { makeAutoObservable, runInAction } from "mobx";
import { IRootStore } from "./rootStore.ts";

interface IAuthStore {
  rootStore: IRootStore
  token: string | null
  setToken: (token: string) => void
}

export class AuthStore implements IAuthStore { 
  rootStore: IRootStore;
  token: string | null;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.token = sessionStorage.getItem("authToken");

    makeAutoObservable(this);
  }

  setToken(token: string) {
    sessionStorage.setItem("authToken", token);
    runInAction(() => {
      this.token = token;
    });
  }
}
