import React, { useState } from "react";
import { Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Icon from "../../icon";
import ButtonLanding from "../button";
import "./style.scss";
import ButtonEmpty from "../../buttons/button-empty";
import ButtonPrimary from "../../buttons/button-primary";

const DrawerMenuLanding = observer(({ open, setOpen, scroll }) => {
  const navigate = useNavigate();
  const [placement, setPlacement] = useState("right");

  const onClose = () => {
    setOpen(!open);
  };
  const handleLog = () => {
    navigate("/dashboard");
  };

  const handleSin = () => {
    navigate("/dashboard");
  };

  return (
    <Drawer
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      key={placement}
      className="drawer_menu"
    >
      <ButtonEmpty text="Become a coach" size="l" onClick={scroll} />
      <ButtonEmpty text="Log in" size="l" onClick={handleLog} />
      <ButtonPrimary text="Sign up " size="l" onClick={handleSin} />
    </Drawer>
  );
});
export default DrawerMenuLanding;
