import { makeAutoObservable, runInAction } from "mobx";
import { getAppointmentsDetails, getAppointmentsDetailsClient } from "../api";
import { IRootStore } from "./rootStore.ts";

export class AppointmentDetailsStore {
  rootStore: IRootStore;
  appointments: any[];
  isLoading: boolean;
  id: number | null;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.appointments = [];
    this.isLoading = false;
    this.id = null;
    makeAutoObservable(this);
  }

  setId(id: number) {
    runInAction(() => {
      this.id = id;
    });
  }

  async fetchAppointments() {
    const { userStore, authStore } = this.rootStore;
    if (userStore.role === "coach") {
      try {
        if (authStore?.token) {
          runInAction(() => {
            this.isLoading = true;
          });

          const response = await getAppointmentsDetails(this.id);
          runInAction(() => {
            this.appointments = response;
          });
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    } else if (userStore.role === "client") {
      try {
        if (authStore?.token) {
          runInAction(() => {
            this.isLoading = true;
          });

          const response = await getAppointmentsDetailsClient(this.id);
          runInAction(() => {
            this.appointments = response;
          });
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  }  
}
