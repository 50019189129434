import React, { useContext } from "react";
import { observer } from "mobx-react";
import CoachMain from "../../coach/coach-main";
import ClientMain from "../../client/clientMain";
import { RootStoreContext } from "../../../store/rootStore.ts";

const Main = observer(() => {
  const { userStore } = useContext(RootStoreContext);

  const userRoles = {
    client: <ClientMain />,
    coach: <CoachMain />,
  };
  return <>{userRoles[userStore.role] || null}</>;
});

export default Main;
