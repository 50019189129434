import React, { useContext } from "react";
import { observer } from "mobx-react";
import { notification, Spin } from "antd";
import Icon from "../icon";
import { RootStoreContext } from "../../store/rootStore.ts";
import "./style.scss";

const Url = observer(() => {
  const { userStore } = useContext(RootStoreContext);
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(userStore.user?.refUrl)
      .then(() => {
        notification.success({
          message: "Успех!",
          description: "Успешно скопировано",
          duration: 3,
        });
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        notification.error({
          message: "Ошибка!",
          description: "Не удалось скопировать текст",
          duration: 3,
        });
      });
  };
  return (
    <div className="url">
      <div className="flex gap-10">
        <div className="card_statistic text">
          {userStore.isLoading ? <Spin /> : userStore.user?.refUrl}
        </div>
        <div className="card_statistic_copy" onClick={copyToClipboard}>
          <Icon name="copy" className="icon" />
        </div>
      </div>
      <div className="text text_statistic">
        Скопируйте и отправьте ссылку своему клиенту.
      </div>
    </div>
  );
});

export default Url;
