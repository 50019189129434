import React from "react";
import "./style.scss";

function ButtonBeta() {
  return (
    <div className="button_beta">
      BETA
    </div>
  );
}

export default ButtonBeta;
