import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Form, Button, Input, notification, Modal, Upload,
} from "antd";
import PhoneInput from "react-phone-input-2";
import ImgCrop from "antd-img-crop";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from "react-i18next";
import Names from "../../../components/names";
import { deactivateAccount, deleteAccount, editClient, editClientAvatar } from "../../../api";
import CustomInput from "../../../components/input";
import { RootStoreContext } from "../../../store/rootStore.ts";
import Language from "../../../components/language";
import "./style.scss";

const ProfileClient = observer(() => {
  const { userStore, authStore } = useContext(RootStoreContext);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [avatarFile, setAvatarFile] = useState(null);
  const [showUploadButton, setShowUploadButton] = useState(true);

  useEffect(() => {
    setAvatarFile(userStore.user?.avatarUrl);
  }, [form, userStore]);
  const onFinish = async (values) => {
    const filter = {
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phoneNumber: values.phoneNumber,
      aboutMeForCoach: values.aboutMeForCoach,
      aboutMeForTherapist: values.aboutMeForTherapist,
      experience: values.experience,
      birthDate: values.birthDate
        ? moment(values.birthDate).format("YYYY-MM-DD")
        : null,
      gender: values.gender,
      weight: values.weight,
      height: values.height,
      email: values.email,
      userName: values.userName,
    };
    const urlToBlob = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    };
    const formData = new FormData();
    const initialAvatarUrl = userStore.user.avatarUrl;

    if (avatarFile && typeof avatarFile === "string" && avatarFile !== initialAvatarUrl) {
      // console.log("что сработало 1? Изображение было изменено и будет преобразовано в blob для отправки.");
      const blob = await urlToBlob(avatarFile);
      formData.append("avatar", blob);
    } else if (avatarFile !== initialAvatarUrl) {
      // console.log("что сработало 2? Выбрано новое изображение и оно добавляется в formData.");
      formData.append("avatar", avatarFile);
    } else {
      // console.log("Изображение не изменилось, отправка изображения не требуется.");
    }

    try {
      const response = await editClient(filter);
      if (avatarFile && avatarFile !== userStore.user?.avatarUrl) {
        if (typeof avatarFile === "string") {
          const blob = await urlToBlob(avatarFile);
          formData.append("file", blob);
        } else {
          formData.append("file", avatarFile);
        }

        const avatarRes = await editClientAvatar(formData);
        // console.log("Ответ сервера: ", avatarRes);
      }

      if (response) {
        notification.success({
          description: t("profileSuccessfullyUpdated"),
          placement: "topRight",
        });

        userStore.fetchUser();
        navigate(-1);
      }
    } catch (error) {
      console.log("error");
      notification.error({
        message: t("error"),
        description: t("profileUpdateFailed"),
        placement: "topRight",
      });
    }
  };

  const initialValues = {
    firstName: userStore.user?.firstName,
    lastName: userStore.user?.lastName,
    phoneNumber: userStore.user?.phoneNumber,
    email: userStore.user?.email,
    height: userStore.user?.height,
    weight: userStore.user?.weight,
    gender: userStore.user?.gender,
    experience: userStore.user?.experience,
    aboutMeForCoach: userStore.user?.aboutMeForCoach,
    aboutMeForTherapist: userStore.user?.aboutMeForTherapist,
    birthDate: userStore.user?.birthDate,
  };

  const logOut = () => {
    sessionStorage.removeItem("authToken");
    authStore.setToken(null);
    localStorage.clear();
  };

  const showConfirmModal = (action) => {
    Modal.confirm({
      title: "Подтвердите действие",
      content: `Вы уверены, что хотите ${action}? Ваши данные будут удалены.`,
      okText: "Да",
      okType: "danger",
      cancelText: "Нет",
      async onOk() {
        if (action === "деактивировать аккаунт") {
          const response = await deactivateAccount();
          console.log("account deactivate");
          logOut();
        } else {
          const response = await deleteAccount();
          logOut();
          console.log("account deleted");
        }
      },
    });
  };
  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setAvatarFile(e.target.result);
      setShowUploadButton(false); // Скрываем кнопку загрузки после выбора файла
    };
    reader.readAsDataURL(file);
    return false; // Важно вернуть false, чтобы предотвратить автоматическую загрузку
  };
  return (
    <div className="container_mobile back_ground profile profile_client">
      <Names title={t("back")} />
      <div className="flex flex-column alignC photo">
        {avatarFile ? (
          <div className="flex flex-column alignC">
            <img src={avatarFile} alt="Profile Avatar" className="img" />
            <ImgCrop rotationSlider>
              <Upload showUploadList={false} beforeUpload={beforeUpload}>
                <div className="name">{t("changePhoto")}</div>
              </Upload>
            </ImgCrop>
          </div>
        ) : (
          <ImgCrop rotationSlider>
            <Upload listType="picture-card" showUploadList={false} beforeUpload={beforeUpload}>
              <div className="name">{t("changePhoto")}</div>
            </Upload>
          </ImgCrop>
        )}
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={initialValues}
        onFinish={onFinish}
        layout="vertical"
      >
        <div className="column">
          <div className="user_name">
            <Form.Item
              label={t("firstName")}
              name="firstName"
              rules={[
                { required: true, message: t("nameRule") },
                { pattern: /\S/, message: "Имя должно содержать хотя бы один непробельный символ" },
              ]}
            >
              <Input placeholder={t("firstName")} />
            </Form.Item>

            <Form.Item
              name="lastName"
              label={t("lastName")}
              rules={[
                { required: true, message: t("lastNameRule") },
                { pattern: /\S/, message: "Фамилия должна содержать хотя бы один непробельный символ" },
              ]}
            >
              <Input placeholder={t("lastName")} />
            </Form.Item>

            {/* <Form.Item */}
            {/*  label="Пожалуйста, добавьте информацию о себе, возможных медицинских противопоказаниях, болезнях, цель (эту информацию видит только ваш тренер)" */}
            {/*  name="aboutMeForCoach" */}
            {/*  labelCol={{ */}
            {/*    style: { */}
            {/*      fontFamily: "Montserrat", */}
            {/*      fontSize: "12px", */}
            {/*      fontStyle: "normal", */}
            {/*      fontWeight: 500, */}
            {/*      lineHeight: "19px", */}
            {/*      paddingBottom: "20px", */}
            {/*      marginBottom: "20px", */}
            {/*    }, */}
            {/*  }} */}
            {/* > */}
            {/*  <Input.TextArea */}
            {/*    placeholder="О себе для тренера" */}
            {/*    autoSize={{ minRows: 3, maxRows: 5 }} */}
            {/*  /> */}
            {/* </Form.Item> */}

            {/* <Form.Item */}
            {/*  label="Пожалуйста, добавьте информацию о себе, возможных переживаниях, опыте в терапии, цель (эту информацию видит только ваш психолог)" */}
            {/*  name="aboutMeForTherapist" */}
            {/*  labelCol={{ */}
            {/*    style: { */}
            {/*      fontFamily: "Montserrat", */}
            {/*      fontSize: "12px", */}
            {/*      fontStyle: "normal", */}
            {/*      fontWeight: 500, */}
            {/*      lineHeight: "19px", */}
            {/*      paddingBottom: "20px", */}
            {/*      marginBottom: "10px", */}
            {/*    }, */}
            {/*  }} */}
            {/* > */}
            {/*  <Input.TextArea */}
            {/*    placeholder="О себе для психолога" */}
            {/*    autoSize={{ minRows: 3, maxRows: 5 }} */}
            {/*  /> */}
            {/* </Form.Item> */}
          </div>
          <div className="user_sure">
            {/* {userStore.user.birthDate ? ( */}
            {/*  <Form.Item label="Ваш возраст" name="birthDate"> */}
            {/*    <CustomInput noNav text={userStore.user.birthDate} /> */}
            {/*  </Form.Item> */}
            {/* ) : ( */}
            {/*  <Form.Item */}
            {/*    label="Добавьте ваш возраст" */}
            {/*    name="birthDate" */}
            {/*    rules={[ */}
            {/*      { required: true, message: "Пожалуйста, укажите возраст!" }, */}
            {/*    ]} */}
            {/*  > */}
            {/*    <DatePicker format="DD-MM-YYYY" placeholder="Возраст" /> */}
            {/*  </Form.Item> */}
            {/* )} */}

            {/* <Form.Item */}
            {/*  label="Добавьте ваш пол" */}
            {/*  name="gender" */}
            {/*  rules={[{ required: true, message: "Пожалуйста, выберите язык!" }]} */}
            {/* > */}
            {/*  <Select placeholder="Выбрать"> */}
            {/*    <Option value="Муржской">Муржской</Option> */}
            {/*    <Option value="Женский">Женский</Option> */}
            {/*    <Option value="Другой">Другой</Option> */}
            {/*  </Select> */}
            {/* </Form.Item> */}

            {/* <Form.Item */}
            {/*  label="Если вы согласны, пожалуйста, добавьте ваш текущий вес" */}
            {/*  name="weight" */}
            {/* > */}
            {/*  <Input placeholder="Вес" /> */}
            {/* </Form.Item> */}

            {/* <Form.Item */}
            {/*  label="Если вы согласны, пожалуйста, добавьте ваш текущий рост" */}
            {/*  name="height" */}
            {/* > */}
            {/*  <Input placeholder="Рост" /> */}
            {/* </Form.Item> */}
            <Form.Item
              label={t("phoneNumberRequared")}
              name="phoneNumber"
              rules={[
                // { required: true, message: "Пожалуйста, введите номер телефона!" },
                {
                  pattern: /^[0-9]+$/,
                  message: "Номер телефона должен содержать только цифры!",
                },
              ]}
            >
              {userStore.user?.phoneNumber ? (
                <Input />
              ) : (
                <PhoneInput
                  country="kg"
                  value={form.getFieldValue("phone")}
                  onChange={(phone) => form.setFieldsValue({ phone })}
                  onlyCountries={[
                    "kg",
                    "kz",
                    "ru",
                    "uz",
                    "us",
                    "ca",
                    "ae",
                    "de",
                    "gb",
                    "fr",
                  ]}
                  placeholder="Введите ваш номер телефона"
                />
              )}
            </Form.Item>

            <Form.Item label={t("choseLang")}>
              <Language />
            </Form.Item>

            <Form.Item label="Email" name="email">
              <CustomInput text={userStore.user?.email} noNav />
            </Form.Item>
            {/* <Form.Item */}
            {/*  label="Пожалуйста, укажите ваш username." */}
            {/*  name="userName" */}
            {/*  rules={[{ required: true, message: "Пожалуйста, укажите цель!" }]} */}
            {/* > */}
            {/*  <Input placeholder={"Имя пользователя"} /> */}
            {/* </Form.Item> */}
          </div>
        </div>
        <div className="flex alignC justify-s wrap gap-20 ">
          {/* <div className="flex justify-s gap-10"> */}
          {/*  <Button */}
          {/*    danger */}
          {/*    onClick={() => showConfirmModal("деактивировать аккаунт")} */}
          {/*  > */}
          {/*    Деактивировать аккаунт */}
          {/*  </Button> */}
          {/*  <Button */}
          {/*    danger */}
          {/*    onClick={() => showConfirmModal("удалить аккаунт")} */}
          {/*  > */}
          {/*    Удалить аккаунт */}
          {/*  </Button> */}
          {/* </div> */}
          <Form.Item>
            <Button type="primary" htmlType="submit" className="button">
              {t("save")}
            </Button>
          </Form.Item>
        </div>

      </Form>
    </div>
  );
});

export default ProfileClient;
