import React from "react";
import Names from "../../../components/names";
import Tab from "../../../components/tab";
import "./style.scss";
import Transactions from "../../../components/transactions ";

function Payment() {
  return (
    <div className="container_mobile back_ground pay">
      <Names title="Назад" name="Платежи" />

      <Tab
        name="Расходы"
        subName="Доход"
        content={<Transactions />}
        subContent={<div className="name pt-20">У вас пока нет дохода</div>}
      />
    </div>
  );
}

export default Payment;
