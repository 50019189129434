import React from "react";
import { Button } from "antd";
import Names from "../../../components/names";

function Offer() {
  return (
    <div className="container_landing pt-20 privacy_policy">
      <Names title="Назад" name="Договор оферты/публичный договор" />
      <div className="box">
        <div className="content">
          <div className="text">
            ИП “Лю-Мин-Чин Жылдыз” именуемое в дальнейшем «Исполнитель», в лице
            Генерального директора Лю-Мин-Чин Жылдыз, действующего на основании свидетельства о регистрации ИП, заключает настоящий Публичный договор возмездного оказания услуг (далее по тексту – Договор) с любым физическим лицом, именуемым в дальнейшем «Заказчик».
          </div>
          <div className="text">
            Договор является соглашением о предоставлении на возмездной основе сервис для ведения онлайн тренировок Исполнителя посредством сайта www.move-mates.com, заключаемым путем публичной оферты, и регламентирует порядок оказания таких услуг и обязательства Сторон.
          </div>
          <div className="text">
            Текст настоящего Договора размещается Исполнителем в сети Интернет на веб-сайте по адресу: www.move-mates.com
          </div>

          <div className="text">
            Стороны, руководствуясь частью 2 статьи 398 Гражданского кодекса Кыргызской Республики заключили настоящий Договор о нижеследующем:
          </div>

          <div className="title">Предмет договора</div>

          <div className="text">
            В соответствии с настоящим Договором Исполнитель обязуется предоставить сервис для ведения онлайн тренировок, а Заказчик обязуется оплатить Исполнителю вознаграждение.
          </div>
          <div className="text">
            Cервис для ведения онлайн тренировок предоставляется Исполнителем посредством сайта www.move-mates.com.
          </div>
          <div className="title">
            В настоящем Договоре используются следующие термины и сокращения:
          </div>
          <div className="text">
            Сайт – веб-сайт, размещенный в сети Интернет по адресу www.move-mates.com.
          </div>
          <div className="text">
            Личный кабинет – совокупность защищенных страниц Сайта, созданных в результате регистрации Заказчика и доступных при вводе его аутентификационных данных (адреса электронной почты и пароля) в предусмотренные для этого поля на Сайте.
          </div>
          <div className="text">
            Счет – счет, счет-фактура, счет-протокол согласования цены, иной аналогичный документ, которым определяется стоимость услуг, а при необходимости – порядок оплаты.
          </div>
          <div className="title">
            Все термины и определения, используемые в настоящем Договоре, относятся в одинаковой степени к терминам в единственном и множественном числе, употребляемом в зависимости от контекста.
          </div>
          <div className="text">
            Заказчик может воспользоваться сервисом для ведения онлайн тренировок в соответствии с условиями Договора в течение всего периода, указанного на Сайте.
            {" "}
          </div>
          <div className="text">
            Исполнитель в рамках настоящего Договора не оказывает Заказчику индивидуальные консультации, не связанные с предметом договора.
          </div>
        </div>
        <div className="content">
          <div className="title">
            Порядок заключения Договора и предоставления доступа
          </div>
          <div className="text">
            Настоящий Договор является публичным договором, в соответствии с которым Исполнитель
          </div>
          <div className="title">
            принимает на себя обязательство по оказанию услуг в отношении неопределенного круга лиц (Заказчиков), обратившихся за их оказанием.
          </div>
          <div className="text">
            Размещение текста настоящего Договора на Сайте является публичным предложением Исполнителя, адресованным неопределенному кругу лиц заключить настоящий Договор (п. 1 ст. 386, п. 2 ст. 398 Гражданского кодекса Кыргызской Республики).
          </div>
          <div className="text">
            Заключение настоящего Договора производится путем присоединения Заказчика к настоящему Договору, т.е. посредством принятия (акцепта) Заказчиком условий настоящего Договора в целом, без каких-либо условий, изъятий и оговорок (ст. 387 Гражданского кодекса Кыргызской Республики).
          </div>
          <div className="text">
            Фактом принятия (акцепта) Заказчиком условий настоящего Договора является оплата Заказчиком заказанных им услуг в порядке и на условиях, определенных настоящим Договором. В случае оплаты Заказчиком заказанных им услуг с использованием кредитных средств, предоставленных соответствующим банком, фактом принятия (акцепта) Заказчиком условий настоящего Договора является заключение Заказчиком соответствующего договора с банком для оплаты услуг Исполнителя (п. 3 ст. 399 Гражданского кодекса Кыргызской Республики).
          </div>
          <div className="text">
            Осуществляя акцепт настоящей публичной оферты в порядке, определенном в настоящем Договоре, Заказчик соглашается со всеми условиями Договора в том виде, в каком они изложены в тексте настоящего Договора и в соответствии с Гражданским кодексом Кыргызской Республики рассматривается как лицо, вступившее с Исполнителем в договорные отношения на основании настоящего Договора. При этом в соответствии с п. 2 и 3 ст. 395, п. 3 ст. 399 Гражданского кодекса Кыргызской Республики настоящий Договор признается заключенным Сторонами в письменной форме.
            {" "}
          </div>
          <div className="text">
            После выполнения действий, указанных в п. 2.4. Договора, Исполнитель предоставляет Заказчику сервис для ведения онлайн тренировок.
            {" "}
          </div>
          <div className="title">
            Права и обязанности Сторон
          </div>
          <div className="text">
            Исполнитель обязуется:
            {" "}
          </div>
          <div className="text">
            Предоставить Заказчику сервис для ведения онлайн тренировок.
            {" "}
          </div>
          <div className="text">
            Осуществлять информационную поддержку Заказчика по вопросам оказания услуг и/или функционирования Сайта по рабочим дням с 09:00 до 18:00 (до 17:00 в пятницу и рабочие дни, являющиеся сокращенными в соответствии с законодательством Кыргызской Республики).
          </div>
          <div className="title">
            Вопросы по поддержке направляются Заказчиком по электронному адресу: movematesapp@gmail.com
            {" "}
          </div>
          <div className="text">
            В случае обнаружения ошибок/недоработок в функционировании Сайта, допущенных Исполнителем либо по вине Исполнителя, своими силами и за свой счет устранить обнаруженные ошибки/недоработки.
          </div>
          <div className="text">
            Производить возврат денежных средств, уплаченных Заказчиком, в случаях и на условиях, предусмотренных Договором.
          </div>
          <div className="title">
            Исполнитель вправе:
          </div>
          <div className="text">
            Вносить изменения в сервис для ведения онлайн тренировок www.move-mates.com
          </div>
          <div className="text">
            Привлекать третьих лиц для исполнения настоящего Договора без согласования с Заказчиком.
            {" "}
          </div>
          <div className="text">
            Запрашивать у Заказчика информацию и документы, необходимые для надлежащего исполнения  обязательств по настоящему Договору.
          </div>
          <div className="text">
            Изменять стоимость услуг по Договору с размещением актуальной стоимости на Сайте.
            {" "}
          </div>
          <div className="text">
            Временно приостанавливать работу Сайта для проведения необходимых плановых профилактических и ремонтных работ на технических ресурсах Исполнителя.
            {" "}
          </div>
          <div className="text">
            Производить замену третьих лиц, привлекаемых для исполнения обязательств по Договору.
          </div>
          <div className="title">Заказчик обязуется:</div>
          <div className="text">Оплатить услуги в сроки и на условиях, установленных Договором, согласованных в счетах и/или согласованных Сторонами посредством сообщений в Личном кабинете.</div>
          <div className="text">Своевременно предоставлять полную и достоверную информацию, необходимую для оказания услуг (в том числе при регистрации на Сайте).</div>
          <div className="text">Использовать функционал Сайта и доступ исключительно в личных,  или иных некоммерческих целях.</div>
          <div className="text">Обеспечить конфиденциальность логина и пароля к своему Личному кабинету, не передавать данные доступа третьим лицам.</div>
          <div className="text">Незамедлительно уведомить Исполнителя о фактах несанкционированного доступа к своему Личному кабинету.</div>
          <div className="text">Не размещать на Сайте или сторонних ресурсах персональные данные других лиц, включая данные доступа к их Личному кабинету на Сайте, а равно не использовать и не пытаться получить персональные данные других пользователей.</div>
          <div className="text">Не регистрироваться в качестве пользователя от имени или вместо другого лица или регистрировать группу (объединение) лиц или юридическое лицо/индивидуального предпринимателя в          качестве такого пользователя. </div>
          <div className="text">Не использовать программное обеспечение и не осуществлять действия, направленные на нарушение нормального функционирования Сайта, не загружать, не хранить, не публиковать, не распространять и не предоставлять доступ или иным образом использовать вирусы, троянские и другие вредоносные программы; не использовать без письменного разрешения Исполнителя автоматизированные скрипты (программы) для сбора информации на Сайте.</div>
          <div className="title">Заказчик вправе:</div>
          <div className="text">Получить доступ к сервису для ведения онлайн тренировок www.move-mates.com  после выполнения условий предоставления доступа (ст. 2 Договора).</div>
          <div className="text">Получать информационную поддержку по вопросам, связанным с порядком оказания услуг и функционированием Сайта, в течение всего срока действия настоящего Договора.</div>
          <div className="text">Использовать доступ к www.move-mates.com исключительно в некоммерческих целях.</div>
          <div className="text">Направлять Исполнителю замечания, предложения и/или отзывы, связанные с предметом настоящего Договора и/или функционированием либо Личного кабинета.</div>
          <div className="title">Стоимость услуг и порядок оплаты</div>
          <div className="text">Стоимость Услуг Исполнителя определяется на основании стоимости товара/услуги, указанной на Сайте  или согласованной в Счете, в сомах.</div>
          <div className="text">Заказчик производит оплату стоимости услуг Исполнителя на условиях 100% предоплаты единовременно путем перечисления денежных средств на расчетный счет Исполнителя, если иные условия не указаны в выставляемом Заказчику счете.</div>
          <div className="title">Датой оплаты является дата поступления денежных средств на расчетный счет Исполнителя.</div>
          <div className="text">Оплата Услуг   осуществляется   Заказчиком   в   пользу   Исполнителя   путем   перечисления</div>
          <div className="text">соответствующей суммы денежных средств способами, поддерживаемыми Исполнителем. При этом Исполнитель вправе самостоятельно определять перечень таких способов, доводя до Заказчика актуальный перечень способов путем размещения его Сайте, либо информируя Заказчика иным способом. Подробные правила оплаты и возврата денежных средств содержатся в Приложении № 1 к настоящему Договору.</div>
          <div className="text">Заказчик вправе оплатить Услуги Исполнителя с использованием кредитных средств, которые могут предоставляться Заказчику соответствующим банком (далее – кредит).</div>
          <div className="text">Стороны осознают и соглашаются, что настоящий Договор не является договором кредитования, Исполнитель не является кредитодателем и не несет перед Заказчиком соответствующих обязательств. Заказчик осознает и соглашается с тем, что кредит предоставляется Заказчику на условиях, предусмотренных соответствующим банком. Исполнитель не несет ответственность за условия предоставления банком Заказчику кредита, в том числе за размер и оплату процентов за пользование кредитом, а также за отказ банка в предоставлении кредита. Обязательства перед банком, связанные с предоставлением последним кредита, Заказчик исполняет самостоятельно.</div>
          <div className="text">Датой оказания Услуг/товара считается дата предоставления Заказчику услуги/товара, соответственно, Услуги/товар считаются оказанными Заказчику.</div>
          <div className="title">Ответственность Сторон. Ограничение ответственности</div>
          <div className="text">При нарушении Заказчиком условий Договора, обязан во внесудебном порядке по письменному требованию Исполнителя оплатить штраф в 100 сомов за каждое нарушение.</div>
          <div className="text">В случае нарушения Заказчиком условий настоящего Договора Исполнитель вправе деактивировать доступ Заказчика к Личному кабинету, а также заблокировать доступ Заказчика на Сайт без возврата уплаченных денежных средств.</div>
          <div className="text">Заказчик обязан уплатить пеню в течение 15 (пятнадцати) календарных дней с момента получения претензии (уведомления) Исполнителя.</div>
          <div className="title">Исполнитель не несет ответственности:</div>
          <div className="text">за содержание информации, размещаемой в чатах мессенджеров (WhatsApp, Viber, Telegram), которые создаются по инициативе пользователей, включая Заказчика;</div>
          <div className="text">за использование третьими лицами персональных данных, которые Заказчик сообщает в таких чатах;</div>
          <div className="text">за качество соединения с сетью Интернет и функционирование оборудования и программного обеспечения Заказчика;</div>
          <div className="text">за несоответствие технических характеристик оборудования Заказчика требованиям, предъявляемым для воспроизведения материалов Курса, если такие требования установлены;</div>
          <div className="text">за несоответствие Услуг, предусмотренных Договором, ожиданиям Заказчика. Субъективная оценка Заказчика не является объективным критерием качества Услуг;</div>
          <div className="text">за ошибки и неточности, допущенные Заказчиком при использовании функционала Сайта (включая, но не ограничиваясь, неверное указание данных при формировании заказа на услугу, др. подобные обстоятельства);</div>
          <div className="text">использования (невозможность использования) и какие бы то ни было последствия использования (невозможности использования) Заказчиком выбранной им формы оплаты услуг по Договору;</div>
          <div className="text">за действия (бездействие) банков или третьих лиц, участвующих в расчетах при оплате услуги или возврате денежных средств в установленных случаях.</div>
          <div className="text">Исполнитель прилагает все разумные усилия, предотвращающие сбои и неполадки в работе Сайта, однако не гарантирует его бесперебойную работу, не несет ответственности и не возмещает убытки, возникшие или возникновение которых возможно в связи с техническими сбоями и перебоями в работе провайдера, иных лиц или служб, ограничением доступа к сети Интернет или Интернет-ресурсу</div>
          <div className="title">по любым причинам, необеспечением Заказчиком сохранности данных доступа к своему Личному кабинету и другими обстоятельствами, находящимися вне компетенции Исполнителя.</div>
          <div className="text">Ни одна из Сторон не будет нести ответственности за полное или частичное неисполнение своих обязательств, если такое неисполнение будет являться следствием действия обстоятельств непреодолимой силы (включая, но не ограничиваясь, пожар, наводнение, землетрясение и другие стихийные бедствия, забастовки, война и военные действия, эпидемии, пандемии или другие обстоятельства, находящиеся вне контроля Сторон), препятствующих исполнению настоящего Договора и возникших после его заключения.</div>
          <div className="text">В случае если Заказчик не исполняет (ненадлежаще исполняет) свои обязательства по Договору, в том числе, обязательства, указанные в п. 4.2. Договора, Исполнитель вправе прекратить действие настоящего Договора путем одностороннего внесудебного отказа от его исполнения посредством направления уведомления о таком отказе в электронном виде на адрес электронной почты, на который ранее Исполнителем были высланы данные для доступа к Курсу. Действие настоящего Договора прекращается в день направления Исполнителем соответствующего уведомления или в срок, указанный в уведомлении, а доступ к материалам Курса деактивируется в тот же срок.</div>
          <div className="title">Интеллектуальная собственность. Конфиденциальность</div>
          <div className="text">В рамках оказания Исполнителем Услуг Заказчику предоставляется доступ к интеллектуальной собственности Исполнителя и/или третьих лиц, с которыми у Исполнителя заключены соответствующие договоры/соглашения (далее по тексту – Интеллектуальная собственность).</div>
          <div className="text">К Интеллектуальной собственности относятся результаты интеллектуальной деятельности Исполнителя и/или третьих лиц, включая, но не ограничиваясь, аудиовизуальные материалы, программы для ЭВМ, LMS (learning management system), методические и/или справочные материалы, задания, информационные ресурсы.</div>
          <div className="title">Заказчик применительно к Интеллектуальной собственности обязан:</div>
          <div className="text">воздерживаться от любых действий, которые нарушают права Исполнителя и/или третьих лиц на Интеллектуальную собственность, в частности, не копировать, не записывать, не воспроизводить, не тиражировать, не распространять в любой форме, не переделывать, не использовать для создания производных произведений любую Интеллектуальную собственность без письменного разрешения Исполнителя;</div>
          <div className="text">немедленно сообщать Исполнителю о любых ставших известными фактах нарушения прав интеллектуальной собственности Исполнителя и/или третьих лиц;</div>
          <div className="text">не предоставлять свои аутентификационные данные для доступа к учетной записи, в Личный кабинет на Сайте третьим лицам. В случае утраты, а также в случаях незаконного получения доступа к логину и паролю третьими лицами, Заказчик обязуется незамедлительно сообщить об этом Исполнителю, путем направления уведомления по адресу: movematesapp@gmail.com</div>
          <div className="title">До момента отправки указанного уведомления все действия считаются совершенными Заказчиком.</div>
          <div className="text">К конфиденциальной относится любая информация, относящаяся к процессу оказания услуг Исполнителем, неопубликованная Исполнителем в открытом доступе и не являющаяся доступной для всеобщего сведения. Заказчик обязуется не разглашать конфиденциальную информацию и иные данные, предоставленные Исполнителем в ходе оказания услуг (за исключением общедоступной информации), третьим лицам без предварительного письменного согласия Исполнителя.</div>
          <div className="text">Исполнитель имеет право использовать в своих маркетинговых и/или рекламных материалах объекты, созданные Заказчиком в ходе изучения материалов Курса, а также упоминать Заказчика в качестве пользователя Программного обеспечения и клиента.</div>
          <div className="title">Прочие условия</div>
          <div className="text">Заказчик подтверждает, что он является дееспособным и достиг возраста, необходимого в соответствии с законодательством Кыргызской Республики для совершения сделок с использованием Сайта.</div>
          <div className="text">Заказчик подтверждает, что осведомлен и безоговорочно согласен с тем, что ему может быть отказано в предоставлении Услуги в случаях:</div>
          <div className="text">неподтверждения авторизации оплаты Банком или электронной платежной системой;</div>
          <div className="text">инициации Заказчиком   процедуры   возврата   денежных   средств   в   случаях   и   порядка предусмотренном Договором;</div>
          <div className="text">в иных случаях, предусмотренных законодательством Кыргызской Республики или настоящим Договором.</div>
          <div className="title">Заключительные положения</div>
          <div className="text">Настоящий Договор считается заключенным с момента выполнения Заказчиком действий, предусмотренных п. 2.4. Договора, и действует до исполнения Сторонами своих обязательств.</div>
          <div className="text">Исполнитель вправе изменять условия настоящего Договора, вводить новые условия или приложения к настоящему Договору без предварительного уведомления Заказчика.</div>
          <div className="text">Заказчик, зная о возможности таких изменений, согласен с тем, что они будут производиться. Если Заказчик продолжает исполнять настоящий Договор, в том числе www.move-mates.com, Сайта после таких изменений, это означает его согласие с такими изменениями.</div>
          <div className="text">Текст изменений и/или дополнений настоящего Договора, либо его новая редакция доводится Исполнителем до всеобщего сведения посредством размещения соответствующей информации на Сайте.</div>
          <div className="text">Стороны безоговорочно соглашаются с тем, что молчание (отсутствие письменных уведомлений о несогласии с отдельными положениями настоящего Договора, в том числе с изменением тарифов) признается согласием и присоединением Заказчика к новой редакции настоящего Договора.</div>
          <div className="text">Настоящий Договор может быть расторгнут по соглашению сторон.</div>
          <div className="text">Каждая из Сторон имеет право отказаться от настоящего Договора в одностороннем порядке, направив другой Стороне уведомление посредством Личного кабинета или по электронной почте не позднее чем    за 15 (пятнадцать) календарных дней до планируемой даты прекращения.</div>
          <div className="text">В случае если какое-либо из условий настоящего Договора утратит юридическую силу, будет признано незаконным или будет исключено из настоящего Договора, это не влечет недействительность остальных условий настоящего Договора, которые сохранят юридическую силу и являются обязательными для Сторон.</div>
          <div className="text">Все вопросы, не урегулированные настоящим Договором, разрешаются в соответствии с действующим законодательством Кыргызской Республики.</div>
          <div className="text">Все споры, претензии и разногласия, которые могут возникнуть между Сторонами, будут разрешаться путем переговоров. При невозможности урегулирования споров указанным путем Стороны обращаются в суд в порядке, предусмотренном действующим законодательством Кыргызской Республики, с обязательным соблюдением досудебного порядка урегулирования спора.</div>
          <div className="text">
            Сторона, которая полагает, что ее права по Договору нарушены, обязана предъявить претензию второй Стороне. Претензия считается предъявленной надлежащим образом, если направлена другой Стороне любым способом, позволяющим установить личность отправителя и факт вручения корреспонденции получателю. Факт возврата корреспонденции с указанием на отсутствие получателя не препятствует обращению в суд. Приложение к претензии копий документов, обосновывающих и подтверждающих предъявленные требования, если данные документы имеются в наличии у второй Стороны, не является обязательным.
            Сторона, получившая претензию, в течение 10 (десяти) рабочих дней со дня ее получения письменно уведомляет вторую Сторону о результатах рассмотрения. Неполучение ответа либо мотивированных возражений на претензию в установленный срок рассматривается как согласие с претензией и изложенными в ней обстоятельствами.
          </div>
          <div className="text">Стороны признают юридическую силу документов, направленных посредством электронных средств связи либо Личного кабинета на Сайте.</div>
          <div className="text">Заказчик дает свое согласие Исполнителю на обработку персональных данных Заказчика, указанных им при обращении за услугами Исполнителя и/или при регистрации на Сайте, а также в Личном кабинете на условиях, предусмотренных Политикой обработки персональных данных, размещенной на Сайте.</div>
          <div className="text">Заказчик даёт свое согласие Исполнителю на использование Исполнителем отзывов Заказчика об Исполнителе и оказываемых им Услугах, размещенных Заказчиком в сети Интернет, в том числе оставленных Заказчиком в официальных группах Исполнителя в социальных сетях, без указания полного имени (фамилии, имени и отчества) Заказчика и без использования изображения Заказчика любым способом, включая, но не ограничиваясь, переработку, в том числе сокращение, изменение, не искажающее их смысл, в целях размещения таких отзывов на официальных сайтах Исполнителя, на страницах Исполнителя в социальных сетях, в рекламных и иных материалах Исполнителя, включения в тексты информационных рассылок клиентам Исполнителя. Настоящее согласие действует с даты заключения Договора. Настоящее согласие может быть отозвано Заказчиком в любой момент путём направления письменного заявления по адресу Исполнителя, указанному в Договоре.</div>
          <div className="text">Заказчик дает свое согласие Исполнителю на получение от Исполнителя сообщений рекламного характера, информационных рассылок о продуктах, услугах Исполнителя и его партнеров, о рекламных акциях, скидках и специальных предложениях, розыгрышах, конкурсах, опросах на адрес электронной почты Заказчика, по номеру телефона Заказчика, в том числе в мессенджерах, включая «Viber», «Telegram», «WhatsApp», посредством почтовой рассылки, SMS-сообщений, push-уведомлений, а также сообщение Заказчику такой информации в устном формате по телефону.</div>
          <div className="title">Реквизиты Сторон</div>
          <div className="text">Стороны соглашаются реквизитами Заказчика считать информацию, указанную им при регистрации на Сайте и/или оформлении заявки на предоставление доступа к Курсу, и/или оплате услуг Исполнителя.</div>
          <div className="title">Реквизиты Исполнителя:</div>
          <div className="text">
            ИП “Лю-Мин-Чин Жылдыз”
            ИНН 10609198300672
            г. Бишкек, ул. Токтогула 106, 720001
            +996777255553
            ОАО “Оптима Банк”
            Бишкек, Киевская, д.104/1
            БИК филиала: 109018
            SWIFT: ENEJKG22
          </div>
          <div className="text">
            Приложение № 1
            к Публичному договору возмездного оказания услуг
          </div>
          <div className="title">Правила оплаты и возврата денежных средств</div>
          <div className="text">Настоящие Правила оплаты и возврата денежных средств (далее - Правила) регулируют отношения между Исполнителем и Заказчиком, связанные с оплатой услуг по Договору и возвратом оплаченной стоимости услуг в случае досрочного расторжения Договора.</div>
          <div className="text">Окончательная стоимость Услуг формируется с учетом выбранного Заказчиком товара/услуги. Оплата Услуг осуществляется в безналичном порядке одним из способов, указанных на Сайте, по выбору Заказчика путем зачисления денежных средств на счет Исполнителя.</div>
          <div className="text">При оплате на Сайте или посредством Личного кабинета к оплате принимаются карты VISA, MasterCard, ЭЛКАРТ, а также электронные кошельки.</div>
          <div className="text">Перечисление платежей производится в соответствии с Правилами международных платежных систем Visa и MasterCard (далее – платежные системы) на принципах соблюдения конфиденциальности и безопасности совершения платежа с использованием современных методов проверки, шифрования и передачи данных по закрытым каналам связи. По вопросам правил и порядка осуществления оплаты посредством платежных систем Заказчик руководствуется правилами соответствующих платежных систем и обращается напрямую к операторам соответствующих платежных систем за разъяснениями и консультациями. Исполнитель настоящим оставляет за собой право оказывать информационную поддержку относительно осуществления оплаты Услуг с помощью платежных систем в пределах своих компетенций, однако ничто в настоящем приложении не обязывает его осуществлять такую информационную поддержку, отвечать за полноту предоставленной информации или нести иные последствия выбора способа оплаты услуг и/или платежной системы Заказчиком.</div>
          <div className="text">Для оплаты Услуг банковской картой Visa или MasterCard при выборе способа оплаты необходимо нажать кнопку «Оплата банковской картой» и для завершения платежа необходимо нажать  кнопку «Оплатить».</div>
          <div className="text">Оплата заказа осуществляется после перенаправления на защищенную платежную страницу оператора платежной системы и/или соответствующего банка для ввода реквизитов банковской карты. Соединение с платежной страницей и передача информации осуществляются в защищенном режиме с использованием технологии 3D Secure.</div>
          <div className="text">На странице для ввода данных банковской карты Заказчику необходимо ввести данные банковской карты: номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные пропечатаны на самой карте. Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты. Если банк Заказчика поддерживает технологию безопасного проведения интернет-платежей MasterCard SecureCode, Verified by Visa, для проведения платежа может потребоваться ввод специального пароля.</div>
          <div className="text">Реквизиты банковской карты Заказчика не хранятся на сервере Исполнителя или на Сайте, Исполнитель доступа к платежным реквизитам Заказчика не имеет.</div>
          <div className="text">
            Конфиденциальность сообщаемой Заказчиком информации, включая персональные данные и платежные реквизиты, обеспечивается оператором соответствующей платежной системы и/или банком            Заказчика.
            Введенная Заказчиком информация, включая платежные реквизиты, данные банковской карты не предоставляется третьим лицам за исключением случаев, предусмотренных законодательством Кыргызской Республики.
          </div>
          <div className="text">В проведении платежа может быть отказано банком в следующих случаях:</div>
          <div className="text">банковская карта не предназначена для совершения платежей через интернет; Заказчик может узнать об этом, обратившись в обслуживающий банк.</div>
          <div className="text">недостаточно средств для оплаты на банковской карте.</div>
          <div className="text">Подробнее о наличии средств на банковской карте Заказчик может узнать, обратившись в банк, выпустивший банковскую карту;</div>
          <div className="text">данные банковской карты введены неверно;</div>
          <div className="text">истек срок действия банковской карты.</div>
          <div className="text">Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Заказчик может узнать, обратившись в банк, выпустивший банковскую карту.</div>
          <div className="text">Исполнитель не контролирует программно-аппаратный комплекс платежной системы и/или банка. Если в результате каких-либо ошибок произошло списание денежных средств Заказчика, но платеж не был авторизован платежной системой и/или банком, обязанности по возврату денежных средств Заказчику возлагаются на оператора платежной системы и/или соответствующий банк.</div>
          <div className="text">Возврат уплаченных Заказчиком денежных средств может быть осуществлен в случаях:</div>
          <div className="text">Услуга/товар не оказана по вине Исполнителя в установленный срок;</div>
          <div className="text">Заказчик до начала оказания услуги обратился с уведомлением о расторжении Договора и возврате денежных средств;</div>
          <div className="text">в случаях, указанных в п. 14 настоящего Приложения.</div>
          <div className="text">
            Полный или частичный возврат уплаченных Заказчиком денежных средств производится Исполнителем на основании письменного заявления Заказчика, направленного на электронную почту Исполнителя __________________________________ с последующим направлением оригинала на почтовый адрес Исполнителя: Кыргызская Республика, _________________________________________.
            Исполнитель вправе потребовать от Заказчика заполнения утвержденной формы заявления о возврате денежных средств.
          </div>
          <div className="text">В случае если Заказчик отказывается от Договора до предоставления Исполнителем товара/услуги, за исключением внесения оплаты на условиях оплаты с использованием кредитных средств, Исполнитель возвращает Заказчику 100% уплаченной суммы.</div>
          <div className="text">В случае если Заказчик отказывается от Договора до предоставления Исполнителем товара/услуги, оплаченному с использованием кредитных средств, Исполнитель производит возврат денежных средств в адрес соответствующего банка, за вычетом сумм комиссий, удержанных банком с Исполнителя. Исполнитель не несет ответственности за оплату и не возмещает Заказчику сумму начисленных процентов за пользование денежными средствами, подлежащих оплате Заказчиком банку в соответствии с заключенным Заказчиком с банком договором.</div>
          <div className="text">В случае если Заказчик отказывается от Договора в течение 30 (тридцати) календарных дней с даты предоставления Исполнителем товара/услуги, Исполнитель возвращает Заказчику 50% от уплаченной суммы. При этом если товар/услуга оплачен с использованием кредитных средств, Исполнитель производит возврат денежных средств в адрес соответствующего банка, за вычетом сумм комиссий, удержанных банком с Исполнителя. Исполнитель не несет ответственности за оплату и не возмещает Заказчику сумму начисленных процентов за пользование денежными средствами, подлежащих оплате Заказчиком банку в соответствии с заключенным Заказчиком с банком договором.</div>
          <div className="text">В целях однозначного толкования: при отказе Заказчика от услуг, оплаченных с использованием кредитных средств (банковских продуктов – потребительских кредитов, рассрочек и пр.), сумма к возврату и расходы Исполнителя рассчитываются от суммы денежных средств, полученных Исполнителем от банка в счет оплаты услуг, оказываемых Заказчику.</div>
          <div className="text">В случае если Заказчик отказывается от Договора по истечении 30 (тридцати) календарных дней с даты предоставления Исполнителем товара/услуги, возврат денежных средств не осуществляется.</div>
          <div className="text">
            Возврат денежных средств производится на счет, с которого была совершена оплата, в срок до
            30 дней с даты получения Заявления Заказчика (конкретный срок возврата зависит от банка, эмитировавшего банковскую карту Заказчика).
          </div>
          <div className="text">При изменении платежных реквизитов Заказчика, а равно при оплате услуг банковской организацией с привлечением кредитных средств, Заказчик обязуется в Заявлении указать банковские реквизиты для возврата денежных средств.</div>
          <div className="text">При неуказании банковских реквизитов Заказчиком Исполнитель не несет ответственности за некорректное и/или несвоевременное перечисление и/или неперечисление денежных средств по платежным реквизитам Заказчика, указанным последним при оплате услуг.</div>
        
        </div>
      </div>
      <a href="/pdf/offer.docx" download="Privacy_Policy.pdf">
        <Button className="button_done">Скачать Условия использования</Button>
      </a>
    </div>
  );
}

export default Offer;
