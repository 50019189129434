import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
import { getCoachesTimeAvailable, getWorkTimeAvailable } from "../api/index";
import { BASE_TIME_ZONE } from "../helpers/api/index";
import { IRootStore } from "./rootStore.ts";

export class AvailableSlotsStore {
  rootStore: IRootStore;
  
  usersLoading = false;

  date: string | null = null;

  time: any[] = [];

  booked: any[] = [];

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setDate = (date: moment.Moment) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    runInAction(() => {
      this.date = formattedDate;
    });
  };

  fetchTime = async () => {
    const { authStore, userStore } = this.rootStore;

    if (userStore.role === "client") {
      try {
        if (authStore?.token) {
          runInAction(() => {
            this.usersLoading = true;
          });

          const filter = {
            date: this.date,
            timezone: BASE_TIME_ZONE,
          };

          const response = await getCoachesTimeAvailable(
            filter,
            this.rootStore.clientIndividualAppointmentsStore.id,
          );
          runInAction(() => {
            if (filter) {
              this.time = response?.worktimes || [];
              this.booked = response?.booked || [];
            }
          });
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      } finally {
        runInAction(() => {
          this.usersLoading = false;
        });
      }
    } else if (userStore.role === "coach") {
      try {
        if (authStore?.token) {
          runInAction(() => {
            this.usersLoading = true;
          });

          const filter = {
            date: this.date,
            timezone: BASE_TIME_ZONE,
          };

          const response = await getWorkTimeAvailable(filter);
          runInAction(() => {
            if (filter) {
              this.time = response?.worktimes || [];
              this.booked = response?.booked || [];
            }
          });
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      } finally {
        runInAction(() => {
          this.usersLoading = false;
        });
      }
    }
  };
}
