import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment-timezone";
import { createGroupAppointments } from "../api/index";

import { BASE_TIME_ZONE } from "../helpers/api/index";

import { Rule } from "./clientIndividualAppointmentsStore.ts";
import { RootStore } from "./rootStore.ts";

export class GroupAppointmentsStore {
  rootStore: RootStore;
  speciality: string | null = null;
  isOffline: boolean;
  rule: Rule;
  locationName: string | null;
  locationAddress: string | null;
  startOfAppointment: string | null;
  endOfAppointment: string | null;
  clientIds: number[];
  description: string | null;
  duration: number;
  time: string;
  date: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.speciality = null;
    this.isOffline = false;
    this.rule = "IN_ONE_DAY";
    this.locationName = null;
    this.locationAddress = null;
    this.startOfAppointment = null;
    this.endOfAppointment = null;
    this.clientIds = [];
    this.description = null;
    this.duration = 0;
    this.time = "";
    this.date = "";

    makeAutoObservable(this);
  }

  setSpeciality(speciality: string) {
    runInAction(() => {
      this.speciality = speciality;
    });
  }

  setClientId(id: number) {
    runInAction(() => {
      this.clientIds.push(id);
    });
  }

  setDuration(duration: number) {
    runInAction(() => {
      this.duration = duration;
    });
  }

  setTime(time: string) {
    runInAction(() => {
      this.time = time;
    });
  }

  setDate(date: string) {
    runInAction(() => {
      this.date = date;
    });
  }

  setOffline(offline: boolean) {
    runInAction(() => {
      this.isOffline = offline;
    });
  }

  combineDateTime() {
    const dateMoment = moment(this.date, "D MMMM");
    const [startTime, endTime] = this?.time?.split("-") || [];
    const startDateTime = moment.tz(
      {
        year: dateMoment.year(),
        month: dateMoment.month(),
        day: dateMoment.date(),
        hour: moment(startTime, "HH:mm").hour(),
        minute: moment(startTime, "HH:mm").minute(),
        second: 0,
      },
      BASE_TIME_ZONE,
    );

    const endDateTime = moment.tz(
      {
        year: dateMoment.year(),
        month: dateMoment.month(),
        day: dateMoment.date(),
        hour: moment(endTime, "HH:mm").hour(),
        minute: moment(endTime, "HH:mm").minute(),
        second: 0,
      },
      BASE_TIME_ZONE,
    );

    runInAction(() => {
      this.startOfAppointment = startDateTime.format();
      this.endOfAppointment = endDateTime.format();
    });
  }

  async fetchGroupAppointments() {
    try {
      this.combineDateTime();

      const filter = {
        speciality: this.speciality,
        isOffline: this.isOffline,
        rule: "NONE",
        locationName: null,
        locationAddress: null,
        startOfAppointment: this.startOfAppointment,
        endOfAppointment: this.endOfAppointment,
        clientIds: this.clientIds,
        description: null,
      };
      await createGroupAppointments(filter);
    } catch (error) {
      console.error("Failed to fetch user:", error);
    }
  }
}
