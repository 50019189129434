import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Form, Input, Button, Spin,
} from "antd";
import { isSafari } from "react-device-detect";
import { observer } from "mobx-react";
import Names from "../../components/names";
import onMessageListener from "../../helpers/sdk/sdk";
import { RootStoreContext } from "../../store/rootStore.ts";
import AvatarAny from "../../components/avatar-any";
import "./style.scss";

const ChatComponent = observer(() => {
  const [form] = Form.useForm();
  const { chatDetailsStore } = useContext(RootStoreContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const userFromLocalStorage = localStorage.getItem("user");
  const userObject = JSON.parse(userFromLocalStorage);
  const localUserId = userObject?.id;
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setIsLoading(true);
    chatDetailsStore.fetchChat().finally(() => {
      setIsLoading(false);
    });

    if (isSafari) {
      const interval = setInterval(() => {
        chatDetailsStore.fetchChat();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [chatDetailsStore]);

  const sendMessage = (values) => {
    chatDetailsStore.postChat(values?.content, localUserId).then(() => {
      chatDetailsStore.fetchChat();
    });
    form.resetFields();
  };

  useEffect(() => {
    const handleMessage = () => {
      chatDetailsStore.fetchChat();
    };
    onMessageListener()
      .then(handleMessage)
      .catch((err) => console.log("failed: ", err));
  }, [chatDetailsStore]);

  if (!isSafari) {
    onMessageListener()
      .then((payload) => {
        if (payload) {
          setTimeout(() => {
            chatDetailsStore.fetchChat();
          }, 500);
        }
      });
  }
  console.log("chatDetailsStore", chatDetailsStore);
  return (
    <div className="chat_details">
      {isLoading ? (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      ) : (
        <div className="username-page container_mobile">
          <div className="header_chat">
            <Names title={t("back")} />
            <div className="flex flex-column alignC gap-5">
              <AvatarAny url={chatDetailsStore.img} />
              <div>{chatDetailsStore.name ? chatDetailsStore.name : "Имя клиента"}</div>
            </div>
            <div />
          </div>

          <div>
            <div className="content">
              {chatDetailsStore?.chat?.map((item) => {
                if (!item.content) return null;
                if (item.userId === localUserId) {
                  return (
                    <div className="chat_text" key={item.messageDate}>
                      {item.content}
                    </div>
                  );
                }
                return (
                  <div className="chat_response" key={item.messageDate}>
                    {item.content}
                  </div>
                );
              })}
            </div>
            <div className="chat_footer">
              <Form form={form} onFinish={sendMessage}>
                <Form.Item
                  name="content"
                  rules={[{ required: true, message: "Please type a message!" }]}
                >
                  <Input placeholder="Type a message..." />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="button_done"
                  >
                    {t("send")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default ChatComponent;
