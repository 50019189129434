import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Button, Divider } from "antd";
import Icon from "../icon";
import "./style.scss";

function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isModalVisiblePayment, setIsModalVisiblePayment] = useState(false);

  useEffect(() => {
    if (location.pathname === "/main/confirmed") {
      setIsModalVisiblePayment(true);
    }
  }, [location]);

  const handleNav = () => {
    setIsModalVisiblePayment(false);
    navigate("/main");
  };

  return (
    <Modal
      open={isModalVisiblePayment}
      onOk={handleNav}
      onCancel={handleNav}
      footer={null}
    >
      <div className="payment_success">
        <Icon name="payment_calendar" />
        <div className="title">
          {t("modalPaymentConfirmed")}
        </div>
        <div className="text">{t("modalPaymentConfirmedText")}</div>
        <Divider />
        <div className="flex flex-column alignS justify-c">
          <div className="flex gap-10 alignC">
            <div>Тренер</div>
            <div>Айгуль Чалова</div>
          </div>
          <div className="flex gap-10 alignC justify-fs">
            <div>Кол-во</div>
            <div>5</div>
          </div>
          <div className="flex gap-10 alignC justify-fs">
            <div>Формат</div>
            <div>Оффлайн</div>
          </div>
          <div>
            <div className="flex gap-10 alignC justify-fs">
              Адрес
            </div>
            <div>Атлант, Тыныстанова 192, г. Бишкек</div>
          </div>
          <div className="flex gap-10 alignC justify-fs">
            <div>Сумма</div>
            <div>5000 сом</div>
          </div>
        </div>
        <Divider />
        <Button className="button" type="primary" onClick={handleNav}>{t("mySessions")}</Button>
      </div>
    </Modal>
  );
}

export default PaymentSuccess;
