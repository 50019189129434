import React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AppStoreButton } from "react-mobile-app-button";
import "./style.scss";

function ButtonApple(props) {
  const { dark, className } = props;
  const url = "https://apps.apple.com/kg/app/move-mates/id6470747632";
  return (
    <AppStoreButton
      theme={dark ? "dark" : "light"}
      url={url}
      className={className}
    />
  );
}

export default ButtonApple;
