import React, { useContext, useEffect } from "react";
import { Button, Divider } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "../../store/rootStore.ts";
import "./style.scss";
import CustomInput from "../input";
import AvatarName from "../avatar-name";
import Icon from "../icon";

const SettingsComponent = observer(() => {
  const { authStore, userStore, product } = useContext(RootStoreContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    product.fetchRequestCreated();
  }, [product]);
  const logOut = () => {
    sessionStorage.removeItem("authToken");
    authStore.setToken(null);
    localStorage.clear();
    navigate("/dashboard");
  };

  const arr = [
    {
      name: t("editProfile"),
      link: "/settings/profile",
      id: 1,
    },
    // ...(userStore.role !== "client" ? [{
    //   name: t("subscription"),
    //   link: "/subscriptions",
    //   id: 2,
    // }] : []),
    {
      name: t("helpAndFeedback"),
      link: "/help",
      id: 3,
    },
  ];

  const handleTrain = () => {
    if (userStore.role === "coach") {
      navigate("/mentor/specialization/individual");
    } else {
      navigate("/request/client");
    }
  };
  const handleChat = () => {
    navigate("/chat");
  };
  const handleRequest = () => {
    navigate("/request");
  };
  return (
    <div className="back_ground settings_component">
      <AvatarName />
      <CustomInput
        text={userStore.role === "coach" ? t("changeSessionDetails") : t("mySessions")}
        name="edit_train"
        onClick={handleTrain}
      />
      <CustomInput
        text={t("chat")}
        name="chat"
        onClick={handleChat}
      />
      {userStore.role === "coach"
        && (
          <div className="input input_notification" onClick={handleRequest}>
            <div className="flex gap-10 alignC">
              <Icon name="notification" className="input_notification_icon" />
              <div className="title">
                {t("requests")}
              </div>
              { !!product.created?.length
                && (
                <div className="request_length">
                  {product.created?.length}
                </div>
                )}
            </div>
            <Icon name="arrow_rigth" />
          </div>
        )}
      <div className="content">
        <div className="flex flex-column gap-10">
          <div className="title_settings">{t("settings")}</div>
          {arr.map((item) => (
            <NavLink to={`${item.link}`} className="nav" key={item.id}>
              <CustomInput
                text={item.name}
              />
            </NavLink>
          ))}
        </div>
        <Button block className="title" type="primary" onClick={logOut}>{t("exit")}</Button>
      </div>
    </div>
  );
});

export default SettingsComponent;
