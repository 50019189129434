import { makeAutoObservable, runInAction } from "mobx";
import { getClientCoachDetail, getClientCoachSession } from "../api";
import { IRootStore } from "./rootStore.ts";

export class CoachStore {
  rootStore: IRootStore;
  coach: any[];
  isLoading: boolean;
  id: number | null;
  session: null;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.coach = [];
    this.isLoading = false;
    this.id = null;
    this.session = null;

    makeAutoObservable(this);
  }

  setId(id: number) {
    runInAction(() => {
      this.id = id;
    });
  }

  async fetchCoach() {
    const { authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.isLoading = true;
        });
      }

      const response = await getClientCoachDetail(this.id);
      runInAction(() => {
        if (response) {
          this.coach = response;
        }
      });
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async fetchCoachSession(filter: any) {
    const { authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.isLoading = true;
        });
      }

      const response = await getClientCoachSession(this.id, filter);
      runInAction(() => {
        if (response) {
          this.session = response;
        }
      });
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
