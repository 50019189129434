import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Names from "../../../components/names";
import { RootStoreContext } from "../../../store/rootStore.ts";
import "./style.scss";
import AvatarAny from "../../../components/avatar-any";

const RequestClient = observer(() => {
  const { product } = useContext(RootStoreContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    product.fetchProductClient();
  }, []);

  const handleNav = (id) => {
    navigate(`/request/client/${id}`);
    product.setId(id);
  };
  const sortItemsByStatus = (a, b) => {
    if (a.status === "CREATED" && b.status !== "CREATED") {
      return -1;
    }
    if (a.status !== "CREATED" && b.status === "CREATED") {
      return 1;
    }
    return 0;
  };
  // const formatAppointment = (appointment) => {
  //   const start = moment(appointment.startOfAppointment);
  //   const end = moment(appointment.endOfAppointment);
  //
  //   return {
  //     date: start.format("DD MMMM"),
  //     time: `${start.format("HH:mm")} - ${end.format("HH:mm")}`,
  //   };
  // };
  // Отсортированный массив
  const sortedContent = product.product?.content?.slice().sort(sortItemsByStatus) || [];
  return (
    <div className="request_client container_mobile pt-20">
      <Names name={t("mySessions")} />
      <div className="flow">
        {product.isLoading ? <Spin /> : sortedContent.map((item) => {
          let statusClass = "";
          let statusText = "";
          switch (item.status) {
            case "CREATED":
              statusText = t("awaitingConfirmation");
              statusClass = "status_created";
              break;
            case "CONFIRMED":
              statusText = t("confirmed");
              statusClass = "status_confirmed";
              break;
            case "DECLINED":
              statusText = t("rejected");
              statusClass = "status_declined";
              break;
            default:
              break;
          }
          return (
            <div key={item.id} className="card" onClick={() => handleNav(item.id)}>
              <div className={`title ${statusClass}`}>{statusText}</div>
              <div className="content">
                <AvatarAny url={item.coachAvatarUrl} />
                <div className="flex flex-column gap-5">
                  <div className="text">
                    {item.coachFirstName}
                    {" "}
                    {item.coachLastName}
                  </div>
                  <div className="text">{item.isOffline ? t("offline") : t("online")}</div>
                </div>
              </div>
            </div>
          );
        }) }
      </div>
    </div>
  );
});

export default RequestClient;
