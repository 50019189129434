import React, { useEffect, useRef } from "react";
import { Button } from "antd";
import LandingHeader from "../header";
import img from "../../../../../assets/imgs/main.png";
import img2 from "../../../../../assets/imgs/main2.png";
import img3 from "../../../../../assets/imgs/main3.png";
import img4 from "../../../../../assets/imgs/main4.png";
import img5 from "../../../../../assets/imgs/main5.png";
import img6 from "../../../../../assets/imgs/main6.png";
import "./style.scss";
import ButtonEmpty from "../../../../../components/buttons/button-empty";
import LandingScroll from "../../../../../components/landing-components/auto-scroll";
import LandingImg from "../../../../../components/landing-components/img";

function LandingMain(props) {
  const { coachHeader, price, comment, contact, main, info, opp } = props;
  const scrollRefDown = useRef(null);
  const scrollRefUp = useRef(null);
  const setupScrollUp = (ref) => {
    const element = ref.current;
    const imageHeight = 280;
    const numberOfImages = 6;
    const totalScrollHeight = imageHeight * numberOfImages;
    let currentPosition = totalScrollHeight; // Начало с максимального значения
    const scrollStep = 1;

    const scroll = () => {
      currentPosition -= scrollStep;
      if (currentPosition <= 0) {
        currentPosition = totalScrollHeight; // Возврат к максимальному значению
      }

      element.scrollTop = Math.abs(currentPosition);
    };

    const intervalId = setInterval(scroll, 25);

    return () => clearInterval(intervalId);
  };

  const setupScrollDown = (ref) => {
    const element = ref.current;
    const imageHeight = 280;
    const numberOfImages = 6;
    const totalScrollHeight = imageHeight * numberOfImages;
    let currentPosition = 0;
    const scrollStep = 1;

    const scroll = () => {
      currentPosition += scrollStep;
      if (currentPosition >= totalScrollHeight) {
        currentPosition = 0; // Сброс к началу
      }

      element.scrollTop = currentPosition;
    };

    const intervalId = setInterval(scroll, 25);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    const stopScrollUp = setupScrollUp(scrollRefUp); // Установка скроллинга вверх
    const stopScrollDown = setupScrollDown(scrollRefDown); // Установка скроллинга вниз

    return () => {
      stopScrollUp(); // Остановка скроллинга вверх
      stopScrollDown(); // Остановка скроллинга вниз
    };
  }, []);

  const handleNav = () => {
    window.open("https://apps.apple.com/kg/app/move-mates/id6470747632", "_blank");
  };
  const images = [
    { img, text: "Stretching", crown: false, rate: 4.7 },
    { img: img2, text: "Fitness", crown: false, rate: "5.0" },
    { img: img3, text: "Hot-Stretching", crown: false, rate: 4.9 },
    { img, text: "Stretching", crown: false, rate: 4.8 },
    { img: img2, text: "Fitness", crown: false, rate: "5.0" },
    { img: img3, text: "Hot-Stretching", crown: false, rate: 4.7 },
    { img, text: "Stretching", crown: false, rate: 4.8 },
    { img: img2, text: "Fitness", crown: false, rate: "5.0" },
    { img: img3, text: "Hot-Stretching", crown: false, rate: 4.7 },
  ];

  const images2 = [
    { img: img4, text: "CrossFit", crown: false, rate: 4.8 },
    { img: img5, text: "Nutrition", crown: false, rate: "5.0" },
    { img: img6, text: "Fitness", crown: false, rate: 4.9 },
    { img: img4, text: "CrossFit", crown: false, rate: 4.8 },
    { img: img5, text: "Nutrition", crown: false, rate: "5.0" },
    { img: img6, text: "Fitness", crown: false, rate: 4.9 },
    { img: img4, text: "CrossFit", crown: false, rate: 4.8 },
    { img: img5, text: "Nutrition", crown: false, rate: "5.0" },
    { img: img6, text: "Fitness", crown: false, rate: 4.9 },
  ];

  return (
    <div className="landing_main">
      <LandingHeader coach={coachHeader} />
      <div className="content container_landing">
        <div className="flex flex-column gap-20">
          <div>
            <div className="name">250+ on-demand world class trainers</div>
          </div>
          <div className="title">Find your perfect trainer and achieve your fitness goals</div>
          <div className="text">Book and meet over 250+ world class on-demand trainers for 1:1 training session based on your needs</div>
          <div className="flex gap-20 alignC">
            <Button type="primary" className="button width-u" onClick={handleNav}>Download the App</Button>
            <ButtonEmpty text="Learn more" size="l" />
          </div>
        </div>
        <div className="scroll_loop">
          <div className="scroll_container mr-30" ref={scrollRefDown}>
            {images.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key,react/jsx-key
              <LandingImg img={item.img} text={item.text} subText={item.subText} rate={item.rate} crown={item.crown} />
            ))}
          </div>
          <div className="scroll_container" ref={scrollRefUp}>
            {images2.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key,react/jsx-key
              <LandingImg img={item.img} text={item.text} subText={item.subText} rate={item.rate} crown={item.crown} />
            ))}
          </div>
        </div>
        {/* <div className="scroll_loop_mobile"> */}
        {/*  <img src={img2} alt="" className="img" /> */}
        {/*  <img src={img3} alt="" className="img" /> */}
        {/* </div> */}
      </div>
      <LandingScroll img={images} />
    </div>
  );
}

export default LandingMain;
