import { useContext, useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import moment from "moment";

import "moment/locale/ru";
import "react-calendar/dist/Calendar.css";
import "./style.scss";
import { observer } from "mobx-react";
import { RootStoreContext } from "../../store/rootStore.ts";

const MyCalendar = observer((props) => {
  const {
    availableSlotsStore,
    userStore,
    clientAppointmentsStore,
    appointmentsStore,
  } = useContext(RootStoreContext);
  const [date, setDate] = useState(new Date());
  const { onDateChange } = props;
  let trainings = [];

  useEffect(() => {
    if (userStore.role === "coach") {
      appointmentsStore?.fetchAppointments();
    } else {
      clientAppointmentsStore?.fetchAppointments();
    }
  }, [userStore, appointmentsStore, clientAppointmentsStore]);

  function tileContent({ date }) {
    if (userStore.role === "coach") {
      if (appointmentsStore?.appointments?.length) {
        trainings = appointmentsStore?.appointments?.map((item) => moment(item?.startOfAppointment).format("YYYY-MM-DD"));
        const formatDate = moment(date).format("YYYY-MM-DD");
        if (trainings?.includes(formatDate)) {
          return (
            <div className="dot">
              <p>•</p>
            </div>
          );
        }
      }
    } else if (userStore.role === "client") {
      if (clientAppointmentsStore?.appointments) {
        trainings = clientAppointmentsStore?.appointments?.map((item) => moment(item?.startOfAppointment).format("YYYY-MM-DD"));
        const formatDate = moment(date).format("YYYY-MM-DD");
        if (trainings?.includes(formatDate)) {
          return (
            <div className="dot">
              <p>•</p>
            </div>
          );
        }
      }
    }

    return "";
  }

  useEffect(() => {
    availableSlotsStore.setDate(date);
  }, [date, availableSlotsStore]);

  return (
    <div className="schedule">
      <Calendar
        startAccessor="start"
        endAccessor="end"
        date={date}
        locale="ru-RU"
        onChange={(newDate) => {
          availableSlotsStore.setDate(newDate);
          if (onDateChange) {
            onDateChange(newDate);
          }
          setDate(newDate);
        }}
        tileContent={userStore.role && tileContent}
      />
    </div>
  );
});

export default MyCalendar;
