import React, { useEffect, useRef, useState } from "react";
import img from "../../../../../assets/imgs/landing_info.png";
import imgDetail from "../../../../../assets/imgs/landing_info_detail.png";
import img2 from "../../../../../assets/imgs/landing_info2.png";
import imgDetail2 from "../../../../../assets/imgs/landing_info2_detail.png";
import img3 from "../../../../../assets/imgs/landing_info3.png";
import "./style.scss";
import Icon from "../../../../../components/icon";

function LandingInfo() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef(null); // Создаем ref для наблюдаемого элемента

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Применяем параллакс-эффект, когда элемент в поле зрения
            setScrollPosition(window.pageYOffset);
          }
        });
      },
      { threshold: 0.5 }, // Настройка, когда элемент считается в поле зрения
    );

    if (contentRef.current) {
      observer.observe(contentRef.current); // Начинаем наблюдение
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current); // Прекращаем наблюдение при размонтировании
      }
    };
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Вычисляем стиль для параллакс эффекта
  const parallaxStyle = (depth) => {
    const speed = depth * scrollPosition;
    return { transform: `translateY(${speed}px)` };
  };

  return (
    <div className="landing_info container_landing" ref={contentRef}>
      <div className="content">
        <div className="title">Find your perfect trainer</div>
        <div className="text">Tailored fitness solutions just for you! Reach your goals with personalized training sessions.</div>
        <div className="img_container">
          <img src={imgDetail} alt="" className="img_detail" style={parallaxStyle(0.1)} />
          <img src={img} alt="" className="img_info" />
        </div>
      </div>

      <div className="content">
        <div className="title">Train online or at your place</div>
        <div className="text">Top-notch trainers available worldwide, anytime, anywhere!</div>
        <div className="img_container">
          <img src={imgDetail2} alt="" className="img_detail2" style={parallaxStyle(0.1)} />
          <img src={img2} alt="" className="img_info" />
        </div>
      </div>

      <div className="content">
        <div className="title">Safe payment across the globe</div>
        <div className="text">Trustworthy transactions everywhere, ensuring payments can be made from any part of the world.</div>
        <div className="img_container">
          <Icon name="visa_logo" className="img_detail3" style={parallaxStyle(0.1)} />
          <Icon name="mastercard_logo" className="img_detail4" style={parallaxStyle(0.1)} />
          <Icon name="maestro_logo" className="img_detail5" style={parallaxStyle(0.1)} />
          <img src={img3} alt="" className="img_info" />
        </div>
      </div>
    </div>
  );
}

export default LandingInfo;
