import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Checkbox, Button, Input, notification, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import moment from "moment";
import Names from "../../../../components/names";
import { BASE_TIME_ZONE } from "../../../../helpers/api";
import { RootStoreContext } from "../../../../store/rootStore.ts";
import "./style.scss";

const TimetableWorkHours = observer(() => {
  const {
    individualAppointmentsStore,
  } = useContext(RootStoreContext);
  const { t } = useTranslation();
  const daysOfWeek = [t("mon"), t("tue"), t("wed"), t("thu"), t("fri"), t("sat"), t("sun")];
  const dayMapping = {
    [t("mon")]: "MONDAY",
    [t("tue")]: "TUESDAY",
    [t("wed")]: "WEDNESDAY",
    [t("thu")]: "THURSDAY",
    [t("fri")]: "FRIDAY",
    [t("sat")]: "SATURDAY",
    [t("sun")]: "SUNDAY",
  };
  const [form] = Form.useForm();
  const defaultTimeSlot = { start: "09:00", end: "17:00" };
  const navigate = useNavigate();
  const [selectedDays, setSelectedDays] = useState({});
  const [timeSlots, setTimeSlots] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const formatTime = (time) => (time.length === 8 ? time.substring(0, 5) : time);
  const checkIfAnyDaySelected = () => {
    const selected = Object.values(selectedDays).some((day) => day);
    setHasErrors(!selected);
  };

  useEffect(() => {
    checkIfAnyDaySelected();
  }, [selectedDays]);
  useEffect(() => {
    individualAppointmentsStore.getIndividualAppointments();
  }, []);

  useEffect(() => {
    const worktimes = individualAppointmentsStore.workingSchedule || individualAppointmentsStore.settings?.worktimes;
    const newSelectedDays = {};
    const newTimeSlots = {};
    const isScheduleSet = worktimes && worktimes.length > 0;
    if (!individualAppointmentsStore.isLoading && worktimes) {
      daysOfWeek.forEach((day) => {
        if (!isScheduleSet && (day !== t("sat") && day !== t("sun"))) {
          // Для пользователя, который заходит в первый раз, устанавливаем понедельник-пятницу с 9 до 17
          newSelectedDays[day] = true;
          newTimeSlots[day] = [{ start: "09:00", end: "17:00" }];
        } else {
          const dayInEnglish = dayMapping[day];
          const dayWorktimes = worktimes.filter((w) => w.dayOfWeek.toUpperCase() === dayInEnglish
            && !(w.start.slice(0, 5) === "11:11" && w.end.slice(0, 5) === "11:11"));
          if (dayWorktimes.length > 0) {
            newSelectedDays[day] = true;
            newTimeSlots[day] = dayWorktimes.map((wt) => ({
              start: formatTime(wt.start),
              end: formatTime(wt.end),
            }));
          } else {
            newSelectedDays[day] = false;
            newTimeSlots[day] = [];
          }
        }
      });
      setSelectedDays(newSelectedDays);
      setTimeSlots(newTimeSlots);

      const initialValues = {};
      daysOfWeek.forEach((day) => {
        initialValues[day] = { active: newSelectedDays[day] };
        if (newSelectedDays[day]) {
          initialValues[day].slots = newTimeSlots[day];
        }
      });

      form.setFieldsValue(initialValues);
    }
  }, [form, individualAppointmentsStore.isLoading]);

  const handleCheckboxChange = (day, checked) => {
    setSelectedDays({ ...selectedDays, [day]: checked });

    if (checked) {
      // Если день выбран, устанавливаем дефолтный временной слот
      setTimeSlots({ ...timeSlots, [day]: [defaultTimeSlot] });

      // Обновляем значения формы
      const newFormValues = form.getFieldsValue();
      newFormValues[day] = { active: true, slots: [defaultTimeSlot] };
      form.setFieldsValue(newFormValues);
    } else {
      // Если день не выбран, очищаем временные слоты для этого дня
      setTimeSlots({ ...timeSlots, [day]: [] });

      // Очищаем значения формы для этого дня
      const newFormValues = form.getFieldsValue();
      newFormValues[day] = { active: false, slots: [] };
      form.setFieldsValue(newFormValues);
    }
    const newSelectedDays = { ...selectedDays, [day]: checked };
    setSelectedDays(newSelectedDays);
    checkIfAnyDaySelected();
  };

  const handleAddTimeSlot = (day) => {
    const existingSlots = timeSlots[day];
    if (existingSlots.length === 0) {
      // Если нет существующих слотов, используем время по умолчанию
      setTimeSlots({ ...timeSlots, [day]: [defaultTimeSlot] });
      return;
    }
    // Находим конечное время последнего слота
    const lastSlot = existingSlots[existingSlots.length - 1];
    const endTime = lastSlot.end.split(":");
    const endHour = parseInt(endTime[0], 10);
    const endMinutes = parseInt(endTime[1], 10);
    let newStartHour = endHour + 1;
    const newEndHour = newStartHour + 1;
    const newStartMinutes = endMinutes;
    if (newStartHour > 23) {
      newStartHour = 0;
    }
    if (newStartHour >= 24 || newEndHour >= 24) {
      notification.error({
        message: t("error"),
        description: t("errorTime"),
        duration: 2.0,
      });
      return;
    }
    const newStartTime = `${newStartHour.toString().padStart(2, "0")}:${newStartMinutes.toString().padStart(2, "0")}`;
    const newEndTime = `${(newStartHour + 1).toString().padStart(2, "0")}:${newStartMinutes.toString().padStart(2, "0")}`; // Пример конечного времени
    const newSlot = { start: newStartTime, end: newEndTime };
    const updatedSlots = [...existingSlots, newSlot];
    setTimeSlots({ ...timeSlots, [day]: updatedSlots });

    const newFormValues = form.getFieldsValue();
    newFormValues[day].slots = updatedSlots;
    form.setFieldsValue(newFormValues);
  };

  const handleRemoveTimeSlot = (day, index) => {
    const updatedSlots = [...timeSlots[day]];
    updatedSlots.splice(index, 1); // Удаление слота по индексу
    setTimeSlots({ ...timeSlots, [day]: updatedSlots });

    // Обновляем значения формы
    const newFormValues = form.getFieldsValue();
    newFormValues[day].slots = updatedSlots;
    form.setFieldsValue(newFormValues);
  };

  const isTimeOverlap = (currentDay, currentIndex, startTime, endTime) => {
    const slots = form.getFieldValue(currentDay)?.slots || [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slots.length; i++) {
      if (i !== currentIndex) {
        const slot = slots[i];
        if (slot && isOverlapping({ start: startTime, end: endTime }, slot)) {
          return true;
        }
      }
    }
    return false;
  };

  const isOverlapping = (timeSlot1, timeSlot2) => {
    const start1 = timeSlot1.start.split(":");
    const end1 = timeSlot1.end.split(":");
    const start2 = timeSlot2.start.split(":");
    const end2 = timeSlot2.end.split(":");

    const startTime1 = new Date(0, 0, 0, start1[0], start1[1]);
    const endTime1 = new Date(0, 0, 0, end1[0], end1[1]);
    const startTime2 = new Date(0, 0, 0, start2[0], start2[1]);
    const endTime2 = new Date(0, 0, 0, end2[0], end2[1]);

    return (startTime1 < endTime2) && (startTime2 < endTime1);
  };

  const validateTimeSlots = (timeSlots) => {
    const errorMessages = [];

    daysOfWeek.forEach((day) => {
      const daySlots = timeSlots.filter((slot) => slot.dayOfWeek === dayMapping[day]);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < daySlots.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = i + 1; j < daySlots.length; j++) {
          if (isOverlapping(daySlots[i], daySlots[j])) {
            errorMessages.push(`Ошибка: Невозможное пересечение в ${day}, с ${daySlots[i].start} по ${daySlots[i].end} и с ${daySlots[j].start} по ${daySlots[j].end}`);
          }
        }
      }
    });

    return errorMessages.length > 0 ? errorMessages : null;
  };
  const validateTimeSlot = async (currentDay, currentIndex, startTime, endTime) => {
    if (isTimeOverlap(currentDay, currentIndex, startTime, endTime)) {
      setHasErrors(true);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(t("error"));
    }
    setHasErrors(false);
    return Promise.resolve();
  };
  const handlePressEnter = async (day, index, type, e) => {
    e.preventDefault();
    const formattedTime = formatTimeInput(e.target.value);
    const fieldPath = [day, "slots", index, type];

    // Установка значения поля
    form.setFieldsValue({ [day]: { slots: { [index]: { [type]: formattedTime } } } });

    try {
      // Запуск валидации для конкретного поля
      await form.validateFields([fieldPath]);

      // Если валидация прошла успешно, обновляем состояние слота
      const updatedSlots = { ...timeSlots };
      updatedSlots[day][index][type] = formattedTime;
      setTimeSlots(updatedSlots);

      setHasErrors(false);
    } catch (error) {
      // Обработка ошибки валидации
      setHasErrors(true);
    }
  };

  const showErrorNotification = (message) => {
    notification.error({
      message: t("error"),
      description: message,
      duration: 4.5,
    });
  };
  const formatTimeInput = (value) => {
    const numbers = value.replace(/[^0-9]/g, "").substring(0, 4);

    let hours; let
      minutes;
    if (numbers.length <= 2) {
      hours = parseInt(numbers, 10);
      minutes = 0;
    } else if (numbers.length === 3) {
      hours = parseInt(numbers.slice(0, 1), 10);
      minutes = parseInt(numbers.slice(1), 10);
    } else if (numbers.length === 4) {
      hours = parseInt(numbers.slice(0, 2), 10);
      minutes = parseInt(numbers.slice(2), 10);
    }

    if (hours > 23 || minutes > 59) {
      return t("error");
    }

    return numbers.length === 1
      ? `0${numbers}:00`
      : numbers.length === 2
        ? `${numbers}:00`
        : numbers.length === 3
          ? `0${numbers.slice(0, 1)}:${numbers.slice(1)}`
          : `${numbers.slice(0, 2)}:${numbers.slice(2)}`;
  };

  const handleSubmit = (values) => {
    const worktimeRequests = [];
    const allSlots = []; // Соберите все слоты из values
    daysOfWeek.forEach((day) => {
      if (values[day] && values[day].active) {
        const slots = values[day].slots || [];
        slots.forEach((slot) => {
          allSlots.push({ ...slot, dayOfWeek: dayMapping[day] });
        });
      }
    });
    const errorMessages = validateTimeSlots(allSlots);
    if (errorMessages && errorMessages.length > 0) {
      errorMessages.forEach((errorMessage) => showErrorNotification(errorMessage));
      return;
    }
    setFormErrors({});
    daysOfWeek.forEach((day) => {
      if (values[day] && values[day].active) {
        const slots = values[day].slots || [];
        slots.forEach((slot) => {
          if (slot.start && slot.end) {
            worktimeRequests.push({
              dayOfWeek: dayMapping[day],
              start: slot.start,
              end: slot.end,
              timeZone: BASE_TIME_ZONE,
              isBreak: false,
            });
          }
        });
      } else {
        worktimeRequests.push({
          dayOfWeek: dayMapping[day],
          start: "11:11",
          end: "11:11",
          timeZone: BASE_TIME_ZONE,
          isBreak: false,
        });
      }
    });
    navigate(-1);
    individualAppointmentsStore.setWorkingSchedule(worktimeRequests);
  };

  return (
    <Spin spinning={individualAppointmentsStore.isLoading}>
      <div className="container_mobile timetable_work_hours back_ground">
        <Names name={t("selectWorkinghours")} />
        <Form
          form={form}
          onFinish={handleSubmit}
          onChange={() => {
            const errors = form.getFieldsError();
            setHasErrors(errors.some((field) => field.errors.length > 0));
          }}
        >
          {daysOfWeek.map((day) => (
            <div key={day} className="day_section">
              <div className="flexRow">
                <div className="check_box">
                  <Form.Item
                    name={[day, "active"]}
                    valuePropName="checked"
                    initialValue={selectedDays[day]}
                  >
                    <Checkbox onChange={(e) => handleCheckboxChange(day, e.target.checked)}>
                      {day}
                    </Checkbox>
                  </Form.Item>
                </div>

                {selectedDays[day] && (
                <div className="flex justify-s">
                  <div className="flex flex-column gap-10">
                    {timeSlots[day].map((slot, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                      <div className={`content ${formErrors[day] ? "error" : ""}`} key={index}>
                        <div className="flexItem">
                          <Form.Item
                            name={[day, "slots", index, "start"]}
                            initialValue={slot.start}
                            rules={[
                              {
                                validator: async (_, value) => {
                                  if (!value || value.length !== 5 || !value.includes(":")) {
                                    return Promise.reject(new Error(t("error")));
                                  }
                                  const endTime = form.getFieldValue([day, "slots", index, "end"]);
                                  return validateTimeSlot(day, index, value, endTime);
                                },
                              },
                            ]}
                          >
                            <Input placeholder="Начало" onPressEnter={(e) => handlePressEnter(day, index, "start", e)} />
                          </Form.Item>

                        </div>
                        <div className="flexItem">
                          <Form.Item
                            name={[day, "slots", index, "end"]}
                            initialValue={slot.end}
                            rules={[
                              {
                                // eslint-disable-next-line consistent-return
                                validator: async (_, value) => {
                                  if (!value || value.length !== 5 || !value.includes(":")) {
                                    return Promise.reject(new Error(t("error")));
                                  }
                                  if (!value) {
                                    setHasErrors(true);
                                    return Promise.reject(new Error(t("error")));
                                  }
                                  const startTime = form.getFieldValue([day, "slots", index, "start"]);
                                  const startMoment = moment(startTime, "HH:mm");
                                  const endMoment = moment(value, "HH:mm");

                                  if (startMoment.isAfter(endMoment)) {
                                    setHasErrors(true);
                                    return Promise.reject(new Error(t("error")));
                                  }

                                  if (isTimeOverlap(day, index, startTime, value)) {
                                    setHasErrors(true);
                                    return Promise.reject(new Error(t("error")));
                                  }
                                  setHasErrors(false);
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              placeholder="Конец"
                              onPressEnter={(e) => handlePressEnter(day, index, "end", e)}
                            />
                          </Form.Item>

                          {index === 0 && (
                            <PlusOutlined onClick={() => handleAddTimeSlot(day)} />
                          )}
                          {timeSlots[day].length && index !== 0 && (
                          <MinusCircleOutlined onClick={() => handleRemoveTimeSlot(day, index)} />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                )}
              </div>
            </div>
          ))}
          <Button type="primary" htmlType="submit" className="button_done" disabled={hasErrors}>
            {t("confirm")}
          </Button>
        </Form>
      </div>
    </Spin>
  );
});

export default TimetableWorkHours;
