import React, {
  useEffect, useState, useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Select,
  Form,
  Button,
  Input,
  notification, Modal, Upload,
} from "antd";
import { observer } from "mobx-react";
import ImgCrop from "antd-img-crop";
import Names from "../../../components/names";
import { deactivateAccount, deleteAccount, editCoach } from "../../../api";
import CustomInput from "../../../components/input";
import { RootStoreContext } from "../../../store/rootStore.ts";
import ModalWrapper from "../../../components/modal-wrapper";
import TimetableWorkHours from "../../shared/schedule/timetable-work-hours";
import Specialization from "../../specialization";
import Language from "../../../components/language";
import "./style.scss";

const { Option } = Select;

const ProfileCoach = observer(() => {
  const {
    userStore,
    authStore,
    specializationsStore,
    notificationStore,
  } = useContext(RootStoreContext);
  const { t } = useTranslation();
  const [isFirstLessonFree, setIsFirstLessonFree] = useState(false);
  const [isOfflineSelected, setIsOfflineSelected] = useState(false);
  const [isModalVisibleWorkTime, setIsModalVisibleWorkTime] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [avatarFile, setAvatarFile] = useState(null);
  const [form] = Form.useForm();
  const formData = new FormData();
  const navigate = useNavigate();

  useEffect(() => {
    if (notificationStore.current) {
      notification.success({
        message: "Успех!",
        description: notificationStore.message,
        placement: "topRight",
        duration: 3,
      });
      notificationStore.setCurrent(false);
    }
  }, [notificationStore]);

  useEffect(() => {
    if (userStore?.user?.availableOffline) {
      setIsOfflineSelected(true);
      form.setFieldsValue({
        trainingType: ["offline"],
      });
    }
    setAvatarFile(userStore.user.avatarUrl);
  }, [form, userStore]);

  const onFinish = async (values) => {
    const filter = {
      firstName: values.firstName,
      lastName: values.lastName,
      duration: null,
      groupPrice: values.number,
      individualPrice: values.number,
      phoneNumber: values.phoneNumber,
      aboutMe: values.aboutMe,
      experience: values.experience,
      qualification: null,
      availableOffline: isOfflineSelected,
      isFirstLessonFree,
      offlineLocation: values.offlineLocation,
      offlineName: values.offlineName,
      supportedLanguages: values.supportedLanguages || [],
      specialities: specializationsStore.current || userStore.user.specialities,
    };
    const urlToBlob = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    };
    const initialAvatarUrl = userStore.user.avatarUrl;

    if (avatarFile && typeof avatarFile === "string" && avatarFile !== initialAvatarUrl) {
      // console.log("что сработало 1? Изображение было изменено и будет преобразовано в blob для отправки.");
      const blob = await urlToBlob(avatarFile);
      formData.append("avatar", blob);
    } else if (avatarFile !== initialAvatarUrl) {
      // console.log("что сработало 2? Выбрано новое изображение и оно добавляется в formData.");
      formData.append("avatar", avatarFile);
    } else {
      // console.log("Изображение не изменилось, отправка изображения не требуется.");
    }

    const jsonString = JSON.stringify(filter);
    const uint8array = new TextEncoder().encode(jsonString);
    const blob = new Blob([uint8array], { type: "application/json" });
    formData.append("body", blob);

    userStore.setLocation(values.offlineLocation, values.offlineName);
    try {
      const response = await editCoach(formData);
      if (response) {
        notification.success({
          description: t("profileSuccessfullyUpdated"),
          placement: "topRight",
        });

        userStore.fetchUser();
        navigate(-1);
      }
    } catch (error) {
      console.log("error");
      notification.error({
        message: t("error"),
        description: t("profileUpdateFailed"),
        placement: "topRight",
      });
    }
  };
  const logOut = () => {
    sessionStorage.removeItem("authToken");
    authStore.setToken(null);
    localStorage.clear();
  };

  const toggleModalWorkTime = () => {
    setIsModalVisibleWorkTime(!isModalVisibleWorkTime);
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const showConfirmModal = (action) => {
    Modal.confirm({
      title: "Подтвердите действие",
      content: `Вы уверены, что хотите ${action}? Ваши данные будут удалены.`,
      okText: "Да",
      okType: "danger",
      cancelText: "Нет",
      async onOk() {
        if (action === "деактивировать аккаунт") {
          const response = await deactivateAccount();
          console.log("account deactivate");
          logOut();
        } else {
          const response = await deleteAccount();
          logOut();
          console.log("account deleted");
        }
      },
    });
  };

  const initialValues = {
    firstName: userStore.user?.firstName,
    lastName: userStore.user?.lastName,
    phoneNumber: userStore.user?.phoneNumber,
    email: userStore.user?.email,
    number: userStore.user?.individualPrice,
    duration: userStore.user?.duration,
    experience: userStore.user?.experience,
    aboutMe: userStore.user?.aboutMe,
    offlineLocation: userStore?.user.offlineLocation,
    offlineName: userStore?.user?.offlineName,
    supportedLanguages: userStore.user?.supportedLanguages || [],
    trainingType: userStore?.user?.availableOffline ? ["offline"] : ["online"],
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setAvatarFile(e.target.result);
      setShowUploadButton(false); // Скрываем кнопку загрузки после выбора файла
    };
    reader.readAsDataURL(file);
    return false; // Важно вернуть false, чтобы предотвратить автоматическую загрузку
  };

  return (
    <div className="container_mobile pt-20 profile back_ground">
      <Names name={t("editProfile")} />

      <div className="flex flex-column  photo">
        {avatarFile ? (
          <div className="flex flex-column alignC">
            <img src={avatarFile} alt="Profile Avatar" className="img" />
            <ImgCrop rotationSlider>
              <Upload showUploadList={false} beforeUpload={beforeUpload}>
                <div className="name">{t("changePhoto")}</div>
              </Upload>
            </ImgCrop>
          </div>
        ) : (
          <ImgCrop rotationSlider>
            <Upload listType="picture-card" showUploadList={false} beforeUpload={beforeUpload}>
              <div className="name">{t("changePhoto")}</div>
            </Upload>
          </ImgCrop>
        )}
      </div>

      <Form
        form={form}
        name="basic"
        initialValues={initialValues}
        onFinish={onFinish}
        layout="vertical"
      >
        <div className="column">
          <div>
            <div className="user_name">
              <Form.Item
                label={t("firstName")}
                name="firstName"
                rules={[
                  { required: true, message: t("nameRule") },
                ]}
              >
                <Input placeholder={t("firstName")} />
              </Form.Item>

              <Form.Item
                name="lastName"
                label={t("lastName")}
                rules={[
                  { required: true, message: t("lastNameRule") },
                ]}
              >
                <Input placeholder={t("lastName")} />
              </Form.Item>

              <Form.Item
                name="aboutMe"
                label={t("specializationRequared")}
                rules={[
                  { required: true, message: t("specializationRequaredRule") },
                ]}
              >
                <Input placeholder={t("specializationRequaredRule")} />
              </Form.Item>
            </div>

            {/* {width < 768 ? ( */}
            {/*  <Form.Item label="Пожалуйста, добавьте рабочие часы" name="duration"> */}
            {/*    <div className="timetable_card" onClick={handleNavTime}> */}
            {/*      <div className="name"> Рабочие часы </div> */}
            {/*      <Icon name="arrow_rigth" /> */}
            {/*    </div> */}
            {/*  </Form.Item> */}
            {/* ) : ( */}
            {/*  <Form.Item label="Пожалуйста, добавьте рабочие часы" name="duration"> */}
            {/*    <div className="timetable_card" onClick={toggleModalWorkTime}> */}
            {/*      <div className="name"> Рабочие часы </div> */}
            {/*      <Icon name="arrow_rigth" /> */}
            {/*    </div> */}
            {/*  </Form.Item> */}
            {/* ) } */}

            {/* {width < 768 ? ( */}
            {/*  <Form.Item */}
            {/*    label="Пожалуйста, добавьте специализацию" */}
            {/*    name="additionalInput2" */}
            {/*  > */}
            {/*    <div className="timetable_card" onClick={handleSpecialization}> */}
            {/*      <div className="name">Специализация </div> */}
            {/*      <Icon name="arrow_rigth" /> */}
            {/*    </div> */}
            {/*  </Form.Item> */}
            {/* ) : ( */}
            {/*  <Form.Item */}
            {/*    label="Пожалуйста, добавьте специализацию" */}
            {/*    name="additionalInput2" */}
            {/*  > */}
            {/*    <div className="timetable_card" onClick={toggleModal}> */}
            {/*      <div className="name">Специализация </div> */}
            {/*      <Icon name="arrow_rigth" /> */}
            {/*    </div> */}
            {/*  </Form.Item> */}
            {/* )} */}

            {/* <Form.Item */}
            {/*  name="trainingType" */}
            {/*  label="Вы автоматически онлайн, но если хотите работать оффлайн, просто нажмите кнопку Оффлайн" */}
            {/* > */}
            {/*  <div className="timetable_card"> */}
            {/*    <Checkbox.Group onChange={handleCheckboxChange}> */}
            {/*      <Checkbox value="online" disabled> */}
            {/*        Онлайн */}
            {/*      </Checkbox> */}
            {/*      {isOfflineSelected ? <Checkbox value="offline" defaultChecked>Оффлайн</Checkbox> : <Checkbox value="offline">Оффлайн</Checkbox> } */}
            {/*    </Checkbox.Group> */}
            {/*  </div> */}
            {/* </Form.Item> */}

            {/* {isOfflineSelected && ( */}
            {/* <> */}
            {/*  <Form.Item */}
            {/*    label="Название места" */}
            {/*    name="offlineName" */}
            {/*    rules={[ */}
            {/*      { */}
            {/*        required: true, */}
            {/*        message: "Пожалуйста, укажите название места!", */}
            {/*      }, */}
            {/*    ]} */}
            {/*  > */}
            {/*    <Input placeholder="Укажите место" /> */}
            {/*  </Form.Item> */}
            {/*  <Form.Item */}
            {/*    label="Адресс" */}
            {/*    name="offlineLocation" */}
            {/*    rules={[ */}
            {/*      { required: true, message: "Пожалуйста, укажите адресс!" }, */}
            {/*    ]} */}
            {/*  > */}
            {/*    <Input placeholder="Укажите адресс" /> */}
            {/*  </Form.Item> */}
            {/* </> */}
            {/* )} */}

          </div>
          <div>

            {/* <Form.Item name="isFirstLessonFree" label="Переключатель тренировки"> */}
            {/*  <div className="timetable_card"> */}
            {/*    <div className="title">Первая тренировка бесплатно </div> */}
            {/*    <Switch */}
            {/*      defaultChecked={userStore.user.isFirstLessonFree} */}
            {/*      onChange={setIsFirstLessonFree} */}
            {/*    /> */}
            {/*  </div> */}
            {/* </Form.Item> */}

            {/* <Form.Item */}
            {/*  label="Пожалуйста, добавьте количество лет опыта" */}
            {/*  name="experience" */}
            {/*  rules={[ */}
            {/*    { required: true, message: "Пожалуйста, укажите опыт!" }, */}
            {/*    { */}
            {/*      pattern: /^[0-9]+$/, */}
            {/*      message: "Опыт должен быть указан в числах!", */}
            {/*    }, */}
            {/*  ]} */}
            {/* > */}
            {/*  <Input placeholder="Опыт" /> */}
            {/* </Form.Item> */}

            {/* <Form.Item */}
            {/*  label="Установите цену за час(индив/группа)" */}
            {/*  name="number" */}
            {/*  rules={[ */}
            {/*    { required: true, message: "Пожалуйста, укажите цену!" }, */}
            {/*    { */}
            {/*      pattern: /^[0-9]+$/, */}
            {/*      message: "Цена должна быть указана в числах!", */}
            {/*    }, */}
            {/*  ]} */}
            {/* > */}
            {/*  <Input placeholder="Цена" /> */}
            {/* </Form.Item> */}
            <Form.Item
              label={t("whatLanguagesDoYouSpeakRequared")}
              name="supportedLanguages"
              rules={[{ required: true, message: t("whatLanguagesDoYouSpeakRequaredRule") }]}
              placeholder="язык"
            >
              <Select mode="multiple" placeholder={t("choose")}>
                <Option value="ENG">{t("languageEnglish")}</Option>
                <Option value="KGZ">{t("languageKyrgyz")}</Option>
                <Option value="RUS">{t("languageRussian")}</Option>
              </Select>
            </Form.Item>

            {/* <Form.Item */}
            {/*  label="Пожалуйста, добавьте цели, с которыми вы можете помочь своим клиентам" */}
            {/*  name="aboutMe" */}
            {/*  rules={[{ required: true, message: "Пожалуйста, укажите цель!" }]} */}
            {/* > */}
            {/*  <Input placeholder="Цель" /> */}
            {/* </Form.Item> */}

            {/* <Form.Item */}
            {/*  label="Пожалуйста, добавьте детали квалификации (название, описание, сертификаты)" */}
            {/*  name="qualification" */}
            {/* > */}
            {/*  <div className="timetable_card" onClick={handleQualification}> */}
            {/*    <div className="name">Квалификация</div> */}
            {/*    <Icon name="arrow_rigth" /> */}
            {/*  </div> */}
            {/* </Form.Item> */}
            <Form.Item label={t("choseLang")}>
              <Language />
            </Form.Item>

            <Form.Item label="Email" name="email">
              <CustomInput text={userStore.user.email} noNav />
            </Form.Item>
            {/* <Form.Item */}
            {/*  label="Phone number" */}
            {/*  name="phoneNumber" */}
            {/*  rules={[ */}
            {/*    { required: true, message: "Пожалуйста, введите номер телефона!" }, */}
            {/*    { */}
            {/*      pattern: /^[0-9]+$/, */}
            {/*      message: "Номер телефона должен содержать только цифры!", */}
            {/*    }, */}
            {/*  ]} */}
            {/* > */}
            {/*  {userStore.user?.phoneNumber ? ( */}
            {/*    <Input /> */}
            {/*  ) : ( */}
            {/*    <PhoneInput */}
            {/*      country="kg" */}
            {/*      value={form.getFieldValue("phone")} */}
            {/*      onChange={(phone) => form.setFieldsValue({ phone })} */}
            {/*      onlyCountries={[ */}
            {/*        "kg", */}
            {/*        "kz", */}
            {/*        "ru", */}
            {/*        "uz", */}
            {/*        "us", */}
            {/*        "ca", */}
            {/*        "ae", */}
            {/*        "de", */}
            {/*        "gb", */}
            {/*        "fr", */}
            {/*      ]} */}
            {/*      placeholder="Введите ваш номер телефона" */}
            {/*    /> */}
            {/*  )} */}
            {/* </Form.Item> */}
          </div>
        </div>
        <div className="flex alignC justify-s wrap gap-20 ">
          <div className="flex justify-s gap-10">
            {/* <Button */}
            {/*  danger */}
            {/*  onClick={() => showConfirmModal("деактивировать аккаунт")} */}
            {/* > */}
            {/*  Деактивировать */}
            {/* </Button> */}
            <Button
              danger
              onClick={() => showConfirmModal("удалить аккаунт")}
            >
              {t("delete")}
            </Button>
          </div>

          <Form.Item className="button_profile">
            <Button type="primary" htmlType="submit" className="button">
              {t("confirm")}
            </Button>
          </Form.Item>
        </div>
      </Form>
      <ModalWrapper isVisible={isModalVisibleWorkTime} onClose={toggleModalWorkTime} title="Рабочие часы">
        <TimetableWorkHours />
      </ModalWrapper>
      <ModalWrapper isVisible={isModalVisible} onClose={toggleModal} title="Специализации">
        <Specialization />
      </ModalWrapper>
      {/* <ModalWrapper isVisible={isModalVisible} onClose={toggleModal} title="Рабочие часы"> */}
      {/*  <TimetableWorkHours /> */}
      {/* </ModalWrapper> */}
    </div>
  );
});

export default ProfileCoach;
