import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../../components/icon";
import Validate from "../../../assets/imgs/validateps.png";
import { confirmEmail } from "../../../api";
import NotFound from "../notFound";

function ConfirmEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const confirmToken = params.get("confirmToken");
  const [showPage, setShowPage] = useState(null);
  useEffect(() => {
    const fetchResetPassword = async () => {
      if (confirmToken) {
        const token = {
          confirmToken,
        };
        try {
          const response = await confirmEmail(token);
          if (response) {
            setShowPage("success");
          } else {
            setShowPage("notFound");
          }
        } catch (error) {
          console.log("Failed:", error);
          setShowPage("notFound");
        }
      }
    };

    fetchResetPassword();
  }, [confirmToken]);
  const handleNav = () => {
    navigate("/login");
  };

  if (showPage === "success") {
    return (
      <div className="container_mobile validate_password ">
        <Icon name="close" onClick={handleNav} />
        <div className="validate_password_content">
          <div className="title">Отлично!!</div>
          <div className="text">Ваш е-маил потвержден.</div>
          <div>
            <img src={Validate} alt="" />
          </div>
        </div>
        <div className="title">
          По любым вопросам обращайтесь по номеру +996 123 456 789
        </div>
      </div>
    );
  } if (showPage === "notFound") {
    return <NotFound />;
  }
  return null;
}

export default ConfirmEmail;
