import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Button, Spin } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "../../../store/rootStore.ts";
import "./style.scss";
import AvatarAny from "../../../components/avatar-any";
import Names from "../../../components/names";
import Icon from "../../../components/icon";

const RequestDetails = observer(() => {
  const { product } = useContext(RootStoreContext);
  const { t } = useTranslation();
  useEffect(() => {
    if (product.id) {
      product.fetchProductDetails();
    }
  }, [product]);
  const sortItemsByStatus = (a, b) => {
    if (a.status === "CREATED" && b.status !== "CREATED") {
      return -1;
    }
    if (a.status !== "CREATED" && b.status === "CREATED") {
      return 1;
    }
    return 0;
  };

  let statusClass = "";
  let statusText = "";
  let paymentRequired = false;
  switch (product.productDetails?.status) {
    case "CREATED":
      statusText = t("awaitingConfirmation");
      statusClass = "status_created";
      break;
    case "CONFIRMED":
      statusText = t("confirmed");
      statusClass = "status_confirmed";
      break;
    case "DECLINED":
      statusText = t("rejected");
      statusClass = "status_declined";
      break;
    case "AWAITING_PAYMENT":
      statusText = t("awaitingPayment");
      statusClass = "status_awaiting_payment";
      paymentRequired = true;
      break;
    default:
      break;
  }
  const formatAppointment = (appointment) => {
    const start = moment.utc(appointment.startOfAppointment);
    const end = moment.utc(appointment.endOfAppointment);

    return {
      date: start.format("DD MMMM"),
      time: `${start.format("HH:mm")} - ${end.format("HH:mm")}`,
    };
  };

  const appointmentsFormatted = product.productDetails?.appointments
    ? product.productDetails?.appointments?.map(formatAppointment)
    : [];

  const handlePayment = () => {
    // Логика для инициации процесса оплаты
    console.log("Initiating payment process for product", product.id);
  };

  return (
    <div className="request_client_detail container_mobile pt-20">
      <Names />
      {product.isLoading ? <Spin className="flex justify-c " />
        : (
          <div className="card">
            <div className={`title ${statusClass}`}>{statusText}</div>
            <div className="flower">
              <div className="flow">
                <AvatarAny url={product.productDetails?.coachAvatarUrl} />
                <div className="flex flex-column gap-5">
                  <div className="text">
                    {product.productDetails?.coachFirstName}
                    {" "}
                    {product.productDetails?.coachLastName}
                  </div>
                  <div className="text">{product.productDetails?.isOffline ? t("offline") : t("online")}</div>
                </div>
              </div>
              <div className="train">
                {t("numberOfSessions")}
                :
                {appointmentsFormatted?.length}
              </div>
              {appointmentsFormatted.map((appointment, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="content">
                  <div className="flex gap-5">
                    <Icon name="calendar" />
                    <div className="text">
                      {appointment.date}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <Icon name="clock_big" />
                    <div className="text">
                      {appointment.time}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="time">
              {t("totalPrice")}
              : 
              {" "}
              {product.productDetails?.fullPrice}
              {" "}
              {t("som")}
              .
            </div>
            {paymentRequired && (
              <Button type="primary" className="button" onClick={handlePayment}>
                {t("pay")}
              </Button>
            )}
          </div>
        )}
    </div>
  );
});
export default RequestDetails;
