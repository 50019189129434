import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonBeta from "../../../../../components/buttons/button-beta";
import ButtonEmpty from "../../../../../components/buttons/button-empty";
import ButtonPrimary from "../../../../../components/buttons/button-primary";
import HamburgerLanding from "../../../../../components/landing-components/hamburger";
import "./style.scss";

function LandingHeader(props) {
  const navigate = useNavigate();
  const { coach, price, comment, contact, main, info, opp } = props;
  const [visible, setVisible] = useState(false);

  const scrollToCoach = () => {
    const topPosition = coach.current.getBoundingClientRect().top + window.pageYOffset - 90;
    window.scrollTo({ top: topPosition, behavior: "smooth" });
    setVisible(false);
  };
  const handleLog = () => {
    navigate("/dashboard");
  };

  const handleSin = () => {
    navigate("/dashboard");
  };
  return (
    <div className="landing_header container_landing">
      <div className="face">
        <div className="flex gap-10 alignC">
          <div className="landing_header_logo">MoveMates</div>
          <ButtonBeta />
        </div>
        <HamburgerLanding open={visible} setOpen={setVisible} onClick={scrollToCoach} />
        <ButtonEmpty text="Become a trainer" className="victory" size="l" onClick={scrollToCoach} />
      </div>
      <div className="victory">
        <ButtonEmpty text="Log in" size="l" onClick={handleLog} />
        <ButtonPrimary text="Sign up " size="l" onClick={handleSin} />
      </div>
    </div>
  );
}

export default LandingHeader;
