import { makeAutoObservable, runInAction } from "mobx";
import { IRootStore } from "./rootStore.ts";
import { createChatRegister } from "../api";

export class ChatRegisterStore {
  rootStore: IRootStore;
  chat: any[];
  usersLoading: boolean;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.chat = [];
    this.usersLoading = false;

    makeAutoObservable(this);
  }

  async fetchChat(token: string) {
    const { userStore, authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.usersLoading = true;
        });
  
        const filter = {
          token,
        };
  
        let lowercaseRole = userStore.role?.toLowerCase();
        if (lowercaseRole === "coach") {
          lowercaseRole += "es";
        }
        if (lowercaseRole === "client") {
          lowercaseRole += "s";
        }
  
        const response = await createChatRegister(filter, lowercaseRole);
        runInAction(() => {
          this.chat = response?.content;
        });
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.usersLoading = false;
      });
    }
  }
}
