import { makeAutoObservable, runInAction } from "mobx";

import { createWorkTime } from "../api";
import { IRootStore } from "./rootStore.ts";

export class WorkTimeStore {
  rootStore: IRootStore;
  workTime: any[];
  isLoading: boolean;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.workTime = [];
    this.isLoading = false;

    makeAutoObservable(this);
  }

  async fetchWorkTime(payload: any) {
    try {
      const response = await createWorkTime(payload);
      if (response && response?.status === 200) {
        console.log("status 200 ,what response? fetchWorkTime", response);
      }
    } catch (error) {
      console.log("Error while trying to work time:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
