import React, { useEffect, useState } from "react";
import { Button, Input, Form } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Names from "../../../components/names";
import { changePassword, confirmPassword } from "../../../api";
import "./style.scss";
import NotFound from "../notFound";

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const confirmToken = params.get("confirmToken");
  const [showPage, setShowPage] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchResetPassword = async () => {
      if (confirmToken) {
        const token = {
          confirmToken,
        };
        try {
          const response = await confirmPassword(token);
          if (response) {
            setShowPage("success");
          } else {
            setShowPage("notFound");
          }
        } catch (error) {
          console.log("Failed:", error);
          setShowPage("notFound");
        }
      }
    };

    fetchResetPassword();
  }, [confirmToken]);

  const handleSubmit = async (values) => {
    try {
      await changePassword(values);
      navigate("/accounts/password/confirm");
    } catch (error) {
      console.log("Failed:", error);
    }
  };

  if (showPage === "success") {
    return (
      <div className="container_mobile reset_password">
        <Names title="Назад" name="Новый пароль" />

        <div className="reset_password_content">
          <div className="title">Новый пароль</div>
          <div className="text">
            Придумайте пароль не менее 8 символов используя латинские буквы и
            цифры
          </div>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Введите ваш пароль!" },
                {
                  min: 8,
                  message: "Пароль должен содержать не менее 8 символов!",
                },
                {
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                  message: "Пароль должен содержать латинские буквы и цифры!",
                },
              ]}
            >
              <Input.Password placeholder="Пароль" />
            </Form.Item>

            <Button type="primary" htmlType="submit" className="button">
              подтвердить
            </Button>
          </Form>
        </div>
      </div>
    );
  } if (showPage === "notFound") {
    return <NotFound />;
  }
  return null;
}

export default ResetPassword;
