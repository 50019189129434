import React, { useContext } from "react";
import "./style.scss";
import { observer } from "mobx-react";
import { Spin } from "antd";
import { RootStoreContext } from "../../store/rootStore.ts";
import Icon from "../icon";

const Avatar = observer(() => {
  const { userStore } = useContext(RootStoreContext);
  if (userStore.isLoading) return <Spin />;
  return (
    <>
      {userStore.isLoading ? <Spin /> : userStore.user?.avatarUrl ? (
        <img src={userStore.user?.avatarUrl} alt="" className="avatar" />
      ) : (
        <Icon name="avatar" className="avatar" />
      )}
    </>
  );
});

export default Avatar;
