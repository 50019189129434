import { makeAutoObservable, runInAction } from "mobx";
import { createChatMessages, getChatMessages } from "../api";
import { IRootStore } from "./rootStore.ts";

export class ChatDetailsStore { 
  rootStore: IRootStore;
  chat: any[];
  isLoading: boolean;
  name: string | null;
  client: any;
  send: any;
  img: string;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.chat = [];
    this.isLoading = false;
    this.name = null;
    this.client = null;
    this.send = null;
    this.img = "";

    makeAutoObservable(this);
  }

  setName(name: string) {
    runInAction(() => {
      this.name = name;
    });
  }

  setClient(client: any) {
    runInAction(() => {
      this.client = client;
    });
  }

  setImg(img: any) {
    runInAction(() => {
      this.img = img;
    });
  }

  async fetchChat(page = 0) {
    const { authStore, userStore } = this.rootStore;

    try {
      if (authStore?.token) {
        runInAction(() => {
          this.isLoading = true;
        });

        const filter = {
          size: 20,
          page,
        };

        let lowercaseRole = userStore.role?.toLowerCase();
        if (lowercaseRole === "coach") {
          lowercaseRole += "es";
        }
        if (lowercaseRole === "client") {
          lowercaseRole += "s";
        }
        const response = await getChatMessages(
          filter,
          this.client,
          lowercaseRole,
        );
        runInAction(() => {
          this.chat = response?.content;
        });
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async postChat(messages: any, localUserName: string) {
    const { authStore, userStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.isLoading = true;
        });

        const filter = {
          content: messages,
        };
        let lowercaseRole = userStore.role?.toLowerCase();
        if (lowercaseRole === "coach") {
          lowercaseRole += "es";
        }
        if (lowercaseRole === "client") {
          lowercaseRole += "s";
        }

        const response = await createChatMessages(
          filter,
          this.client,
          lowercaseRole,
        );

        runInAction(() => {
          if (response?.content) {
            this.chat = [
              { content: response.content, firstName: localUserName },
              ...this.chat,
            ];
          }
        });
      }
    } catch (error) {
      console.error("Failed to send message:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
