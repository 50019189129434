import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import MyCalendar from "../../../components/calendar";
import CardAdd from "../../../components/card-add";
import CardInfo from "../../../components/card-info";
import "./style.scss";
import { RootStoreContext } from "../../../store/rootStore.ts";
import Hamburger from "../../../components/hamburger";

const Schedule = observer(() => {
  const { availableSlotsStore, userStore } = useContext(RootStoreContext);
  const navigate = useNavigate();
  const handleClick = () => {
    if (userStore.role === "coach") {
      navigate("/mentor/calendar/event");
    } else if (userStore.role === "client") {
      navigate("/client");
    }
  };

  return (
    <div className="calendar_schedule">
      <div className="web">
        <Hamburger />
      </div>
      <div className="web_content back_ground pb-120">
        <MyCalendar />
        <CardAdd title="Добавить тренировку" onClick={handleClick} />
        <CardInfo selectedDate={availableSlotsStore.date} />
      </div>
    </div>
  );
});

export default Schedule;
