import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button } from "antd";
import Names from "../../../../components/names";
import MyCalendar from "../../../../components/calendar";
import "./style.scss";
import CalendarTime from "../../../../components/calendar-time";
import { RootStoreContext } from "../../../../store/rootStore.ts";

const TimetableWorkHoursCalendarMentor = observer(() => {
  const { groupAppointmentsStore, individualAppointmentsStore } = useContext(RootStoreContext);
  const navigate = useNavigate();

  const handleDateChange = (newDate) => {
    const formattedDate = moment(newDate).format("D MMMM");
    if (localStorage.getItem("type") === "group") {
      groupAppointmentsStore.setDate(formattedDate);
    } else {
      individualAppointmentsStore.setDate(formattedDate);
    }
  };

  const handleNav = () => {
    navigate(-1);
  };
  return (
    <div className="container_mobile flex time_table_work_hours_calendar flex-column gap-20 back_ground">
      <div className="flex alignC gap-20">
        <Names title="Назад" name="Выходные дни" />
      </div>
      <MyCalendar onDateChange={handleDateChange} />
      <CalendarTime mode="view" />
      <Button className="button_done" onClick={handleNav}>
        Выбрать
      </Button>
    </div>
  );
});

export default TimetableWorkHoursCalendarMentor;
