import axios from "axios";
import { BASE_API_URL } from "../helpers/api";

export const request = async (url, method, payload, formData, params) => {
  const token = sessionStorage.getItem("authToken");
  const api = BASE_API_URL;
  try {
    const res = await axios({
      url: `${api}${url}`,
      headers: {
        ...(formData && { "Content-Type": "multipart/form-data" }),
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      method,
      data: payload,
      params,
    });
    return res.data;
  } catch (error) {
    const { href } = window.location;
    if (error?.response?.status === 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userID");
      if (!href.includes("/login") && !href.includes("/auth")) {
        document.location.href = "/dashboard";
      }
    }

    throw error;
  }
};

export async function login(data) {
  return request("/auth/login", "POST", data);
}

export async function getUser(role) {
  return request(`/api/${role}/me`, "GET");
}

export async function editCoach(filter) {
  return request("/api/coaches/edit", "POST", filter, true);
}
export async function editClient(filter) {
  return request("/api/clients/me/update", "POST", filter);
}

export async function editClientAvatar(filter) {
  return request("/api/clients/avatars", "POST", filter, true);
}

export async function signUp(data, role) {
  return request(`/auth/${role}/create`, "POST", data);
}

export async function createAppointments(data) {
  return request("/api/coaches/appointments", "GET", null, null, data);
}

export async function createWorkTime(data) {
  return request("/api/coaches/worktimes/doUpdateWortime", "POST", data);
}

export async function createIndividualAppointment(data) {
  return request(
    "/api/coaches/sessionSettings",
    "POST",
    data,
  );
}

export async function getIndividualAppointment() {
  return request(
    "/api/coaches/sessionSettings",
    "GET",
  );
}

export async function createGroupAppointments(data) {
  return request(
    "/api/coaches/appointments/createGroupAppointment",
    "POST",
    data,
  );
}

export async function getAppointments(filter) {
  return request(
    "/api/coaches/appointments/getUpcomming",
    "GET",
    null,
    null,
    filter,
  );
}

export async function getAppointmentsDetails(id) {
  return request(`/api/coaches/appointments/${id}`, "GET");
}

export async function getAppointmentsDetailsClient(id) {
  return request(`/api/clients/appointments/${id}`, "GET");
}

export async function getSpecialization() {
  return request("/api/coaches/specializations", "GET");
}

export async function getAppointmentsClient(filter) {
  return request(
    "/api/clients/appointments/getUpcomming",
    "GET",
    null,
    null,
    filter,
  );
}

export async function getCurrentAppointments(id) {
  return request(`/api/coaches/appointments/${id}`, "GET");
}

export async function getClient(id) {
  return request(`/api/coaches/clients/${id}`, "GET");
}

export async function getClientsList(filter) {
  return request("/api/coaches/clients", "GET", null, null, filter);
}

export async function getCoachClientDetail(id) {
  return request(`/api/coaches/clients/${id}`, "GET");
}

export async function getClientCoachDetail(id) {
  return request(`/api/clients/coaches/${id}`, "GET");
}
export async function getClientCoachSession(id, filter) {
  return request(`/api/clients/coaches/${id}/sessions`, "GET", null, null, filter);
}
export async function getCoachClientDetailTest(id, promise) {
  return request(`/api/coaches/clients/${id}`, "GET", null, null, promise);
}

export async function getWorkTimeAvailable(filter) {
  return request("/api/coaches/worktimes/available", "GET", null, null, filter);
}

export async function getWorkTime() {
  return request("/api/coaches/worktimes", "GET");
}

export function getChat(filter, role) {
  return request(`/api/${role}/chats`, "GET", null, null, filter);
}

export function createChatRegister(filter, role) {
  return request(`/api/${role}/chats/register`, "POST", filter);
}

export function getChatMessages(filter, name, role) {
  return request(
    `/api/${role}/chats/${name}/messages`,
    "GET",
    null,
    null,
    filter,
  );
}

export function createChatMessages(filter, name, role) {
  return request(`/api/${role}/chats/${name}/messages`, "POST", filter);
}

export function getCoaches(filter) {
  return request("/api/clients/coaches", "GET", null, null, filter);
}

export function getCoachesTimeAvailable(filter, id) {
  return request(
    `/api/clients/coaches/${id}/getAvailable`,
    "GET",
    null,
    null,
    filter,
  );
}

export function createClientAppointment(filter, id) {
  return request(
    `/api/clients/coaches/${id}/appointments/createIndividual`,
    "POST",
    filter,
  );
}

export function requestResetPassword(filter) {
  return request("/auth/requestResetPassword", "POST", filter);
}

export function resetPassword(filter) {
  return request("/auth/resetPassword", "GET", null, null, filter);
}

export function confirmEmail(filter) {
  return request("/auth/confirmEmail", "GET", null, null, filter);
}

export function confirmPassword(filter) {
  return request("/auth/confirmResetPassword", "GET", null, null, filter);
}

export function changePassword(filter) {
  return request("/auth/changePassword", "POST", filter);
}

export function canselAppointment(filter, id) {
  return request(`/api/clients/appointments/${id}/cancel`, "POST", filter);
}

export function rescheduleAppointment(filter, id) {
  return request(`/api/clients/appointments/${id}/reschedule`, "POST", filter);
}

export function makePayment(filter) {
  return request("/api/coaches/ledger/makePayment", "POST", filter);
}

export function coachesLedger(filter) {
  return request("/api/coaches/ledger", "GET", null, null, filter);
}

export function deleteAccount() {
  return request("/auth/delete", "POST");
}

export function deactivateAccount() {
  return request("/auth/deactivate", "POST");
}

export function subscriptionOffers() {
  return request("/api/subscription_offers", "GET");
}

export function getProductClient(filter) {
  return request("/api/clients/products", "GET", null, null, filter);
}

export function getProductDetails(id) {
  return request(`/api/clients/products/${id}`, "GET");
}

export function confirmProduct(id) {
  return request(`/api/coaches/products/${id}/doConfirm`, "POST");
}

export function cancelProduct(id) {
  return request(`/api/coaches/products/${id}/doDecline`, "POST");
}

export function VideoCallStart(id) {
  return request(`/api/coaches/appointments/${id}/start`, "POST");
}

export function getRequest(filter) {
  return request("/api/coaches/products", "GET", null, null, filter);
}

export function getRequestDetail(id) {
  return request(`/api/coaches/products/${id}`, "GET");
}
