import { makeAutoObservable, runInAction } from "mobx";
import { IRootStore } from "./rootStore.ts";
import { getUser } from "../api";

type Role = "user" | "coach" | "client";

export class UserStore {
  rootStore: IRootStore;

  role: Role | null = null;

  location: string | null = null;

  user = null;

  locationName: string | null = null;

  isCoach: boolean;

  isLoading: boolean;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.loadFromLocalStorage();
    this.isCoach = false;
    this.isLoading = false;
    makeAutoObservable(this);
  }

  setRole(role: Role) {
    runInAction(() => {
      this.role = role;
      localStorage.setItem("role", role);
    });
  }

  setCoach(isCoach: boolean) {
    runInAction(() => {
      this.isCoach = isCoach;
    });
  }

  setLocation(location: string, name: string) {
    runInAction(() => {
      this.location = location;
      this.locationName = name;
      const locationData = { location, locationName: name };
      localStorage.setItem("locationData", JSON.stringify(locationData));
    });
  }

  async fetchUser() {
    const { authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.isLoading = true;
        });
        let lowercaseRole = this.role?.toLowerCase();
        if (lowercaseRole === "coach") {
          lowercaseRole += "es";
        }
        if (lowercaseRole === "client") {
          lowercaseRole += "s";
        }
        const response = await getUser(lowercaseRole);
        runInAction(() => {
          this.user = response;
          localStorage.setItem("user", JSON.stringify(response));
        });
      }
    } catch (error) {
      console.log("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  loadFromLocalStorage() {
    const storedUser = localStorage.getItem("user");
    const storedRole = localStorage.getItem("role");
    const storedLocationData = localStorage.getItem("locationData");

    if (storedUser) {
      this.user = JSON.parse(storedUser);
    }

    if (storedRole) {
      this.role = storedRole as Role;
    }
    if (storedLocationData) {
      const { location, locationName } = JSON.parse(storedLocationData);
      this.location = location;
      this.locationName = locationName;
    }
  }
}
