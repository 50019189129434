import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../icon";
import "./style.scss";

function Document() {
  return (
    <>
      <NavLink to="/privacy-policy">
        <div className="settings">
          <Icon name="privacy" />
          <div className="nav">
            Политика конфиденциальности
          </div>
        </div>
      </NavLink>
      <NavLink to="/term-of-use">
        <div className="settings">
          <Icon name="term" />
          <div className="nav">
            Условия использования
          </div>
        </div>
      </NavLink>
      <NavLink to="/offer-contact">
        <div className="settings">
          <Icon name="offer" />
          <div className="nav">
            Договор оферты
          </div>
        </div>
      </NavLink>
      <NavLink to="/refund">
        <div className="settings">
          <Icon name="refund" />
          <div className="nav">
            Правила оплаты и возврата денежных средств
          </div>
        </div>
      </NavLink>
    </>
  );
}

export default Document;
