import React, { useEffect, useState, useContext } from "react";

import "./style.scss";
import { useNavigate } from "react-router-dom";

import { Form, notification, Select } from "antd";
import { observer } from "mobx-react";
import CustomInput from "../../../components/input";
import Names from "../../../components/names";
import { RootStoreContext } from "../../../store/rootStore.ts";

const NewEventClient = observer(() => {
  const { clientIndividualAppointmentsStore, coachesListStore } = useContext(RootStoreContext);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const navigate = useNavigate();
  const { Option } = Select;

  const showSuccessNotification = () => {
    notification.success({
      message: "Успех!",
      description: "Тренировка успешно создана.",
      placement: "topRight",
      duration: 3,
    });
  };

  useEffect(() => {
    coachesListStore.fetchCoaches();
    if (localStorage.getItem("showNotification") === "true") {
      showSuccessNotification();
      localStorage.removeItem("showNotification");
    }
  }, [coachesListStore]);

  const handleIndividual = () => {
    navigate("/client/specialization/individual");
  };

  // const handleGroup = () => {
  //   navigate("/client/specialization/group");
  // };

  const handleCoachChange = (selectedValue) => {
    const { id, firstName } = JSON.parse(selectedValue);
    setSelectedCoach({ id, firstName });
    clientIndividualAppointmentsStore.setId(id);
    clientIndividualAppointmentsStore.setName(firstName);
  };

  return (
    <div className="container_mobile  new_event_client back_ground">
      <Names title="Назад" name="Тип тренировки" />

      <Form.Item label="Тренер">
        <Select
          defaultValue="Выберите тренера "
          style={{ width: 200 }}
          onChange={handleCoachChange}
          showSearch
          optionFilterProp="children"
        >
          {coachesListStore.coaches.map((item) => (item.firstName ? (
            <Option
              key={item.id}
              value={JSON.stringify({
                id: item.id,
                firstName: item.firstName,
              })}
            >
              {item.firstName}
            </Option>
          ) : null))}
        </Select>
      </Form.Item>

      <div className="flex flex-column gap-10">
        <CustomInput
          disabled={!selectedCoach}
          text="Индивидуальная "
          name="personalcard"
          onClick={handleIndividual}
        />

        {/* <CustomInput */}
        {/*  text={"Групповая"} */}
        {/*  name={"personalcard"} */}
        {/*  onClick={handleGroup} */}
        {/* /> */}
      </div>
    </div>
  );
});

export default NewEventClient;
