import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Checkbox } from "antd";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import Names from "../../components/names";
import "./style.scss";
import { RootStoreContext } from "../../store/rootStore.ts";

const Specialization = observer(() => {
  const { specializationsStore, individualAppointmentsStore } = useContext(RootStoreContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    specializationsStore.fetchSpecializations();
    if (individualAppointmentsStore?.speciality) {
      form.setFieldsValue({
        specializations: individualAppointmentsStore.speciality || individualAppointmentsStore.settings?.specialities,
      });
    } else {
      individualAppointmentsStore.setSpeciality(individualAppointmentsStore.settings?.specialities);
      form.setFieldsValue({
        specializations: individualAppointmentsStore.speciality });
    }
  }, [individualAppointmentsStore.settings?.specialities, form]);
  const onFinish = (values) => {
    const specializationsArray = values.specializations;
    individualAppointmentsStore.setSpeciality(specializationsArray);
    navigate(-1);
  };

  return (
    <div className="container_mobile timetable back_ground">
      <Names title={t("back")} name={t("specializations")} />
      <Form form={form} onFinish={onFinish}>
        <div className="flex alignC gap-10 timetable_card">
          <Form.Item name="specializations">
            <Checkbox.Group style={{ width: "100%" }}>
              {specializationsStore.specializations.map((spec) => (
                <Checkbox
                  value={spec}
                  key={spec}
                >
                  {spec.charAt(0) + spec.slice(1).toLowerCase()}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </div>
        <Button type="primary" htmlType="submit" className="button_done">
          {t("save")}
        </Button>
      </Form>
    </div>
  );
});

export default Specialization;
