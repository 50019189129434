import React, { useContext } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { RootStoreContext } from "../../../store/rootStore.ts";

function ButtonLanding(props) {
  const { userStore } = useContext(RootStoreContext);
  const { text, className } = props;
  const navigate = useNavigate();
  const handleNav = () => {
    userStore.setCoach(false);
    navigate("/dashboard");
  };
  return (
    <div className={`button_landing ${className} `} onClick={handleNav}>
      {text}
    </div>
  );
}

export default ButtonLanding;
