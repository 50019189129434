import React from "react";
import "./style.scss";

function NotFound() {
  return (
    <div className="not_found">
      <div className="title">404</div>
      <div className="title">Page not found</div>
    </div>
  );
}

export default NotFound;
