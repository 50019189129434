import React, { useContext } from "react";
import Icon from "../icon";
import "./style.scss";
import { RootStoreContext } from "../../store/rootStore.ts";

function ContactClient(props) {
  const { userStore } = useContext(RootStoreContext);
  const { onClick } = props;
  return (
    <div className="contact_client" onClick={onClick}>
      <div className="contact_client_card">
        <Icon name="chat" />
        <div className="text">
          Связаться с
          {" "}
          {userStore.role === "coach" ? "клиентом" : "тренером" }
        </div>
      </div>

      <div className="title">Для доп вопросов</div>
    </div>
  );
}

export default ContactClient;
