import { makeAutoObservable, runInAction } from "mobx";
import { getChat } from "../api";
import { RootStore } from "./rootStore.ts";

export class ChatStore {
  rootStore: RootStore;
  chat: any[];
  usersLoading: boolean;
  newMessages: any;
  id: number;
  name: string;
  chatName: string;
  currentChatName: string;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.chat = [];
    this.usersLoading = false;
    this.newMessages = {};
    this.id = 0;
    this.name = "";
    this.chatName = "";
    this.currentChatName = "";
    makeAutoObservable(this);
  }

  unreadChats = new Set();

  setUnreadChat(chatName: string) {
    this.unreadChats.add(chatName);
  }

  clearUnreadChat(chatName: string) {
    this.unreadChats.delete(chatName);
  }

  setId(id: number) {
    runInAction(() => {
      this.id = id;
    });
  }

  addNewMessage(message: any) {
    this.newMessages = message;
  }

  setName(name: string) {
    this.name = name;
  }

  setChatName(name: string) {
    this.chatName = name;
  }
  setCurrentChatName(chatName: string) {
    this.currentChatName = chatName;
  }
  async fetchChat(page = 0) {
    const { userStore, authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.usersLoading = true;
        });

        const filter = {
          size: 20,
          page,
        };
        let lowercaseRole = userStore.role?.toLowerCase();
        if (lowercaseRole === "coach") {
          lowercaseRole += "es";
        }
        if (lowercaseRole === "client") {
          lowercaseRole += "s";
        }
        const response = await getChat(filter, lowercaseRole);
        const existingIds = new Set(this.chat?.map((chat: any) => chat.id));
        const uniqueNewCoaches = response?.content?.filter(
          (coach: any) => !existingIds.has(coach.id),
        );
        runInAction(() => {
          this.chat = [...this.chat, ...uniqueNewCoaches];
        });

        if (response?.content.length > 18) {
          await this.fetchChat(page + 1);
        }
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.usersLoading = false;
      });
    }
  }
}
