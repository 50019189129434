import { makeAutoObservable, runInAction } from "mobx";
import { getCoaches } from "../api";
import { IRootStore } from "./rootStore.ts";

export class CoachesListStore {
  rootStore: IRootStore;
  coaches: any[];
  usersLoading: boolean;
  specialities: string[];
  languages: any;
  searchRequest: string | null;
  experience: number;
  expFrom: any;
  expTo: any;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.coaches = [];
    this.usersLoading = false;
    this.specialities = [];
    this.searchRequest = "";
    this.experience = 0;
    this.expFrom = null;
    this.expTo = null;

    makeAutoObservable(this);
  }

  setSpecialities(specialities: string[]) {
    this.specialities = specialities;
  }
  setSearchRequest(searchRequest: string) {
    this.searchRequest = searchRequest;
  }

  setExperienceRange(experience: string) {
    const range = this.parseExperience(experience);
    this.expFrom = range.from;
    this.expTo = range.to;
  }

  // eslint-disable-next-line class-methods-use-this
  parseExperience(experience: string) {
    switch (experience) {
      case "1-5":
        return { from: 1, to: 5 };
      case "6-10":
        return { from: 6, to: 10 };
      case "11-15":
        return { from: 11, to: 15 };
      case "16-20":
        return { from: 16, to: 20 };
      case "21+":
        return { from: 21, to: Infinity };
      default:
        return { from: null, to: null };
    }
  }
  setLanguages(languages: any) {
    this.languages = languages;
  }

  async fetchCoaches(page = 0) {
    const { authStore } = this.rootStore;
    try {
      if (authStore?.token) {
        runInAction(() => {
          this.usersLoading = true;
        });

        const filter = {
          searchRequest: this.searchRequest,
          specialities: this.specialities.join(","),
          languages: this.languages,
          size: 20,
          expFrom: this.expFrom,
          expTo: this.expTo === Infinity ? 999 : this.expTo, // предполагая, что бэкенд не может обработать Infinity
          page,
        };
        const response = await getCoaches(filter);

        runInAction(() => {
          if (page === 0) {
            this.coaches = response?.content || [];
          } else {
            const existingIds = new Set(this.coaches?.map((coach) => coach.id));
            const uniqueNewCoaches = response?.content?.filter(
              (coach: any) => !existingIds.has(coach.id),
            );
            this.coaches = [...this.coaches, ...uniqueNewCoaches];
          }
        });

        if (response?.content.length > 18 && page === 0) {
          await this.fetchCoaches(page + 1);
        }
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.usersLoading = false;
      });
    }
  }
}
