import React from "react";
import qr from "../../../../../assets/imgs/qr.jpg";
import appleApp from "../../../../../assets/imgs/apple_app.png";
import "./style.scss";
import AppleButton from "../../../../../components/buttons/button-apple";
import ButtonBeta from "../../../../../components/buttons/button-beta";

function LandingAppStore() {
  return (
    <div className="landing_app container_landing">
      <div className="war">
        <div className="content">
          <div className="flex gap-10 alignC">
            <div className="title">MoveMates</div>
            <ButtonBeta />
          </div>
          <div className="text">
            Find your perfect trainer and achieve your fitness goals
          </div>
          <img src={qr} alt="" className="qr" />
          <AppleButton dark />
        </div>
        <img src={appleApp} alt="" className="img" />
      </div>
    </div>
  );
}

export default LandingAppStore;
