import React from "react";
import "./style.scss";
import Icon from "../icon";

function CustomInput(props) {
  const {
    name, text, nav, onClick, child, noNav, className, disabled,
  } = props;

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div
      className={`input ${className} ${disabled ? "disabled" : ""}`}
      onClick={handleClick}
    >
      <div className="flex alignC gap-10">
        {name && <Icon name={name} />}
        <div className="text">
          {text}
          {" "}
          {child}
        </div>
      </div>
      {!noNav && <Icon name={nav || "arrow_rigth"} />}
    </div>
  );
}

export default CustomInput;
