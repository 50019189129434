import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment-timezone";
import { createClientAppointment } from "../api/index";

import { BASE_TIME_ZONE } from "../helpers/api/index";
import { RootStore } from "./rootStore.ts";

export type Rule = "IN_ONE_DAY";

export class ClientIndividualAppointmentsStore {
  rootStore: RootStore;
  speciality: string | null;
  isOffline: boolean;
  rule: Rule;
  locationName: null;
  locationAddress: null;
  startOfAppointment: string | null;
  endOfAppointment: string | null;
  id: number | null;
  description: null;
  duration: number;
  isLoading: boolean;
  time: any;
  date: string;
  name: string | null;
  response: null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.speciality = null;
    this.isOffline = false;
    this.rule = "IN_ONE_DAY";
    this.locationName = null;
    this.locationAddress = null;
    this.startOfAppointment = null;
    this.endOfAppointment = null;
    this.id = null;
    this.description = null;
    this.date = "";
    this.duration = 0;
    this.time = "";
    this.name = null;
    this.isLoading = false;
    this.response = null;

    makeAutoObservable(this);
  }

  setSpeciality(speciality: string) {
    runInAction(() => {
      this.speciality = speciality;
    });
  }

  setName(name: string) {
    runInAction(() => {
      this.name = name;
    });
  }

  setId(id: number) {
    runInAction(() => {
      this.id = id;
    });
  }

  setDuration(duration: number) {
    runInAction(() => {
      this.duration = duration;
    });
  }

  setTime(time: any) {
    runInAction(() => {
      this.time = time;
    });
  }

  setDate(date: string) {
    runInAction(() => {
      this.date = date;
    });
  }

  setOffline(offline: boolean) {
    runInAction(() => {
      this.isOffline = offline;
    });
  }

  setLocation(locationAddress: any) {
    runInAction(() => {
      this.locationAddress = locationAddress;
    });
  }

  combineDateTime() {
    runInAction(() => {
      this.startOfAppointment = this.time.map((slot: any) => {
        const dateStr = slot.date.replace(/^[а-яА-Я]+\s/, "");
        const dateTime = moment.utc(`${dateStr} ${slot.time}`, "D MMM YYYY HH:mm");

        if (!dateTime.isValid()) {
          throw new Error(`Неверный формат даты: ${slot.date}`);
        }

        const startDateTime = dateTime.toISOString();
        return startDateTime;
      });
    });
  }

  async fetchIndividualAppointments() {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      this.combineDateTime();

      // const locationName = this.isOffline ? userStore.locationName : null;
      // const locationAddress = this.isOffline ? this.locationAddress : null;
      const filter = {
        speciality: null,
        isOffline: null,
        rule: null,
        locationAddress: null,
        locationName: null,
        startOfAppointments: this.startOfAppointment,
        // clientId: 4,
        description: null,
      };
      const response = await createClientAppointment(filter, this.id);
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
