import React from "react";
import Icon from "../../../components/icon";
import Names from "../../../components/names";

const arr = [
  {
    text: "У вас запланирована Hiit в 10:00 утра с Кириллом.У вас запланирована Hiit в 10:00 утра с Кириллом.",
    type: "Тренировка",
    id: 1,
  },
  {
    text: "У вас запланирована Hiit в 10:00 утра с Кириллом.У вас запланирована Hiit в 10:00 утра с Кириллом.",
    type: "Тренировка",
    id: 2,
  },
  {
    text: "У вас запланирована Hiit в 10:00 утра с Кириллом.У вас запланирована Hiit в 10:00 утра с Кириллом.",
    type: "Тренировка",
    id: 3,
  },
];

function Notifications() {
  return (
    <div className="container_mobile pt-20 back_ground">
      <Names title="Назад" name="Уведомления" />
      {/* <div className="flex flex-column gap-10 pt-20"> */}
      {/*  {arr.map((item) => ( */}
      {/*    <div key={item.id} className="card flex alignC gap-5"> */}
      {/*      <Icon name="big_calendar" /> */}
      {/*      <div> */}
      {/*        <div className="title">{item.type}</div> */}
      {/*        <div className="text">{item.text}</div> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  ))} */}
      {/* </div> */}
      <div>У вас нет уведомлений</div>
    </div>
  );
}

export default Notifications;
