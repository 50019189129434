import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

import Names from "../../../../components/names";
import CustomInput from "../../../../components/input";

import "./style.scss";

function NewEventMentor() {
  const navigate = useNavigate();

  const showSuccessNotification = () => {
    notification.success({
      message: "Успех!",
      description: "Тренировка успешно создана.",
      placement: "topRight",
      duration: 3,
    });
  };

  useEffect(() => {
    if (localStorage.getItem("showNotification") === "true") {
      showSuccessNotification();
      localStorage.removeItem("showNotification");
    }
  }, []);
  
  const handleIndividual = () => {
    navigate("/mentor/specialization/individual");
    localStorage.setItem("type", "individual");
  };

  // const handleGroup = () => {
  //   navigate("/mentor/specialization/group");
  //   localStorage.setItem("type", "group");
  // };

  return (
    <div className="container_mobile event back_ground">
      <Names title="Назад" name="Новое событие" />

      <CustomInput
        text="Индивидуальная "
        name="personalcard"
        onClick={handleIndividual}
      />

      {/* <CustomInput */}
      {/*  text={"Групповая"} */}
      {/*  name={"personalcard"} */}
      {/*  onClick={handleGroup} */}
      {/* /> */}
    </div>
  );
}

export default NewEventMentor;
