import React, { useContext, useEffect, useRef, useState } from "react";
import DailyIframe from "@daily-co/daily-js";
import { observer } from "mobx-react";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { RootStoreContext } from "../../../store/rootStore.ts";

const VideoCallClient = observer(() => {
  const videoRef = useRef(null);
  const { notificationStore } = useContext(RootStoreContext);
  const [loading, setLoading] = useState(true);
  const [callData, setCallData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Updated callData: ", callData);
  }, [callData]);

  useEffect(() => {
    setLoading(true);
    let data = null;

    if (notificationStore.videoCall.roomUrl) {
      console.log("notificationStore.videoCall", notificationStore.videoCall);
      data = {
        roomUrl: notificationStore.videoCall.roomUrl,
        roomToken: notificationStore.videoCall.roomToken,
      };
      setCallData(data);
    } else if (notificationStore.startAppointment.callUrl) {
      console.log("notificationStore.startAppointment", notificationStore.startAppointment);
      // Используем данные из startAppointment
      data = {
        roomUrl: notificationStore.startAppointment.callUrl,
        roomToken: notificationStore.startAppointment.token,
      };
      // console.log("data1", data);
      setCallData(data);
    } else {
      navigate(-1); // Навигация назад, если URL отсутствует
    }
    // console.log("data2", data);
    // console.log("data polojilos novayz?", callData);
    setLoading(false);
  }, [notificationStore.videoCall, notificationStore.startAppointment, navigate]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    console.log("callData", callData);
    if (callData && videoRef.current) {
      // Убедитесь, что callData.roomUrl - это строка
      if (typeof callData.roomUrl === "string") {
        const callFrame = DailyIframe.createFrame(videoRef.current, {
          showLeaveButton: true,
          showFullscreenButton: true,
        });

        if (typeof callData.roomToken === 'string') {
          callFrame.join({ url: callData.roomUrl, token: callData.roomToken });
        } else {
          console.error("Token должен быть строкой, полученное значение:", callData.roomToken);
          // Обработка ситуации, когда токен не является строкой
        }
        callFrame.on("left-meeting", () => {
          navigate("/main");
        });

        return () => {
          callFrame.destroy();
        };
      } 
      console.error("URL должен быть строкой", callData.roomUrl);
      navigate(-1); // Навигация назад, если URL не является строкой
    }
  }, [callData, navigate]);
  console.log("Проверка roomToken:", callData?.roomToken);

  return (
    <div className="video_call">
      {loading ? <Spin size="large" /> : <div ref={videoRef} className="video_call_ref" />}
    </div>
  );
});

export default VideoCallClient;
